<template>
    <div class="">
        <table v-if="warenkorb && (warenkorb.booked || warenkorb.selection)">

            <tr>
                <th colspan="2">aktuelle Lizenzierung</th>
                <td class="w50p"></td>
                <th colspan="2">zukünftige Lizenzierung</th>
            </tr>
            <tr>
                <td colspan="2" valign="top">
                    <table v-if="warenkorb.booked &&  warenkorb.booked.positionen">
                        <tr><th>pos</th><th>Anzahl</th><th>Artikel</th><th>Einzelpreis</th><th>Summe</th></tr>
                        <tr v-for="(item,index) in warenkorb.booked.positionen" :key="index">
                            <td>{{item.pos}}</td>
                            <td>{{item.anzahl}}</td>
                            <td>{{item.beschreibung.beschreibung}}</td>
                            <td class="summe">{{Currency(item.preis)}}</td>
                            <td class="summe">{{Currency(item.preisSum)}}</td>
                        </tr>
                    </table>

                </td>
                <td></td>
                <td colspan="2" valign="top">
                    <table v-if="warenkorb.selection && warenkorb.selection.positionen">
                        <tr><th>pos</th><th>Anzahl</th><th>Artikel</th><th>Einzelpreis</th><th>Summe</th></tr>
                        <tr v-for="(item,index) in warenkorb.selection.positionen" :key="'a'+index">
                            <td>{{item.pos}}</td>
                            <td>{{item.anzahl}}</td>
                            <td>{{item.beschreibung.beschreibung}}</td>
                            <td class="summe">{{Currency(item.preis)}}</td>
                            <td class="summe">{{Currency(item.preisSum)}}</td>
                        </tr>
                    </table>

                </td>
            </tr>
            <tr>
                <td colspan="2"><hr /></td>
                <td></td>
                <td colspan="2"><hr /></td>
            </tr>

            <tr>
                <td>Tools Summe</td>
                <td align="right">{{Currency(warenkorb.booked.lizenzen.toolPreisSumme.summe)}}</td>
                <td></td>
                <td>ToolsSumme</td>
                <td align="right">{{Currency(warenkorb.selection.lizenzen.toolPreisSumme.summe)}}</td>
            </tr>
            <tr v-if="hasVolRabatt()">
                <td>.\. Volumenrabatt: {{warenkorb.booked.lizenzen.volumenRabatt.info}}</td>
                <td align="right">{{Currency(warenkorb.booked.lizenzen.volumenRabatt.summe)}}</td>
                <td></td>
                <td>.\. Volumenrabatt:{{warenkorb.selection.lizenzen.volumenRabatt.info}}</td>
                <td align="right">{{Currency(warenkorb.selection.lizenzen.volumenRabatt.summe)}}</td>
            </tr>
            <tr v-if="hasNutzerrabatt()">
                <td>.\. Nutzerrabatt</td>
                <td></td>
                <td></td>
                <td>.\. Nutzerrabatt</td>
                <td></td>
            </tr>
            <tr v-if="hasDriveLizenzen()">
                <td colspan="2">
                    <table width="100%">
                        <tr>
                            <td class="w75">&nbsp;</td>
                            <td>{{warenkorb.booked.lizenzen.driveBasis.info}}</td>
                            <td align="right">4.20</td>
                            <td class="summe">{{Currency(warenkorb.booked.lizenzen.driveBasis.summe)}}</td>
                        </tr>
                    </table>
                </td>
                <td></td>
                <td colspan="2">
                    <table width="100%">
                        <tr>
                            <td class="w75">&nbsp;</td>
                            <td>{{warenkorb.selection.lizenzen.driveBasis.info}}</td>
                            <td align="right">4.20</td>
                            <td class="summe">{{Currency(warenkorb.selection.lizenzen.driveBasis.summe)}}</td>
                        </tr>
                    </table>
                
                </td>
            </tr>
            <tr v-if="hasSonderrabatt()">
                <td>.\. Sonderrabatt</td>
                <td></td>
                <td></td>
                <td>.\. Sonderrabatt</td>
                <td></td>
            </tr>
            <tr>
                <td colspan="2"><hr /></td>
                <td></td>
                <td colspan="2"><hr /></td>
            </tr>
            <tr>
                <td>Netto:</td>
                <td align="right">{{Currency(warenkorb.booked.summen.nettoRechnungSumme)}}</td>
                <td></td>
                <td>Netto:</td>
                <td align="right">{{Currency(warenkorb.selection.summen.nettoRechnungSumme)}}</td>
            </tr>
            <tr>
                <td>MWSt:</td>
                <td align="right">{{Currency(warenkorb.booked.summen.mwStSumme)}}</td>
                <td></td>
                <td>MWSt:</td>
                <td align="right">{{Currency(warenkorb.selection.summen.mwStSumme)}}</td>
            </tr>
            <tr>
                <td>Brutto:</td>
                <td class="summe">{{Currency(warenkorb.booked.summen.rechnungSumme)}}</td>
                <td></td>
                <td>Brutto:</td>
                <td class="summe">{{Currency(warenkorb.selection.summen.rechnungSumme)}}</td>
            </tr>
            <tr><td colspan="5"><hr /></td></tr>
            <tr>
                <td colspan="2"></td>
                <td colspan="3">{{warenkorb.differenz.msg}} {{Currency(warenkorb.differenz.differenz)}} € (Netto)</td>
            </tr>
        </table>
    </div>
</template>

<script>
    import {  getWarenkorb } from '@/moduls/auth'
    export default {

        name: 'KundenDetailKaufe',
        props: {
            fireid: String,
        },
        components: {

        },
        data() {
            return {
                datapool: [],
                warenkorb: {},
                imageBytes: '',
            }
        },
        methods: {
            async getMemberApps(memberID) {
                //this.datapool = await getMemberApps(memberID)
                this.warenkorb = await getWarenkorb(memberID)
                console.log(JSON.stringify(this.warenkorb))
            },
            getDatum(data) {
                return data.substring(0,10)
            },
            getKaufDatum(data) {
                if (data.substring(0, 4) > 2010) {
                    return this.getDatum(data)
                }
                return ' Probe '
            },
            getSumme(item) {
                let summe = 0
                for (var t = 0; t < item.length; t++) {
                    summe = summe + item[t].preisSum
                }
                return summe
            },
            Currency(data) {
                if (data) {
                    return parseFloat(data).toFixed(2)
                }
                return '-.-'
            },
            isProbe(data) {
                if (data.tillValid < new Date()) { return 'recycle'  }
                return this.getKaufDatum(data.invoiceFrom).includes('Probe') ? 'exclamation-circle' : 'euro-sign'
            },
            getLogo(data) {
                return process.env.VUE_APP_SCREENLOGOURL + data
            },
            IsStatus(data) {
                var ico = "ban"
                if (data == 0) {
                    ico ="cart-arrow-down"
                } else if (data == -1) {
                    ico="trash"
                } else if (data == 1) {
                    ico ="newspaper"
                } else if (data == 2) {
                    ico ="signature"
                }
                return ico;
            },
            IsRabatt(data) {
                return  data==true  ?"percentage": "caret-right";
            },
            hasVolRabatt() {
                return this.warenkorb
                    && (
                        (this.warenkorb.booked.lizenzen.volumenRabatt && this.warenkorb.booked.lizenzen.volumenRabatt.show)
                        ||
                        (this.warenkorb.selection.lizenzen.volumenRabatt && this.warenkorb.selection.lizenzen.volumenRabatt.show)
                    )
            },
            hasSonderrabatt() {
                return this.warenkorb
                    && (
                        (this.warenkorb.booked.lizenzen.sonderRabatt && this.warenkorb.booked.lizenzen.show)
                        || (this.warenkorb.selection.lizenzen.sonderRabatt && this.warenkorb.selection.lizenzen.show)
                    )
            },
            hasDriveLizenzen() {
                return this.warenkorb && (this.warenkorb.booked.lizenzen.driveBasis.show || this.warenkorb.selection.lizenzen.driveBasis.show)
            },
            hasNutzerrabatt() {
                return this.warenkorb
                    && (
                    (this.warenkorb.booked.lizenzen.nutzerRabatt && this.warenkorb.booked.lizenzen.nutzerRabatt.show)
                    ||
                    (this.warenkorb.selection.lizenzen.nutzerRabatt && this.warenkorb.selection.lizenzen.nutzerRabatt.show)
                )
            }


        },

        watch: {
            fireid() {
                console.log('watch '+this.fireid)
            }
        },

        created() {
            console.log('created ' + this.fireid)
            this.getMemberApps(this.fireid)
        },
        mounted() {
            console.log('mounted ' + this.fireid)
        }
        
    };
</script>

<style scoped>
    .lo{
        padding-left: 36px;
    }

    .Zeile{
        margin-top: -10px;
    }

    .w50p{
        width: 50px;
    }

    .summe{
        text-align: right;
        width: 75px;
    }
    .w75{
        width: 70px;
    }
</style>