import { render, staticRenderFns } from "./memberPanelKaufe.vue?vue&type=template&id=20d8ee69&scoped=true&"
import script from "./memberPanelKaufe.vue?vue&type=script&lang=js&"
export * from "./memberPanelKaufe.vue?vue&type=script&lang=js&"
import style0 from "./memberPanelKaufe.vue?vue&type=style&index=0&id=20d8ee69&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "20d8ee69",
  null
  
)

export default component.exports