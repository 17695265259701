<template>
    <div>
        <div class="block head"><h3>Rechnungslauf</h3> </div>
        <div class="block head">
            <div class="left pa">Auswahl - aktive Rechnungen : {{getPositionen()}}  / Dauerrechnungen : {{getCreatePositionen()}} [ {{getCreatePositionenNeu()}}]</div>
            <div class="right"><button v-if="Modal==0"  :class="Aktive" @click="doPopUp()">Rechnungslauf erstellen</button></div>
            <div class="clear"></div>
        </div>
        <!--{{pState}}-->
        <div v-if="Modal==2">
            <h3>Data Processing <v-icon name="spinner" spin scale="3"></v-icon></h3>
            <div>
                <div>Vorgang: {{pState.vorgangID}}</div>
                <div>Job : {{pState.prozessState}}</div>
                <div>start: {{Datum(pState.jobStart)}}</div>
            </div>
            <div v-if="pState.isWorking"  class="btn_blue actionHand" @click="killRechnungslauf()"> Kill Job</div>
        </div>
        <div v-if="Modal==1 || Modal==3">
            <div class="Popup">
                <div class="DialogTitle"><div class="DialogHead">Rechnungslauf erstellen</div></div>
                <div class="pa">
                    <div><div class="left pw10">RechnungMonat :</div> <input type="text" v-model="rechMonat" class="right" /></div>
                    <div class="clear"><div class="left pw10">Rechnungsdatum :</div> <input type="date" v-model="erstellt" class="right" /></div>
                    <div class="clear"><div class="left pw10">Sepadatum :</div><input type="date" v-model="sepa" :min="erstellt" class="right" /></div>
                    <div class="clear"><div class="left pw10">VorgangID :</div><input type="text" v-model="vorgangID" class="resizedTextbox1 right" /></div>
                    <div class="clear"><div class="left pw10">Status Mails an :</div><input type="text" v-model="eMail" class="resizedTextbox1 right" /></div>
                    <!--<div class="clear"><div class="left pw10">Force :</div><input type="checkbox" v-model="force" class="right" /></div>-->
                    <div class="clear"></div>
                    <div class="PT20">
                        <button class="left" @click="StartVerarbeitung()">Rechnungslauf starten</button>

                        <button class="left" @click="killRechnungslauf()" v-if="ResetJob">Job Killen</button>

                        <button class="right" @click="Modal=0">Abbrechen</button>
                    </div>
                    <div class="clear"></div>
                    <div v-if="ErrorMessage" :class="getErrorInfoBox()">
                        {{ErrorMessage}}
                    </div>
                    <div v-if="VorgangResult" class="infoBox">
                        {{VorgangResult}}
                    </div>
                </div>
                <div class="clear"></div>
            </div>
        </div>
        <div v-if="Modal==0">
            <div v-for="item in Abrechnung" v-bind:key="item.memberID" class="SmallListe">
                <LaufZeileObj :data="item" @refresh="getKunden"></LaufZeileObj>
            </div>
        </div>

    </div>
</template>

<script>
    import { ShowDateTime, GetHeuteWerktag, GetVorgangsDatum, GetRechnungDatum} from '@/moduls/datum.js'
    import { getKundenliste, GetInvoiceStatus, TriggerStart, KillRechnungsLauf } from '@/moduls/invoice.js'
    import { getLoginUserName, getLoginUsereMail } from '@/moduls/auth.js'
    import LaufZeileObj from '@/components/invoice/laufZeile.vue'

    export default {
        data() {
            return {
                suchpattern: {},
                working: false,
                Abrechnung: [],
                Status:0,
                erstellt: this.getHeute(),
                rechMonat: this.getRechMonat(),
                sepa: this.getSepa(),
                vorgangID: '',
                Aktive: 'disabled',
                Modal: 0,
                eMail: '',
                force:false,
                pState: {},
                ProcessResult: null,
                ErrorMessage: null,
                VorgangResult: null,
                ResetJob: false,
                WorkOnUser: ''
            }
        },
        props: {
            data: {},
        },
        methods: {
            databaseEnv() {
                return `${process.env.VUE_APP_API_URL}`
            },
            Datum(item) {
                return ShowDateTime(item)
            },
            getVorgangID() {
                var vd = GetVorgangsDatum(this.erstellt)
                // console.log(vd + '   ' + this.erstellt)
                this.vorgangID = 'RL-'+vd
                return vd
            },
            async killRechnungslauf() {
                await KillRechnungsLauf();
                await this.doPopUp();
            },
            async getKunden() {
                //console.log('kundenliste')
                //console.log(this.Modal + ' 20')
                this.Abrechnung = await getKundenliste()
                //console.log(this.Modal + ' 21')
                //await GetInvoiceStatus()
                return this.Abrechnung
            },
            async RefreshData() {
                //console.log(this.Modal + ' 10')
                await this.getKunden()

                //console.log(this.Modal +' 11')
                await this.GetStatus()
                //console.log(this.Modal +' 12')
                this.getVorgangID()
                //console.log(this.Modal +' 13')
            },
            async GetStatus() {
                this.pState = await GetInvoiceStatus()
                //console.log(this.pState)

                if (this.pState.isWorking) {
                    this.Modal = 2
                }
            },
            async doPopUp() {
                this.Modal = 1
                this.pState = await GetInvoiceStatus()
            },
            async GetUser() {
                this.eMail = getLoginUsereMail()
                this.WorkOnUser = getLoginUserName();
                //console.log(this.WorkOnUser)
                //console.log(this.eMail)
            },
            async StartVerarbeitung() {
                this.Modal = 2
                this.ResetJob = false;
                this.ErrorMessage = null
                this.ProcessResult = await TriggerStart(this.vorgangID, this.erstellt, this.sepa, this.eMail, 2, this.rechMonat)
                //console.log(JSON.stringify(this.ProcessResult)); // + ' ' + this.ProcessResult.startsWith(':exists:',0))

                if (this.ProcessResult.prozessState=="Rechnung") {
                    this.Modal = 3
                    this.ErrorMessage = 'Vorgang l�uft.'
                    return
                } if (this.ProcessResult.prozessState == "Abgeschlossen") {
                    this.Modal = 1
                    this.ErrorMessage = 'Abgeschlossen'
                    return
                } else if (this.ProcessResult.prozessState == "Error") {
                    this.Modal = 1
                    this.ErrorMessage = this.ProcessResult.error
                    // console.log('existing')
                    this.ResetJob = true;
                    return 
                } else if (this.ProcessResult.prozessState == "RechnungenErstellt") {
                    this.Modal = 1
                    this.ErrorMessage = null
                    // console.log('OK')
                    //this.ResetJob = true;
                    this.VorgangResult = 'Rechnungen erstellt'
                    return
                }
                this.Modal = 1
                this.ErrorMessage = this.ProcessResult.error
                // console.log('existing')

            },
            getPositionen() {
                let valid = 0
                for (let i = 0; i < this.Abrechnung.length; i++) {
                    if (this.Abrechnung[i].verwenden==-1) {
                        valid++
                    }
                }
                this.Aktive = valid > 0 ? 'enabled':'disabled'
                //console.log(this.Aktive)
                return valid
            },
            getCreatePositionen() {
                let valid = 0
                for (let i = 0; i < this.Abrechnung.length; i++) {
                    //this.Abrechnung[i].verwenden == -1 && 
                    if (this.Abrechnung[i].verwenden == -1 && this.Abrechnung[i].dauerInvoice == true) {
                        valid++
                    }
                }
                //console.log(this.Aktive)
                return valid
            },
            getCreatePositionenNeu() {
                let valid = 0
                for (let i = 0; i < this.Abrechnung.length; i++) {
                    //this.Abrechnung[i].verwenden == -1 && 
                    //console.log(' - ' + JSON.stringify(this.Abrechnung[i]))
                    if (this.Abrechnung[i].verwenden == -1 && this.Abrechnung[i].dauerInvoice == true && this.Abrechnung[i].rechnungsDruck < Date() ) {
                        valid++
                    }
                }
                //console.log(this.Aktive)
                return valid
            },
            getRechMonat() {
                return GetRechnungDatum(this.getHeute())
            },
            getHeute() {
                return GetHeuteWerktag()
            },
            getSepa() {
                return GetHeuteWerktag(4)
            },
            getErrorInfoBox() {
                return (this.Modal == 3) ? 'infoBox': 'errorBox';
            },
            orderedAbrechnung: function () {
                return this.Abrechnung.sort((a, b) => { if (a.verwenden < b.verwenden) return -1; return 0 });
            },
            //orderedAbrechnung1: function () {
            //    return this.oA.sort((a, b) => { if (a.verwenden < b.verwenden) return -1; return 0 });
            //},
            //oA: function() {
            //    this.Abrechnung.sort(function (a, b) {
            //        var va = a.dauerInvoice ==true ? 1 : 0;
            //        var vb = b.dauerInvoice == true ? 1 : 0;
            //        console.log(va + ' ' + vb);
            //        return va > vb ? 1 : 0;
            //    });
            //}
            

        },
        components: {
            LaufZeileObj,
        },
        created() {
            console.log('1');
            this.RefreshData()
            console.log('2');
            this.GetUser()
            console.log('3');
            this.Modal = 0
            //this.Rechnungslauf.erstellt = this.getHeute()
        },
        //computed: {
        //    orderedAbrechnung: function () {
        //        return this.Abrechnung.sort((a, b) => { if (a.verwenden < b.verwenden) return -1; return 0 });
        //    }
        //}
    }
</script>

<style scoped>

    .Popup {
        font-size: 16px;
        border: solid 1px gray;
        padding: 2em;
        position: absolute;
        width: 500px;
        height: 450px;
        z-index: 15;
        top: 20%;
        left: 50%;
        margin: -100px 0 0 -150px;
        background: white;
        box-shadow: 10px 10px darkgray;
    }

    .DialogTitle {
        background-color: #008CBA;
        color: white;
        width: 100%;
        height: 5em;
    }

    .DialogHead {
        top: 30%;
        left: 25%;
        position: relative;
        font-size: 24px;
    }

    .errorBox{
        padding:2em;
        text-align:center;
        color: darkred;
        font-weight:bold;
        border:solid 1px darkred;
        margin-top:0.5em;
        background-color:burlywood;
    }

    .infoBox {
        padding: 2em;
        text-align: center;
        color: blue;
        font-weight: bold;
        border: solid 1px blue;
        margin-top: 0.5em;
        background-color: aliceblue;
    }

    .block {
        padding-top: 0.2em;
        padding-bottom: 0.2em;
        border-bottom: solid 1px gray;
    }
    .pa {
        padding: 5px;
        padding-top: 10px;
        padding-right: 40px;
        font-family: "Helvetica", arial, sans-serif;
        font-size: 18px;
    }
    .head{
        font-weight:bold;
        font-size:large;
    }
    .w10 {
        padding:1.5em;
    }
    .PT20{
        padding-top:2em;
    }

    .pw10 {
        padding-left: 1.5em;
        padding-right: 1.5em;
        padding-top:0.5em;
    }

    .disabled{
        visibility:hidden;
    }
    .enabled{
        visibility:visible;
    }

    /* Always display the drop down caret */
    input[type="date"]::-webkit-calendar-picker-indicator {
        color: #000000;
    }

    /* A few custom styles for date inputs */
    input[type="date"] {
        appearance: none;
        -webkit-appearance: none;
        color: #000000;
        font-family: "Helvetica", arial, sans-serif;
        font-size: 18px;
        border: 1px solid #ecf0f1;
        background: #ecf0f1;
        padding: 5px;
        display: inline-block !important;
        visibility: visible !important;
    }

    input[type="date"], focus {
        color: #95a5a6;
        box-shadow: none;
        -webkit-box-shadow: none;
        -moz-box-shadow: none;
    }

    .resizedTextbox2 {
        width: 80px;
        height: 32px;
        font-size: large;
        background: #ecf0f1;
    }
    .resizedTextbox1 {
        width: 160px;
        height: 32px;
        font-size: large;
        background: #ecf0f1;
    }

</style>