<template>
    <div>
        <div class="right">
            <div>
                <div class="left pr2">{{status.vorgangID}} ({{status.jobStart}})</div>
                <div class="left wi1">&nbsp;</div>
                <div class="left">Status: {{status.prozessState}}</div>
            </div>
            <div>Error: {{status.error}}</div>
        </div>
        <div id="nav">
            <button @click="setState(1)">Kundendaten</button>
            <button @click="setState(2)">Rechnungslauf</button>
            <!--<button @click="setState(3)">Teilrechnung</button>-->
            <button @click="setState(6)">Rechnung Freigabe</button>
            <!--<button @click="setState(4)">Mailversand</button>-->
            <button @click="setState(5)">Archiv</button>
            <button @click="setState(9)">Einstellungen</button>
            <!--<div class="right">
            <div>{{status.vorgangID}} ({{status.jobStart}})</div>
            <div>Status: {{status.prozessState}}</div>
            <div>Error: {{status.error}}</div>
        </div>-->
            <!--<select>Rechnungsmonat xyZ<option>aktueller Monat</option></select>-->
        </div>

        <div class="content">
            <KundenListe v-if="state==1">

            </KundenListe>
            <Rechnungslauf v-if="state==2" @refresh="Reload()">

            </Rechnungslauf>
            <TeilRechnungslauf v-if="state==3">

            </TeilRechnungslauf>
            <Einstellungen v-if="state==9">

            </Einstellungen>
            <Mailer v-if="state==4">

            </Mailer>
            <Archiv v-if="state==5">

            </Archiv>
            <RechnungFreigabe v-if="state==6">

            </RechnungFreigabe>
            <div class="ph5">&nbsp;</div>
        </div>
    </div>
</template>

<script>
    import KundenListe from '@/components/invoice/kundenliste.vue'
    import Rechnungslauf from '@/components/invoice/rechnungslauf.vue'
    import TeilRechnungslauf from '@/components/invoice/teilrechnungslauf.vue'
    import Einstellungen from '@/components/invoice/settings.vue'
    import Mailer from '@/components/invoice/mailer.vue'
    import Archiv from '@/components/invoice/archiv.vue'
    import RechnungFreigabe from '@/components/invoice/rechnungfreigabe.vue'

    import { getServerStatus } from  '@/moduls/invoice.js'

    export default {
        data() {
            return {
                suchpattern: {},
                working: false,
                state: 0,
                status: {},
            }
        },
        methods: {
            databaseEnv() {
                return `${process.env.VUE_APP_API_URL}`
            },
            async setState(state) {
                this.state = state;
                await this.GetServerStatus();
            },
            async GetServerStatus() {
                this.status = await getServerStatus();
            }
        },
        components: {
            KundenListe,
            Rechnungslauf,
            TeilRechnungslauf,
            Einstellungen,
            Mailer,
            Archiv,
            RechnungFreigabe
        }
    }
</script>

<style scoped>
    .space{
        padding:1em;
    }

    .content{
        padding-left:8px;
        padding-right:8px;
    }
</style>