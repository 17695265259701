<template>
    <div class="box">
        <!--<h4>Detail {{ msg }}</h4>-->
        <!--<div>
        <div class="logo"><img :src="getLogo(DataItem.logo)" /></div>
    </div>-->
        <div>
            <div class="prefix"><b>Name:</b></div><div class="prefix"> {{DataItem.name}}&nbsp; &nbsp;</div>
            <div class="p5 prefix"><b>PID:</b></div><div class="prefix"> {{DataItem.pid}}</div>
        </div>
        <div class="clear"></div>
        <div>
            <div class="prefix"><b>Label:</b></div><div><input type="text" v-model="DataItem.label" class="Col1" /></div>
        </div>
        <div class="clear"></div>
        <div>
            <div class="prefix"><b>Hinweis:</b></div><div><input type="text" v-model="DataItem.hinweis" class="Col1" /></div>
        </div>
        <div class="clear"></div>


        <div>
            <div class="prefix"><b>Tagline:</b></div><div><input type="text" v-model="DataItem.tagline" class="Col1" /></div>
        </div>
        <div class="clear"></div>
        <div>
            <div class="prefix"><b>Description:</b></div><div> <input type="text" v-model="DataItem.description" class="Col1" /></div>
        </div>
        <div class="clear"></div>
        <div>
            <div class="prefix"><b>Teaser:</b></div><div> <input type="text" v-model="DataItem.teaser" class="Col1" /></div>
        </div>
        <div class="clear"></div>
        <div>
            <div class="prefix"><b>Requirements:</b></div><div> <input type="text" v-model="DataItem.requirements" class="Col1" /></div>
        </div>
        <div class="clear"></div>
        <div>
            <div class="prefix"><b>marketingLogo:</b></div><div> <input type="text" v-model="DataItem.marketingLogo" class="Col1" /></div>
        </div>
        <div class="clear"></div>
        <hr />
        <div>
            <ArrayEdit :ArrayItem="DataItem.featureList" msg="FeatureList"></ArrayEdit>
            <!--<div class="prefix"><b>FeatureList:</b></div><div class="Col1" @click="addFLItem">  <v-icon name="plus"></v-icon> </div>
        <div v-for="(item,index) in DataItem.featureList" :key="index"> <input type="text" value="item" /></div>-->
        </div>
        <div class="clear"></div>
        <hr />
        <div>
            <ArrayArea :ArrayItem="DataItem.fullText" msg="Fulltext"></ArrayArea>
            <!--<div class="prefix"><b>FullText:</b></div><div class="Col1"  @click="addFTItem"> <v-icon name="plus"></v-icon> </div>
        <div v-for="(item,index) in DataItem.fullText" :key="index">{{item}}</div>-->
        </div>
        <div class="clear"></div>
        <hr />
        <span @click="SubmitData(DataItem)"><v-icon name="save" scale="2"></v-icon></span>
        <span>Aktualisierungen speichern</span>
        <div class="clear"></div>

        <hr />


    </div>
</template>

<script>
    import ShotView from '@/components/screenshots.vue'
    import ArrayEdit from '@/components/stringArrayEditor.vue'
    import ArrayArea from '@/components/stringArrayArea.vue'

    import { updateTool} from '@/moduls/auth.js'

    export default {
        name: 'ToolViewEdit',
        props: {
            //suche: {}
            DataItem: {}
        },
        components: {
            ShotView,
            ArrayEdit,
            ArrayArea
        },
        data() {
            return {
                msg: '123',
                Data: {}
            }
        },
        mounted() {

        },
        watch: {
            DataItem: function () {
                console.log('DataItem')
                this.msg = this.DataItem.name
            },
         },
        methods: {
            formatPrice(value) {
                return value.toLocaleString('de-DE', { style: 'currency', currency: 'EUR' });
            },
            HasTest(item) {
                return item.link_testversion != ''
            },
            HasKauf(item) {
                return item.link_bestellen != ''
            },
            getLogo(data) {
                return process.env.VUE_APP_SCREENLOGOURL + data
            },
            async SubmitData(data) {
                let res = await updateTool(data)
                console.log(res)
                if (res) {
                    this.$emit("hide", data.pid)
                }
            }
            //addFLItem() {
            //    this.DataItem.featureList.push("")
            //},
            //addFTItem() {
            //    this.DataItem.fullText.push("")
            //}

        }
    };
</script>

<style scoped>
    .prefix {
        float: left;
        width: 120px;
    }
    .Col1{
        float: left;
        width: 60vh;
    }
    .p5{
        padding-left: 5px;
    }
    .logo{
        margin-right: 54px;
        margin-top: -50px;
        float: right;
        width: 48px;
    }
    .textblock{
        background-color:aliceblue ;
    }

    .box{
        border: solid 2px indigo;
        padding: 4px;
        background-color: aliceblue;
    }
</style>