<template>
    <div>
        <div><h2>Kaufstatistik</h2></div>
        <div>Tools Liste</div>
        <div class="bold"><span class="Spalte">Artikel</span><span>Anzahl</span></div>
        <div v-for="item in datapool.tools" :key="item.pid"><span class="Spalte">{{item.pid}}</span> <span>{{item.anzahl}}</span></div>
        <hr />
        <div class="horzeile">&nbsp;</div>
        <div>Letzte Käufe</div>
        <div class="bold"><span class="Spalte">Artikel</span><span>Datum</span></div>
        <div v-for="(item, index) in datapool.last" :key="index"><span class="Spalte">{{item.pid}}</span> <span>{{Datum(item.invoice)}}</span></div>
        <hr />
        <div class="horzeile">&nbsp;</div>
        <div>Letzte Kündigungen</div>
        <div class="bold"><span class="Spalte">Artikel</span><span>Datum</span></div>
        <div v-for="(item, index) in datapool.kills" :key="'a'+index"><span class="Spalte">{{item.pid}}</span> <span>{{Datum(item.invoice)}}</span></div>
        <hr />
    </div>
</template>

<script>
    import { getKaufStatistik } from '@/moduls/auth.js'
    import { ShowDatum } from '@/moduls/datum.js'
    export default {
        name: 'KaufStatistik',
        props: {
            
        },
        components: {

        },
        data() {
            return {
                datapool: {},
                
                
            }
        },
        methods: {
            async startUp() {
                this.datapool = await getKaufStatistik()
            },
            Datum(data) {
                return ShowDatum(data)
            },
        },
        created() {
            this.startUp()
        }
    };
</script>

<style scoped>
    .horzeile{
        height: 0.5em;
    }
    .Spalte{
        float: left;
        width: 100px;
    }
    .bold{
        font-weight: bold;
    }
</style>