<template>
    <div>beende Sitzung...</div>
</template>

<script>
    import { logoutUser } from '@/moduls/auth'

    export default {
        name: 'logout',
        data() {
            return {}
        },
        methods: {
            async logout() {
                try {
                    await logoutUser()
                    this.$router.push('/')
                } catch (err) {
                    //alert(`Error: ${err}`)
                    console.log(`Error: ${err}`)
                }
            }
        }, 
        mounted() {
            console.log('logout')
            this.logout()
        }
    }

</script>

<style scoped>
</style>