<template>
    <div>
        <div id="nav">
            <button @click="selection=1">Kunde Liste</button>
            <!--<button @click="selection=2">neue Kunden</button>-->
            <button @click="selection=3">Drive Kunden</button>
            <button @click="selection=4">KaufStatistik</button>
            <button @click="selection=5">Neu & Offen</button>
            <button @click="selection=6">Änderungen</button>
            <button @click="selection=7">Logos</button>
            <!--<button @click="selection=7" v-if="isFinanzUser()">InvoiceMan</button>-->
        </div>
        <div class="clear"></div>

        <div class="content">
            <!--{{selection}}-->

            <Kundenliste :filter="getFilter()" v-if="selection==1 || selection==2 || selection==3 || selection==5">

            </Kundenliste>
            <KaufStatistik v-if="selection==4">

            </KaufStatistik>
            <KundeAdmin v-if="selection==6">

            </KundeAdmin>
            <KundeAdminLogo v-if="selection==7">

            </KundeAdminLogo>
            <!--<Kundenliste filter="new" v-if="selection==2">

            </Kundenliste>-->
            <!--<div v-else>
        INHALT
             {{selection}}
    </div>-->
        </div>

    </div>
</template>

<script>

    import Kundenliste from '@/components/kundenliste.vue'
    import KundeAdmin from '@/components/kundeadmin.vue' 
    import KaufStatistik from '@/components/kaufstatistik.vue'
    import KundeAdminLogo from '@/components/kundenprelogo.vue'
    

    import { isFinanzUser } from '@/moduls/auth'

    export default {
        name: 'Kunden',
        props: {
            //suche: {}
        },
        components: {
            Kundenliste,
            KundeAdmin,
            KaufStatistik,
            KundeAdminLogo,
        },
        data() {
            return {
                selection: 0,
                working: false,
            }
        },
        mounted() {
            //console.log('mount ' + JSON.stringify(this.$route.params) + ' ' + this.suche)
        },
        watch: {
            //suche: function () {
            //    console.log(JSON.stringify(this.suche))
            //}
        },
        methods: {
            getFilter() {
                //console.log('getFilter '+ this.selection);
                let res = "";

                res = this.selection == 1 ? "" : "new"
                if (this.selection == 3) {
                    return "drive"
                }
                if (this.selection == 5) {
                    return "Newmember"
                }
                return res
            },
            isFinanzUser() {
                return isFinanzUser()
            }
        }
    };


</script>

<style scoped>

    .clear{
        clear: both;
    }

    .content{
        padding: 20px;
    }

    
</style>