<template>
    <div>
        <div class="space"></div>
        <div>
            <div :class="LeftButton()" @click="viewstate=1"><b>Kundenliste</b></div>
            <div class="left pl2"><small>[{{databaseEnv()}}]</small></div>
            <div :class="RightButton()"  @click="viewstate=2"><b>WhiteList</b></div>       
        </div>
        <div class="clear"></div>
        <hr />
        <div v-if="viewstate==1">
            <div v-for="item in datapool" v-bind:key="item.memberID" class="SmallListe">
                <KundeItem :data="item" @whitelist="setWhitelist"></KundeItem>
            </div>
        </div>
        <div v-if="viewstate==2">
            <div v-for="item in whitelist" v-bind:key="item.memberID"  class="SmallListe">
                <KundeItem2 :data="item" @whitelist="removeWhitelist"></KundeItem2>
            </div>
        </div>
        <div class="clear"></div>

    </div>
</template>

<script>
    import KundeItem from '@/components/invoice/kundeitem.vue'
    import KundeItem2 from '@/components/invoice/kundeitem2.vue'
    import { getKundenliste } from '@/moduls/invoice'
    import { getWhiteliste } from '@/moduls/invoice'
    import { setKunden2Whitelist, removeKundenFromWhitelist } from '@/moduls/invoice'


    export default {
        data() {
            return {
                suchpattern: {},
                datapool: [],
                whitelist: [],
                working: false,
                viewstate:1,
            }
        },
        methods: {
            databaseEnv() {
                return `${process.env.VUE_APP_API_URL}`
            },
            async getKunden() {
                // console.log('kundenliste')
                this.datapool = await getKundenliste()
                return this.datapool
            },
            async setWhitelist(item) {
                // console.log('set WL ' + item)
                if (await setKunden2Whitelist(item)) {
                    this.RefreshData()
                }
            },
            async removeWhitelist(item) {
                // console.log('remove WL ' + item)
                if (await removeKundenFromWhitelist(item)) {
                    this.RefreshData()
                }
            },
            async RefreshData() {
                await this.getKunden()
                await this.getWhitelist()
            },
            async getWhitelist() {
                // console.log('whitekundenliste')
                this.whitelist = await getWhiteliste()
                return this.whitelist
            },
            LeftButton() {
                
                return this.viewstate == 1 ? 'left selected Head' : 'left Head'
            },
            RightButton() {
                return this.viewstate == 2 ? 'right selected Head' : 'right Head'
            }
        },
        created() {
            this.RefreshData()
        },
        components: {
            KundeItem,
            KundeItem2
        }
    }
</script>

<style scoped>

    .Head{
        font-size: 20px ;
        /*border: solid 1px red;*/
        padding:.25em;
    }

    .left{
        float:left;
    }
    .right{
        float:right;
    }
    .pl2{
        padding-left:2em;
        padding-top:0.5em;
    }
    .selected {
        background-color: #008CBA;
        color:white;
    }
    .space{
        padding:1em;
    }
</style>