<template>
    <div class="col23 workarea">
        <div class="col w20">{{item.vorgangID}}</div>
        <div class="col w20">Status: {{item.prozessState}}</div>
        <div class="col w20">{{Datum(item.jobStart)}}</div>
        <div class="colr p1 actionHand" @click="ShowDetails()" title="Details"><v-icon name="info" scale="1.6"></v-icon></div>
        <!--<div class="colr p1">
            <v-icon v-if="item.status==1" name="check" scale="1.6" class="ok"></v-icon>
            <v-icon v-if="item.status==0" name="network-wired"></v-icon>
            <v-icon v-if="item.status<0" name="minus-circle" scale="1.6" class="red"></v-icon>
        </div>-->

        <div class="colr p1" :title="MailStatus()"><v-icon name="mail-bulk" scale="1.6" :class="Versenden()"></v-icon></div>
        <div class="colr p1" :title="erstelltInfo()"><v-icon name="coins" scale="1.6" :class="Erstellt()"></v-icon></div>
        <div class="clear"></div>
        <div>
            <div v-if="level1">
                <h3>RechnungsLauf Daten</h3>
                <div v-for="(rechnung,index) in metas.Meta" :key="index">
                    <JobDataZeile :rechnung="rechnung"></JobDataZeile>


                </div>
                <hr />
                <div class="btn_blue" @click="OpenMerge()">open => Merged PDF</div>
                    <!--<div v-for="detailItem in item.PositionData" v-bind:key="detailItem.RechnungsID">
            <div>
                <div class="col w20">{{detailItem.ID}}</div>
                <div class="col w20">{{detailItem.Detail}}</div>
                <div class="col w20">{{detailItem.Summe}}</div>
                <div class="colr p1 lg"><v-icon name="sort-up" scale="1.6"></v-icon></div>
                <div class="colr p1 lg"><v-icon name="sort-up" scale="1.6"></v-icon></div>
                <div class="colr p1"><v-icon name="mail-bulk" scale="1.6" :class="subVersendet(detailItem)"></v-icon></div>
                <div class="colr p1"><v-icon name="coins" scale="1.6" :class="subGespeichert(detailItem)"></v-icon></div>

            </div>
            <div class="clear"></div>
        </div>-->


                </div>



                <div v-if="item.Detail2">
                    Detail Meldungen
                </div>
            </div>

    </div>
</template>

<script>

    import { ShowDateTime } from '../../moduls/datum';
    import { getRechnungMeta } from '../../moduls/invoice';
    
    import JobDataZeile from '@/components/invoice/rechnungen_jobdata.vue'

    export default {
        name: 'ArchivItem',
        props: {
            item: {}
        },
        data() {
            return {
                working: false,
                processworking: false,
                level1: false,
                metas: {}
            }
        },
        components: {
            JobDataZeile
        },
        methods: {
            databaseEnv() {
                return `${process.env.VUE_APP_API_URL}`
            },
            Datum(data) {
                return ShowDateTime(data);
            },
            erstelltInfo() {
                // // console.log(this.item.prozessState)
                return 'Rechnungen' + this.item.prozessState.equals('RechnungenRTM') ? 'Published ':'???'
            },
            MailStatus() {
                return this.item.canSend ? 'kann versendet werden': 'nicht versendbar'
            },
            async ShowDetails() {
                this.level1 = !this.level1
                if (this.level1) {
                    await this.GetJobDataInfos();
                }
            },
            OpenMerge() {
                window.open(this.databaseEnv() + 'api/Invoice/Job/ArchivMergePdf/' + this.item.vorgangID, '_blank')
            },
            Ok() {
                return  this.item && this.item.status && this.item.status == 1 ? true : false
            },
            Versenden() {
                return this.item && this.item.canSend && this.item.canSend ? 'ok' : 'invisible'
            },
            Erstellt() {
                return this.item.prozessState;
            },
            subVersendet(sitem) {
                return   sitem && sitem.versendet ? true: false
            },
            subGespeichert(sitem) {
                return sitem && sitem.gespeichert ? true: false
            },
            async GetJobDataInfos() {
                this.metas = await getRechnungMeta(this.item.vorgangID);
            }

        }
    };
</script>

<style scoped>
    .col {
        float: left;
    }

    .col23{
        /*float:left;*/
        width:75%;
    }

    .colr {
        float: right;
    }

    .workarea {
        padding: 1em;
        border-left: solid 1px gray;
        border-bottom: solid 1px gray;
    }

    .w20 {
        width: 20%;
    }

    .p1 {
        padding-left: 0.5em;
        padding-right: 0.5em;
    }

    .red {
        color: darkred;
    }

    .ok {
        color: darkgreen;
    }

    .lg{
        color:lightgray;
    }

    .invisible {
        color: white;
        background-color: white;
    }
</style>