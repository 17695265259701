<template>
    <div class="pdfFrame">
        <span><v-icon name="spinner" spin></v-icon> </span><span>&nbsp;</span><span>lade Rechnung</span>
        <div v-if="pdf">
            <iframe class="pdfFrame" :src="pdf" ></iframe>
        </div>
    </div>
</template>

<script>
    import { getAuthToken, getProforma, getUserRoles } from '@/moduls/auth.js'

    export default {
        name: 'Rechnung',
        props: {
            id: {required : true}
        },
        data() {
            return {
                working: false,
                member: '',
                pdf:null,
            }
        },
        methods: {
            info() {
                return 'x ' + JSON.stringify(this.$route.query.member); 
            },
            async GetRechnung(mid) {
                console.log(await getUserRoles())
                this.pdf = 'data:application/pdf;base64,' + await getProforma(mid, getAuthToken())
                // console.log('Got Data')
            },
            PdfData() {
                return 'data:application/pdf;base64, ' + encodeURI(this.pdf) 
            }
        },
        watch: {
            id: function () {
                
            },
        },
        created() {
            // console.log('-> ' + this.id)
            if (this.id) {
                this.GetRechnung(this.id)
            }
        }
    };
</script>

<style scoped>
    .pdfFrame{
        width: 100%;
        height: 100%;
    }
    iframe {
        height: calc(100vh - 4px);
        width: calc(100vw - 4px);
        box-sizing: border-box;
    }
</style>