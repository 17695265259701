<template>
    <div class="full">
        <div><h1>TestKunden Daten [1200-1299]</h1></div>
        <div>
            <div>
                <div class="Col1">MemberID</div>
                <div class="Col2">Login</div>
                <div class="Col2">Anzeige</div>
                <div class="Col1">Erstellt</div>
                <div class="Col3">Details</div>
                <div class="clear"></div>
            </div>
            <div v-for="item in datapool" v-bind:key="item.memberID">
                <div class="Col1">{{item.memberID}}</div>
                <div class="Col2">{{item.memberEmail}}</div>
                <div class="Col2">{{item.anzeigename}}</div>
                <div class="Col1">{{getDatum(item.memberCreated)}}</div>
                <div class="Col3">{{item.memberProperties}}</div>
                <div class="clear"></div>
            </div>
        </div>
        <div>
            <div> <h1>Neuanlage</h1></div>
            <div><div class="col2">MemberID</div><div class="Col2"><input type="text" v-model="nmeb" /></div></div>
            <div class="clear"></div>
            <div><div class="col2">Key</div><div class="Col2"><input type="text" v-model="nkey" /></div></div>
            <div class="clear"></div>
            <div @click="CreateNewSet"><span>Create/Reset</span> </div>
            <div class="clear"></div>
            <div>{{ResultSet}}</div>
        </div>
    </div>
</template>

<script>
    // getTestlizenzpool
    import { getTestlizenzpool, insertTestlizenz } from '@/moduls/auth'
    export default {

        name: 'Testlizenzpool',
        
        components: {

        },
        data() {
            return {
                datapool: [],
                ResultSet: '',
                nmeb: '',
                nkey:'',
            }
        },
        methods: {
            async getMembers() {
                //this.datapool = await getMemberApps(memberID)
                this.datapool = await getTestlizenzpool()
            }, 
            async CreateNewSet() {
                this.ResultSet = await insertTestlizenz(this.nmeb,this.nkey)
            },
            getDatum(data) {
                return data.substring(0, 10)
            },
        },
        created() {
            console.log('created ' )
            this.getMembers()
        },
    }

</script>

<style scoped>
    .full{
        width:100%;
    }

    .Col1 {
        width: 8%;
        float: left;
        min-width: 80px;
    }

    .Col2 {
        width: 13%;
        float: left;
        min-width: 150px;
    }

    .Col3 {
        width: *;
        float: left;
        min-width: 220px;
    }
</style>