<template>
    <div>
        <ul>
            <li><a href="" @click.prevent="setPage(1)">|&lt;</a></li>
            <li><a href="" @click.prevent="setPage(0)">&lt;</a></li>
            <li v-for="n in numOfPages" :key="n">
                <a href="" @click.prevent="setPage(n)" :class="isActiv(n)">{{n}}</a>
            </li>
            <li><a href="" @click.prevent="setPage(-1)">&gt;</a></li>
            <li><a href="" @click.prevent="setPage(-9)">&gt;|</a></li>
        </ul>
    </div>
</template>

<script>
    export default {
        name: 'pagination',
        props: {
            dataPool: Array,
            perPage: { type: Number, default: 10 },
        },
        components: {
            //Icon
        },
        methods: {
            setPage(n) {
                if (n == 0) {
                    n = this.currentPage - 1
                } else if (n == -1) {
                    n = this.currentPage + 1
                } else if (n == -9) {
                    n = this.numOfPages
                }
                if (n > this.numOfPages) {
                    n = this.numOfPages
                } else if (n < 1) {
                    n = 1
                }
                this.currentPage = n
                this.$emit('changePage', this.computedUsers)
            },
            isActiv(n) {
                return n == this.currentPage ? 'pagepos' : ''
            }
        },
        data() {
            return {
                currentPage: 1,
                //perPage: 3,
            }
        },
        mounted() {
            this.setPage(1)
        },
        computed: {
            offset: function () {
                return ((this.currentPage - 1) * this.perPage);
            },
            limit: function () {
                return (this.offset + this.perPage);
            },
            numOfPages: function () {
                return Math.ceil(this.dataPool.length / this.perPage);
            },
            computedUsers: function () {

                //console.log('page '+this.offset + ' ' + this.limit)
                return this.dataPool.slice(this.offset, this.limit);
            }
        },
        watch: {
            dataPool: function () {
                console.log('watch')
                this.setPage(1)
            }
        }
    };
</script>

<style scoped>
    a {
        text-decoration: none;
        color: #005563;
    }

        a:link {
            text-decoration: none;
        }

        a:visited {
            text-decoration: none;
        }

        a:hover {
            text-decoration: none;
        }

        a:active {
            text-decoration: none;
            font-weight: bold;
        }

    .pagepos {
        font-weight: bold;
        background-color: #005563;
        color: white;
    }



    ul {
        list-style: none;
        padding: 0;
    }

    li {
        display: inline;
        padding-right: 5px;
    }

        li :last-child {
            padding-right: 0;
        }
</style>