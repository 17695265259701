<template>
    <div>
        <input type="file" accept="image/*" @change="onChange" id="uploaditem" />
        <div v-if="item.imageUrl" class="preview" @click="upload">
            <img :src="item.imageUrl" class="preview" />
            <v-icon name="save" scale="2"></v-icon>
        </div>
        <!--{{logo}}-->
    </div>
</template>

<script>

    import axios from 'axios'

    export default {
        name: 'imageUpload',
        props: {

            logo:Object
        },
        data() {
            return {
                previewImage: null,
                item: {
                    image: null,
                    imageUrl: null
                }
            }
        },
        methods: {
            upload() {
                console.log(this.item)
                this.uploadImageApi()
            },
            uploadImageApi() {
                const URL = `${process.env.VUE_APP_API_URL}api/uploadLogoimage`
                let data = new FormData();
                data.append('name', 'Logo');
                data.append('lizenz', this.logo.lizenz)
                data.append('gruppe', this.logo.gruppe)
                data.append('key', this.logo.key);
                data.append('file', this.item.image);
                let config = {
                    header: {
                        'Content-Type': 'image/png'
                    }
                }
                axios.put(
                    URL,
                    data,
                    config
                ).then(
                    response => {
                        console.log('image upload response > ', response)
                        this.$emit('saved',data)
                    }
                )
            },


            uploadImage(e) {
                const image = e.target.files[0];
                const reader = new FileReader();
                reader.readAsDataURL(image);
                reader.onload = e => {
                    this.previewImage = e.target.result;
                    console.log(this.previewImage);
                };
            },
            onChange(e) {
                const file = e.target.files[0]
                this.item.image = file
                this.item.imageUrl = URL.createObjectURL(file)
            }
        }
    }  // missing closure added
</script>

<style scoped>
    .uploading-image {
        display: flex;
    }
    .preview{
        height: 100px !important;
    }
</style>