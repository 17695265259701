<template>
    <div class="main">
        <div class="result">{{result}}</div>
        <div><hr /></div>
        <div v-for="(item, index) in orderedItems" :key="index" v-bind:class="online(item)">
            <div class="info">

                <div>
                    <span>Produkt: </span>
                    <span class="spalte">{{item.name}}</span>
                    <span class="spalte">[{{item.appID}}]</span>
                    <span class="spalte">Version: {{item.version}}</span>
                </div>
                <div>
                    <span class="spalte">gültig bis: {{getDatum(item.tillValid)}}</span>
                    <span class="spalte">letzte Änderung: {{getDatum(item.changed)}}</span>
                </div>
                <div class="topright" @click="seteditmode(index)"><v-icon :name="showeditSymbol(index)"></v-icon></div>
            </div>
            <div class="edit" v-if="iseditmode(index)">

                <div class="edits">
                    <div>Version</div>
                    <input type="text" v-model="item.version" />
                </div>
                <div class="topright" @click="setdetail(index)"><v-icon :name="setdetailSymbol(index)"></v-icon></div>
                <div v-if="!detailview" class="editf">
                    <div class="mx">neuer FilePool</div>
                    <div>
                        <textarea v-model="newFilepool">

                        </textarea>
                        <button @click="submitDataSet(item)">Update</button>
                    </div>
                </div>
                <div v-if="detailview">
                    <!--<div v-for="it in getFilePool(item.appID)" v-bind:key="it.appID">{{it}}</div>-->
                    {{(item.filePool)}}
                </div>
                <div class="edits">

                </div>

            </div>

            <div class="clear"><hr /></div>
        </div>

    </div>
</template>

<script>

    import { getAllFilePools, setPIDFilePoolRTM, getFilePools} from '@/moduls/auth.js'

    export default {
        name: 'UpdateFilepool',
        props: {

        },
        components: {

        },
        data() {
            return {
                datapool: [],
                working: false,
                editmode: false,
                detailview: false,
                editmodeidx: -1,
                newFilepool: '',
                currentOrder: 'appID',
                fp: [],
                result: {},
            }
        },
        methods: {
            async getProdukte() {
                this.datapool = await getAllFilePools()
            },
            detailFilePool(id) {
                return this.getFilePool(id)
            },

            async getFilePool(id) {
                this.fp = await getFilePools(id)
                //this.fp = g
                console.log(this.fp)
                return this.fp
            },
            online(data) {
                //console.log(data.tillValid > new Date().toISOString())
                //console.log(data.tillValid + '  ' + new Date().toISOString())
                return data.tillValid > new Date().toISOString() ? 'online' : 'offline'
            },
            getDatum(data) {
                return data.substring(0, 10)
            },
            seteditmode(data) {
                this.editmodeidx = data
                this.editmode = true
                this.newFilepool= ''
            },
            iseditmode(data) {
                if (!this.editmode) return false
                if (data == this.editmodeidx) return true
                return false
            },
            showeditSymbol(data) {
                return this.iseditmode(data) ? 'save':'edit'
            },
            async submitDataSet(item) {
                console.log(item.appID)
                console.log(item.version)
                console.log(this.newFilepool);
                this.result = await setPIDFilePoolRTM(item.appID, item.version, this.newFilepool)

                console.log('-'+this.result+'-')
                if (this.result === true) {
                    console.log('fire false')
                    this.editmodeidx = -1;

                }
            },
            setdetail(data) {
                this.detailview = !this.detailview
                console.log(data)
            },
            setdetailSymbol(data) {
                console.log(data)
                return this.detailview ? 'envelope' : 'envelope-open-text'
            },
            //FormatXml(data) {
            //    return prettyxml(data)
            //}
           
        },
        created() {
            this.getProdukte()
        },
        computed: {
            orderedItems() {
                let vm = this
                return vm.datapool.sort((a, b) => (a.tillValid < b.tillValid) ? 1 : -1)
            }
        }
    };
</script>

<style scoped>
    button{
        margin-left: 0em;
        margin-top: 0.2em;
    }

    .main{
        position:relative;
    }

    .online{
        color: darkblue;
    }
    .offline{
        color:darkred;
    }
    .spalte{
        padding-right: 1em;
    }
    .info{
        float: left;
        width: 35em;
    }
    .edit{
        float: left;
        width:60em;
    }
    .edits {
        float: left;
        width: 10em;
        padding-left: 1em;
    }
    .editf {
        float: left;
        width: 100%;
        padding-left: 1em;
    }
    .topright{
        position: relative;
        left:33em;
        top: -1em;
        width: 3em;
    }

    textarea{
        width: 90%;
        height: 200px;
        padding-left: 1em;
    }
    .mx{
        margin-left: 1em;
    }

    .result{
        padding: 1em;
    }
</style>