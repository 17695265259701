<template>
    <div>
        <select name="gruppe" v-model="selection">
            <option v-for="group in gruppen" name="group.id" :key="group.id" :value="group.value">{{group.value}}</option>
        </select>
    </div>
</template>

<script>
    export default {
        name: 'GruppenAuswahl',
        props: {
            //suche: {}
            
        },
        components: {

        },
        data() {
            return {
                selection: '0000',
                gruppen: [{id:0, value: '0000'}, {id:1, value: '0001'}, {id:2, value: '0002'}],
            }
        },
        mounted() {

        },
        watch: {
            selection: function () {
                this.$emit('GruppeSelection',this.selection)
                //console.log('selection changed')
                //return this.selection
            }
        },
        methods: {
            
        }
    };
</script>

<style scoped>
    select {
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        border-radius: 0;
        font-size: 1em;
        width: 100%;
        border: 1px solid #bbb;
        padding: .75em 1em .5em 1em;
        box-shadow: 0 2px 1px 0 rgba(0,0,0,0.2);
        background-color: white;
        background-image: url('~@/assets/images/arrow_down.png');
        background-position: right;
        background-repeat: no-repeat;
    }

        select:hover {
            box-shadow: 0 1px 1px 0 rgba(0,0,0,0.1);
        }

        select::-ms-expand {
            display: none;
        }
</style>