<template>
    <div>
        <div>Edit Liste Admin  {{memberID}}</div>
        <div>gebucht : {{datapool.booked}} &nbsp; Selektiert : {{datapool.selected}}</div>

        <hr />
        <div class="backscreen">
            <div class="Spalte">
                <div><span>Produkt Warenkorb +</span><span><select v-model="addSelect"><option v-for="item in datapool.canAdd" :key="item">{{item}}</option></select></span>                    <span v-if="addSelect" @click="DoWK(addSelect)"><v-icon name="save" scale="1.2"></v-icon></span>                </div>
                <div>
                    <span>Produkt Warenkorb -</span><span><select v-model="removeSelect"><option v-for="item in datapool.selected" :key="item">{{item}}</option></select></span>
                    <span v-if="removeSelect" @click="DoRWK(removeSelect)"><v-icon name="trash-alt" scale="1.2"></v-icon></span>

                </div>
            </div>


            <div class="Spalte">
                <div v-for="item in datapool.selected" :key="item">{{item}}</div>
                <div><span>Selektierte Produkte buchen</span><span @click="DoKK(addKauf)"><v-icon name="save" scale="1.2"></v-icon></span></div>
                <!--<div><span>Produkt Kauf -  </span><span><select v-model="removeKauf"><option v-for="item in datapool.canRemove" :key="item">{{item}}</option></select></span> <span v-if="removeKauf" @click="DoRKK(removeKauf)"><v-icon name="trash-alt" scale="1.2"></v-icon></span></div>-->

            </div>
            <div class="clear"></div>
        </div>
        <span @click="DoCleanSelection()"><v-icon name="trash-alt" scale="1.2"></v-icon> Clear Warenkorb</span>
        <hr />
        <div>
            <span><select v-model="killSelect"><option v-for="item in datapool.canRemove" :key="item">{{item}}</option></select></span>
            <span @click="DoKillKauf()"><v-icon name="trash-alt" scale="1.2"></v-icon> Produkt löschen !</span>
        </div>
        <hr />
        <div>
            Produkt Core:
            <div v-for="item in kaufsatzInfo" :key="item.pid">
                <span :class="IsValidItem(item)">{{item}}</span>
            </div>
        </div>
        <!--<div>{{datapool}}</div>-->
    </div>
</template>

<script>

    import { getKaufEditData, deleteWarenkorb, confirmWarenkorb, PutWarenkorb, deleteWarenkorbSelections, deleteLizenzKauf, getMemberKaufInfo } from '@/moduls/auth.js'
    import { IsInFuture } from '@/moduls/datum.js'

    export default {
        name: 'KundeEditPanelAdmin',
        props: {
            //suche: {}
            memberID: ''
        },
        components: {
         },
        data() {
            return {
                selection: '',
                working: false,
                datapool: {},
                removeKauf: '',
                removeSelect: '',
                addKauf: '',
                addSelect: '',
                killSelect: '',
                kaufsatzInfo:[]
            }
        },
        watch: {
            memberID: function(e) {
                console.log(e)
            }
        },
        created() {
            this.getPanel()
        },
        methods: {
            async getPanel() {
                this.datapool = await getKaufEditData(this.memberID)
                this.kaufsatzInfo = await getMemberKaufInfo(this.memberID)
                console.log(JSON.stringify(this.datapool))
            },
            async DoWK(item) {
                // aditem to Warenkorb
                console.log(item)
                this.result = await PutWarenkorb(this.memberID, this.addSelect)
                this.addSelect = null
                //this.getPanel()
                this.$emit('refresh', this.memberID)
            },
            async DoKK(item) {
                // aditem to Warenkorb
                console.log(item)
                this.result = await confirmWarenkorb(this.memberID, this.addKauf)
                this.addKauf = null
                //this.getPanel()
                this.$emit('refresh', this.memberID)
            },
            async DoRWK(item) {
                // aditem to Warenkorb
                console.log(item)
                this.result = await deleteWarenkorb(this.memberID, this.removeSelect)
                this.removeSelect = null
                //this.getPanel()
                this.$emit('refresh', this.memberID)
            },
            async DoRKK(item) {
                // aditem to Warenkorb
                console.log(item)
                this.removeKauf = null
            },
            async DoCleanSelection() {
                this.result = await deleteWarenkorbSelections(this.memberID)
                //this.getPanel()
                this.$emit('refresh', this.memberID)
            },
            async DoKillKauf() {
                this.result = await deleteLizenzKauf(this.memberID, this.killSelect)
                console.log(' DoKill : '+JSON.stringify(this.result))
                this.$emit('refresh', this.memberID)
            },
            IsValidItem(item) {
                //var td = new Date()
                //console.log(item.validTill)
                if (!IsInFuture(item.validTill)) {
                    return "invalid"
                }
                return "valid"
            }
        }
    };
</script>

<style scoped>

    .Spalte{
        width: 45%;
    }

    .backscreen{
        background-color: whitesmoke;
    }

    .valid {
        color:forestgreen;
    }

    .invalid{
        color: red;
    }

</style>