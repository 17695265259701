<template>
    <div>
        <div >
            <div @click="expand=!expand">

                <span>
                    Change Group Order {{Gruppe}}
                </span>
                <span>
                    <v-icon v-if="expand" name="minus" scale="1.2"></v-icon>
                    <v-icon v-else name="expand" scale="1.2"></v-icon>
                </span>
            </div>
            <div class="ActionPanel" v-if="expand">
               <!--Hier gibts die Liste und Reihenfolge-->
                <div v-for="(item,index) in GruppenTools" :key="index">
                    <span class="rc">[{{item.orderPos}}]</span>
                    <span class="rc">{{item.name}}</span>
                    <span class="rc">({{item.pid}})</span>

                    <span class="rcr" @click="MoveUp(index)"><v-icon name="chevron-circle-up" scale="1.4"></v-icon></span>
                    <span class="rcr" @click="MoveDown(index)"><v-icon name="chevron-circle-down" scale="1.4"></v-icon></span>
                    <hr class="clear" />
                </div>
                <!--{{GruppenTools}}-->
                <div @click="submitNewOrder"><v-icon name="save" scale="2"></v-icon>  Änderungen übernehmen </div>
            </div>
        </div>
    </div>
</template>

<script>

    import { getAllTools, updateToolOrder } from '@/moduls/auth.js'

    export default {
        name: 'ChangeOrder',
        props: {
            Gruppe: String,
        },
        components: {
            
        },
        data() {
            return {
                working: false,
                searchtopic: 'memberID',
                searchpattern: '',
                expand: false,
                GruppenTools: [],
            }
        },
        methods: {
            async reloadToolOrderData() {
                this.GruppenTools = await getAllTools(this.Gruppe)
            },
            MoveUp(index) {
                //console.log('UP ' + index)
                if (index === 0) { // if its the first in the array
                    this.GruppenTools.push(this.GruppenTools.shift()) // same as option 1
                } else {
                    this.GruppenTools = this.GruppenTools.map((element, i) => { // iterate array and change places
                        if (i === index - 1) {
                            return this.GruppenTools[index];
                        } else if (i === index) {
                            return this.GruppenTools[index - 1];
                        } else {
                            return element;
                        }
                    })
                }
            },
            MoveDown(index) {
                //console.log('DO ' + index)
                if (index === this.GruppenTools.length - 1) { // if its the last in the array
                    this.GruppenTools.unshift(this.GruppenTools.pop()) // same as option 1
                } else {
                    this.GruppenTools = this.GruppenTools.map((element, i) => { // iterate array and change places
                        if (i === index) {
                            return this.GruppenTools[index + 1];
                        } else if (i === index + 1) {
                            return this.GruppenTools[index];
                        } else {
                            return element;
                        }
                    })
                }
            },
            async submitNewOrder() {
                let postdata = {}
                postdata.Gruppe = this.Gruppe
                postdata.Items = []
                for (let i = 0; i < this.GruppenTools.length; i++) {
                    postdata.Items.push(this.GruppenTools[i].pid)
                }
                //console.log(postdata);
                let res = await updateToolOrder(postdata)
                if (res) {
                    this.reloadToolOrderData()
                    this.$emit('Reload', true)
                    this.expand = false
                }
                //console.log(res + '---eof----')
            }
        },
        created() {
            //console.log('create' + this.Gruppe)
            this.reloadToolOrderData()
        },
        mouted() {
            //console.log('mount')
            
        },
        watch: {
            Gruppe: function () {
                //console.log('Trigger Reload')
                this.reloadToolOrderData()
            }
        }
    };
</script>

<style scoped>

    .ActionPanel{
        border: solid 1px #dddddd;
        padding:1em;
    }

    .rc{
        padding-right: 1em;
    }

    .rcr {
        float:right;
        padding-left: 1em;
    }
</style>