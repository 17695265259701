<template>
    <div>
        <div class="prefix"><b>{{msg}}:</b></div><div class="Col1" @click="addItem"> <v-icon name="plus"></v-icon> </div>
        <div class="clear"></div>
        <div v-for="(item,index) in ArrayItem" :key="index">
            <span @click="removeItem(index)" class="prefix50"><v-icon name="minus"></v-icon></span>
            <span class="full"><input type="text" class="full" v-model="ArrayItem[index]"/></span>
            <div class="clear"></div>
        </div>
    </div>
</template>

<script>

    export default {
        name: 'ToolArrayEdit',
        props: {
            //suche: {}
            ArrayItem: {},
            msg: String,
        },
        components: {
        },
        data() {
            return {
               mg:'',
                Data: {}
            }
        },
        mounted() {

        },
        watch: {
            ArrayItem: function () {
                //console.log('DataItem')
                this.mg = this.DataItem.name
            },
        },
        methods: {
            //formatPrice(value) {
            //    return value.toLocaleString('de-DE', { style: 'currency', currency: 'EUR' });
            //},
            //HasTest(item) {
            //    return item.link_testversion != ''
            //},
            //HasKauf(item) {
            //    return item.link_bestellen != ''
            //},
            //getLogo(data) {
            //    return process.env.VUE_APP_SCREENLOGOURL + data
            //},
            //addFLItem() {
            //    this.DataItem.featureList.push("")
            //},
            addItem() {
                this.ArrayItem.push("")
            },
            removeItem(index) {
                this.ArrayItem.splice(index, 1);
            }

        }
    };
</script>

<style scoped>


    .prefix {
        float: left;
        padding-right: 5px;
    }

    .prefix50 {
        float: left;
        padding-right: 5px;
        width: 50px;
    }

    .Col1 {
        float: left;
        width: 300px;
    }

    .full {
        float: left;
        width: 60vh;
    }

</style>