<template>
    <div>
        <div>
            <div>{{lgo.key}}</div>
            <div class="left w50"><img :src="setLogo(lgo)" class="preview" /></div>
            <div class="left actionHand" @click="expand=!expand" v-if="canedit"><v-icon name="edit" scale="2"></v-icon></div>
            <div class="right" v-if="expand"><ImagePreview :logo="lgo" @saved="savedItem"></ImagePreview></div>
            <div class="clear"></div>
        </div>
    </div>
</template>

<script>
    //import { getLogoPools, getLogoSet } from '@/moduls/auth'
    import ImagePreview from '@/components/imagepreview.vue'

    export default {
        name: 'LogoAnzeige',
        props: {
            lgo: Object,
        },
        components: {
            //Kundenliste,
            ImagePreview
        },
        data() {
            return {
                expand: false,
                canedit:false,
                //LogoWorkSet: {},
                //LogoWorkPool: [],
                //working: false,
                //lizenzkey: 'PS6Q1K7OCE58MGT8',
                //listselection: '',
            }
        },
        methods: {
            setLogo(data) {
                //console.log(data)
                return data.base64
            },
            savedItem(data) {
                console.log(data)
                this.expand = false
                this.$emit('refresh', data)
            },
            canChangeImages(val) {
                console.log('grp '+val.gruppe)
                return (val.gruppe!='demo' && val.gruppe!='emendata' && val.gruppe!='neutral')
                //return false
            }
            //async getLogoGruppen(key) {
            //    console.log('redirect ' + key)
            //    this.LogoWorkPool = await getLogoPools(key)
            //    console.log(JSON.stringify(this.LogoWorkPool))
            //},
            //async getLogos(key, gruppe) {
            //    this.LogoWorkSet = await getLogoSet(key, gruppe)
            //},
            //async loadKunden() {
            //    console.log('fire gruppen')
            //    await this.getLogoGruppen(this.lizenzkey);
            //},
            //onChange(event) {
            //    console.log(event.target.value)
            //    this.$emit('selected', this.lizenzkey)
            //}
        },
        created() {
            console.log('watch ' + this.lgo.gruppe)
            this.canedit = this.canChangeImages(this.lgo)
        },
        watch: {
            lgo: function (val) {
                console.log('watch ' + val.gruppe)
                this.canedit = this.canChangeImages(val)
                return val
            }
            //listselection: async function (val) {
            //    console.log(val)
            //    await this.getLogos(this.lizenzkey, this.listselection)
            //    this.$emit('selected', this.listselection)
            //}
        }
    };
</script>

<style scoped>
    .preview {
        border: solid 1px black;
        padding: 1em;
        height: 100px;
    }
    .w50{
        min-width:400px;
        width: 45%;
    }
</style>