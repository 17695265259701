<template>
    <div class="sbox">
        <div>
            Art :
            <select v-model="searchtopic">
                <option>memberID</option>
                <option>Akey</option>
                <option>Name</option>
                <option>eMail</option>
            </select>
            <span @click="submit()">&nbsp;<v-icon name="search"></v-icon></span>
        </div>
        <div>
            <input type="text" v-model="searchpattern"  autocomplete="off"/>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'Suchleiste',
        props: {

        },
        components: {
            //Kundenliste,
        },
        data() {
            return {
                working: false,
                searchtopic: 'memberID',
                searchpattern: '',
            }
        },
        methods: {
            submit() {
                let res = { topic: this.searchtopic, pattern: this.searchpattern }
                this.$emit('search', res)
                this.$parent.$emit('search', res)
                console.log('Lupe Go '+res)
            }
        },
        created() {
            
        }
    };
</script>

<style scoped>
    .sbox {
        border-left: solid 1px #acc8f2;
        border-bottom-left-radius: 40px;
        border-top-left-radius: 40px;
        background-color: aliceblue;
        /*margin-right: 10px;*/
        padding: 8px 0px 8px 48px;
    }
</style>