<template>
    <div>
        <select  v-model="selected" @change="onChange">
            <option v-for="item in datapool" :value="item" :key="item.PID">
                {{ item.name }}/({{ item.pid}})
            </option>
        </select>
    </div>
</template>

<script>

    import { getTools } from '@/moduls/auth'

    export default {
        name: 'AppSelection',
        props: {

        },
        components: {
            //Kundenliste,
        },
        data() {
            return {
                datapool: [],
                working: false,
                selected: {},
            }
        },
        methods: {
            async getProdukte() {
                this.datapool = await getTools('0000')
            },
            onChange(event) {
                console.log(event.target.value)
                this.$emit('selected',this.selected.pid)
            }
        },
        created() {
            this.getProdukte()
        },
        //watch: {
        //    selected: function (val) {
        //        this.$emit('selected', this.selected.pid)
        //    }
        //}
    };
</script>

<style scoped>
</style>