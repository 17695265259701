<template>
    <div class="tabelle">

        <div class="headline">
            <div class="headitem fatcol">{{data.memberID}}</div>
            <div class="headitem">{{Datum(data.memberCreated)}}</div>
            <div class="headitem" v-if="data.hasDrive"><img src="../../assets/images/drive_48x48.png" width="18" height="18"/></div>
            <div class="headitem" v-else>&nbsp;</div>
            <div class="headitem right">>> <a @click="SetToWhiteList(data.memberID)">[To WhiteList]</a></div>
        </div>
        <div class="headline">
            <div class="headitem col3">
                <v-icon name="handshake"></v-icon> {{data.memberEmail}}</div>
            <div class="headitem col3">
                <v-icon name="chalkboard-teacher"></v-icon> {{data.anzeigename}}</div>
            <div class="headitem col3">
                <v-icon name="building"></v-icon>
                {{data.memberProperties.plz}}
                &nbsp;
                {{data.memberProperties.city}}
            </div>

        </div>
        <div class="headline">
            <div class="headitem col3">
                <v-icon name="credit-card"></v-icon>&nbsp;{{data.memberProperties.iban}}</div>
            <div class="headitem col3">
                <v-icon name="money-check-alt"></v-icon>&nbsp;{{data.memberProperties.bankname}}</div>
            <div class="headitem col3">
                <v-icon name="hdd"></v-icon>&nbsp;[{{data.memberProperties.gpn}}]</div>
        </div>
        <div class="clear"></div>
    </div>
</template>

<script>
    //import KundenListe from '@/components/invoice/kundenliste.vue'
    import { ShowDatum } from '@/moduls/datum.js'
    export default {
        data() {
            return {
                suchpattern: {},
                working: false,
            }
        },
        props: {
            data: {},
        },
        methods: {
            databaseEnv() {
                return `${process.env.VUE_APP_API_URL}`
            },
            SetToWhiteList(item) {
                this.$emit('whitelist', item)
                //console.log('whitelist ' + item)
            },
            Datum(item) {
                return ShowDatum(item)
            }

        },
        components: {
           
        }
    }
</script>

<style scoped>
    .headline{
        clear:both;
    }
    .headitem{
        float:left;
        padding-right: 1em;
    }

    .right{
        float:right;
    }
    .fatcol{
        font-weight: bold;
        padding-right: 1em;
    }
    .col3{
        width:30%;
    }
    .tabelle {
        clear: both;
        padding-top: 0.5em;
        padding-bottom: 0.5em;
        border-bottom: solid 1px gray;
    }

</style>