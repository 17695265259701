<template>
    <div>
        <b>Drive KontoDaten für {{memberID}}</b>
        <div>
            <div v-if="dataset && dataset.drive ">
                <div>
                    <div> Lizenzen: <b>{{dataset.tools.tools}}</b></div>
                    <div class="left pr1">Anzahl Lizenzen : <b>{{dataset.tools.toolLizenzen}}</b></div>
                    <div class="clear"></div>
                    <div class="left">
                        <hr />
                        <div v-for="(tool,index) in dataset.tools.data" v-bind:key="index">
                            <div v-if="tool.nutzerName!=''">
                                {{tool.nutzerName}} pid: {{tool.pid}} Anz: {{tool.anzahl}}
                            </div>
                        </div>
                    </div>
                    <div class="clear"></div>
                    <hr />
                </div>

                <!--<div> {{dataset.drive}}<br /><hr /></div>-->
                <div v-if="dataset.drive.akeySettingsProperties"><b>AdminUser :</b> {{dataset.drive.akeySettingsProperties.adminUser}}<br /><hr /></div>
                <div>
                    <div>
                        <b>DriveNutzer:</b> {{dataset.gruppen.users}}
                    </div>
                    <div v-for="(item, index2) in dataset.gruppen.betreuungsGruppen" v-bind:key="index2">
                        <b>{{item.membersOfGruppe}} :</b>
                        <span v-for="(sitem, index3) in item.nutzerDaten" v-bind:key="index3">
                            {{sitem.nutzerName}};
                        </span>

                    </div>
                    <!--{{dataset.gruppen}}-->
                </div>
            </div>
            <hr />
            <div>Drive Nutzerkonten</div>
            <div><span class="pitem">Nutzername:</span><span class="pitem">eMail:</span><span class="pitem">Passwordstatus:</span></div>
            <div class="clear"></div>
            <div v-for="(item, index2) in dataset.user" :key="'p'+index2">
                <span class="pitem"><b>{{item.nutzerName}}</b></span>
                <span class="pitem">{{item.notifyMail}}</span>
                <!--{{item.widDetails.individualiserbar}}-->
                <span class="pitem">{{item.widDetails.newPassword}}</span>
                <span>ResetPassword</span>
                <div class="clear"></div>

            </div>
            <hr />
            <div><span class="pitem">Nutzername:</span><span class="pitem4">Rollen:  n: neu  r: lesen w: schreiben k: löschen</span></div>
            <div class="clear">&nbsp;-</div>
            <div :class="toggle(index3)" v-for="(item, index3) in dataset.user" :key="index3">
                <div class="pitem120"><b>{{item.nutzerName}}</b></div>
                <div class="clear"></div>
                <div class="pitemf">
                    <div  v-for="(allo,index4) in item.allow" :key="index4">
                        <div :class="getAccessBackcolor(allo)">
                            <div class="pitem60">{{getAccess(allo)}}<div class="right">{{allo.accessLevel}}</div></div>
                            <div class="clear"></div>
                        </div>

                    </div>
                </div>
                
                <div class="clear"></div>

            </div>
            <div class="clear"></div>
        </div>
    </div>
</template>

<script>

    import { getDriveData } from '@/moduls/auth.js'

    export default {

        name: 'DriveDetail',
        props: {
            memberID: String,
        },
        components: {

        },
        data() {
            return {
                dataset: {}
            }
        },
        methods: {
            //async GetArchivData() {
            //    let pitem = await getDriveStatistik('1051')
            //    console.log(JSON.stringify(pitem))
            //},
            async DriveStatistik(member) {
                //member ='PS6Q1K7OCE58MGT8'
                if (member) {
                    this.dataset = await getDriveData(member)
                    console.log(JSON.stringify(this.dataset))
                }
            },
            async Init(member) {
                await this.DriveStatistik(member)
            },
            getAccess(item) {
                //console.log(item.role)
                if (item.role == 'full') return '[nrwx]'
                var res ='['
                if (item.role.indexOf('neu')>=0) { res += 'n' } else { res += ' ' }
                if (item.role.indexOf('read')>=0) { res += 'r' } else { res += ' ' }
                if (item.role.indexOf('write')>=0) { res += 'w' } else { res += ' ' }
                if (item.role.indexOf('kill')>=0) { res += 'x' } else { res += ' ' }
                return res +']'
            },
            getAccessBackcolor(item) {
                //console.log(item.role)
                var res=''
                if (item.role.indexOf('neu') >= 0) { res += 'ee' } else { res += '00' }
                if (item.role.indexOf('read') >= 0) { res += 'ee' } else { res += ' ' }
                if (item.role.indexOf('write') >= 0) { res += 'ee' } else { res += ' ' }
                if (item.role.indexOf('kill') >= 0) { res = 'redone' }
                if (item.role == 'full') res = 'admin'
                return res + ' accessbox '
            },
            toggle(item) {
                //console.log(item)
                if (item % 2) return 'lighti'

                return 'light'
            }
        },
        created() {
            this.Init(this.memberID)
        },
        //watch: {
        //    memberID: async function () {
        //        console.log('watch ')
        //        await this.DriveStatistik()
        //    }
        //}
    };
</script>

<style scoped>
    .pr1{
        padding-right:1em;
    }

    .pitem{
        float: left;
        padding-left:0.5em;
        width:20%;
    }
    .pitem4 {
        float: left;
        padding-left: 0.5em;
        width: 40%;
    }
    .pitem0 {
        float: left;
        padding-left: 0.5em;
        
    }
    .pitem60 {
        float: left;
       /* padding-left: 0.5em;*/
        width:100px;
    }
    .pitem20 {
        float: left;
        padding-left: 0.5em;
        width: 20px;
    }
    .pitem120 {
        float: left;
        padding-left: 0.5em;
        width: 120px;
    }
    .pitem110 {
        float: left;
        padding-left: 0.5em;
        width: 110px;
    }
    .pitemf{
        float:left;
        width: 95%;
    }

    .accessbox {
        border: solid 1px #fefefe;
        padding-left: 0.4em;
        padding-right: 0.4em;
        padding-bottom: 0.2em;
        margin: 4px;
        width: 120px;
        float: left;
        font-size: small;
    }


    .redone {
        background-color: #ff0000;
    }

    .admin {
        color: #ff0000;
        background-color:#00ff00;
    }

    .read {
        background: #d1d1d1;
    }
    .write {
        background: #11d1d1;
    }
    .neu {
        background: #d1d111;
    }

    .light{
        background: #e0e0e0;
    }

    .lighti {
        background: #efefef;
    }

</style>