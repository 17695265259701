<template>
    <div class="contentnoborder">

        <div class="card">
            <div class="RO" @click="SetCStatus(1)"><v-icon :name=CStatus(1)></v-icon></div>
            <h3>Startseite der e:mendata Admin Services</h3>

            <div v-if="CS1==true">
                <div class="left card">
                    <div>WebApi</div>
                    <img src="https://webapi.emendata.de/api/web/cpu/png" />
                </div>
                <div class="left card">
                    <div>CalcApi</div>
                    <img src="https://vc000.emendata.info/livemonitor/live.png" />
                </div>
                <div class="left cardnoborder">
                    <ul class="support">
                        <li>Verwaltung der Kundendaten, Käufe Probemonat</li>
                        <li>Einstellungen der Website, Produkte, Gruppen</li>
                        <li>Updates, Freigabe bearbeiten</li>
                        <li>Service: Hilfestellungen</li>
                    </ul>
                    <!--<div @click="TestPDF()">
            TestLink PDF
        </div>-->
                </div>

            </div>
            <div class="clear"></div>
        </div>
        <div class="ph5">&nbsp;</div>

        <div @click="GetWebStatus()">Test</div>

        <div class="card">
            <div class="RO" @click="SetCStatus(2)"><v-icon :name=CStatus(2)></v-icon></div>
            <div class="h3 left">Service Status</div>
            <div class="left px5">
                <div v-for="(item,name) in ServerStatus" :key="name" class="iconbutton">
                    <v-icon :name="isAvailable(item)" v-bind:class="ClassAvailable(item)"></v-icon>
                </div>
            </div>
            <div class="clear"></div>
        


            <div v-if="CS2==true">
                <!--<div class="left">-->
                    <div class="card cw50 left">
                        <table>
                            <tr><th colspan="3">localhost</th></tr>
                            <tr v-for="(item,name) in ServerStatus" :key="name">
                                <td><v-icon :name="isAvailable(item)" v-bind:class="ClassAvailable(item)"></v-icon></td>
                                <td>{{name}}</td>
                                <td>{{item}}</td>
                                <td @click="expandView(name,item)">&nbsp;<v-icon name="edit"></v-icon></td>
                            </tr>
                        </table>
                    </div>
                <!--</div>-->
                <!--<div class="left">-->
                    <div class="card cw50 left">
                        <table>
                            <tr><th colspan="3">web Server</th></tr>
                            <tr v-for="(item,name) in WebServers" :key="name">
                                <td><v-icon :name="isAvailable(item)" v-bind:class="ClassAvailable(item)"></v-icon></td>
                                <td>{{name}}</td>
                                <td>{{item}}</td>
                                <td @click="expandView(name,item)">&nbsp;<v-icon name="edit"></v-icon></td>
                            </tr>
                        </table>
                    </div>
                <!--</div>-->
                <!--<div class="left">-->
                    <div class="card cw50 left">
                        <table>
                            <tr><th colspan="3">web API</th></tr>
                            <tr v-for="(item,name) in WebApis" :key="name">
                                <td><v-icon :name="isAvailable(item)" v-bind:class="ClassAvailable(item)"></v-icon></td>
                                <td>{{name}}</td>
                                <td>{{item}}</td>
                                <td @click="expandView(name,item)">&nbsp;<v-icon name="edit"></v-icon></td>
                            </tr>
                        </table>
                    </div>
                <!--</div>-->

                <div class="clear"></div>



                <div v-if="isExpand">
                    <div>&nbsp;</div>
                    <div>
                        <span @click="MessageAvailable=!MessageAvailable"><v-icon :name="IconAvailable()" scale="2"></v-icon></span>
                        <span>Status [{{MessageStatus}}]</span>
                        <span @click="submitValues()"><v-icon name="save"></v-icon></span>
                        <div v-if="MessageAvailable">
                            TRUE
                        </div>
                        <div v-else>
                            <span><input type="text" v-model="MessageView" /></span>
                            <span><input type="date" v-model="MessageDate" /></span>

                        </div>

                    </div>
                </div>
                <p>
                    <v-icon name="user-md" scale="2"></v-icon>
                    {{Info()}}
                </p>
            </div>
        </div>
        </div>
</template>

<script>
    
    import { getMemberAppsServiceState, submitServiceStatus, getUserInfo, getWebStatusCode } from '@/moduls/auth.js'
    import axios from 'axios'

    export default {
        name: 'Home',
        props: {
            //suche: {}
        },
        components: {

        },
        data() {
            return {
                ServerStatus: {},
                WebServers: {},
                WebApis: {},
                isExpand: false,
                MessageStatus: '',
                MessageView: '',
                MessageDate: '',
                MessageAvailable: true,
                imgData: '@/assets/images/arrow_down.png',
                invoiceID: 'RL-2022041050',
                rechnung: '',
                CS1: true,
                CS2: false
            }
        },
        mounted() {

        },
        created() {
            this.getData()
        },
        methods: {
            TestPDF() {   //.get(process.env.VUE_APP_API_URL+`api/Kunde/RechnungData64/${this.invoiceID}`)
                axios
                    //.get(`https://webapi.emendata.de/api/Kunde/RechnungData64/${this.invoiceID}`)
                    .get(process.env.VUE_APP_API_URL + `api/Kunde/RechnungData64/${this.invoiceID}`)
                    .then((response) => {
                        this.rechnung = response.data
                    })
                    .catch((err) => {
                        console.log('Error while getting invoice', err)
                    })
            },
            async getData() {
                //console.log('getFilter '+ this.selection);
                this.ServerStatus = await getMemberAppsServiceState()
                this.WebApis = {}
                this.WebServers = {}
            },
            isAvailable(check) {
                //console.log('Chk: '+check)
                if (check.startsWith('Release')){
                    return 'heart'
                }
                return check == 'true' ? 'network-wired' :'exclamation-triangle' //stop-circle'
            },
            ClassAvailable(check) {
                return check == 'true' ? 'work' : 'red'
            },
            IconAvailable() {
                return this.MessageAvailable  ? 'toggle-on': 'toggle-off'
            },
            expandView(name, data) {
                this.MessageStatus = name
                this.MessageAvailable = (data == 'true')
                this.MessageView = data
                this.isExpand = !this.isExpand
                this.MessageDate="2040-12-31"
                return this.isExpand
            },
            async submitValues() {
                let actionJob = 'StopAvailableFile'

                if (this.MessageStatus == 'serviceAvailable') {
                    actionJob = 'StopAvailableFile'
                } else if (this.MessageStatus == 'driveAvailable') {
                    actionJob = 'StopDriveService'
                } else if (this.MessageStatus == 'updatesAvailable') {
                    actionJob = 'StopUpdateService'
                } else if (this.MessageStatus == 'classicDriveAvailable') {
                    actionJob = 'StopDriveClassicService'
                } 

                console.log(await submitServiceStatus(actionJob, this.MessageView, this.MessageAvailable, this.MessageDate))
                this.getData()
                this.isExpand = false
            },
            async GetWebStatus() {
                //let res = await getWebStatusCode('https://www.emendata.de');
                //console.log(res);
            },
            Info() {
                return getUserInfo()?.role
            },

            CStatus(name) {
                if (name == 1) {
                    return this.CS1 ? "toggle-on" :"toggle-off";
                } else if (name == 2) {
                    return this.CS2 ? "toggle-on" : "toggle-off";
                }
            },
            SetCStatus(name) {
                if (name == 1) {
                    this.CS1 = !this.CS1;
                } else if (name == 2) {
                    this.CS2 = !this.CS2;
                }
            }

        }
    };

</script>

<style scoped>
    .red {
        color: orangered;
    }
    .work {
        color: darkgreen;
    }
    .h3 {
        font-family: 'oswaldbook', arial, sans-serif;
        letter-spacing: 1px;
        font-size: 1.2em;
        color: #003a5c;
    }
    .iconbutton {
        float: left;
        padding: 0.5em;
        margin-top: -0.4em;
    }
</style>