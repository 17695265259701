<template>
    <div v-bind:id="appcenter()">
        <div id="navh" v-if="isLoggedIn()">

            <div class="suchpanel"><suchleiste @search="dosuche"></suchleiste></div>

            <img id="logo" src="@/assets/images/emd00.png" />
            <router-link to="/" class="rlink" title="Startseeite">Home</router-link> &nbsp;
            <router-link to="/kunden" v-if="isAdminUser() || isKundenUser() || isFinanzUser()" class="rlink" title="Kundenverwaltung">Kunden</router-link> &nbsp;
            <router-link to="/invoice" v-if="isAdminUser() || isKundenUser() || isFinanzUser()" class="rlink" title="Monatsrechnungen">InvoiceMan</router-link> &nbsp;
            <!--<router-link to="/updates" v-if="isAdminUser() || isUpdateUser()" class="rlink" title="Desk Updates">Updates</router-link> &nbsp;-->
            <router-link to="/webmin" v-if="isAdminUser() || isWebUser()" class="rlink" title="Web Defaults">WWW</router-link> &nbsp;
            <router-link to="/service" v-if="isAdminUser() || isUpdateUser()" class="rlink" title="Spezial Jobs">Service</router-link>  &nbsp;
            <!--<router-link to="/about">!</router-link> |-->
            <router-link to="/logout" class="px5" title="Logout">
                <v-icon label="UserInfo" class="loitem" :title="getUserInfo()">
                    <v-icon name="sign-out-alt" scale="2" class="back"></v-icon>
                    <v-icon :name="logouticon()" scale="1.2" class="fore"></v-icon>
                </v-icon>
            </router-link>

        </div>
        <div class="top10 fullHeight">
            <router-view :suche="suchpattern" @refreshsuche="refreshSuche" />
        </div>
        <div id="footer">
            e:mendata GmbH {{getJahr()}}
            <div>[{{databaseEnv()}}]</div>
        </div>
    </div>
</template>

<script>
    import { isLoggedIn, getUserInfo, isAdminUser, isPowerUser, isUpdateUser, isFinanzUser, isKundenUser, isWebUser, getLoginUsereMail, getLoginUserName} from '@/moduls/auth'
    import suchleiste from '@/components/suchleiste'
    //import Icon from 'vue-awesome/icons'
    //import Icon from 'vue-awesome/components/Icon'

    export default {
        data() {
            return {
                suchpattern: {},
                working: false,
            }
        },
        methods: {
            databaseEnv() {
                return `${process.env.VUE_APP_API_URL}` 
            },
            isLoggedIn() {
                console.log(this.$router.currentRoute.name)
                if (this.$router.currentRoute.name === 'rechnung') {
                    window.document.height= '100vh'
                    return false
                }
                return isLoggedIn()
            },
            appcenter() {
                return this.isLoggedIn() ? 'app' : 'appcenter';
            },
            fire(selection) {

                console.log('fires ' + selection + ' ' + getUserInfo())
            },
            isUpdateUser() {
                return isUpdateUser()
            },
            isFinanzUser() {
                return isFinanzUser()
            },
            isWebUser() {
                return isWebUser()
            },
            isKundenUser() {
                return isKundenUser()
                //return getUserInfo()?.role?.includes('Admin')
            },
            isPowerUser() {
                return isPowerUser()
                //return getUserInfo()?.role?.includes('Poweruser')
            },
            isAdminUser() {
                return isAdminUser()
            },
            dosuche(data) {
                //console.log('dosuche '+ data + '  ' + this.$route.name);
                this.suchpattern = data
                if (this.$route.name != 'suche') {
                    this.$router.push('suche')
                }
            },
            logouticon() {
                return isAdminUser() ? 'user-md' : (isPowerUser() ? 'user-tie' : 'user-times')
            },
            refreshSuche(memberID) {
                
                var trigger = { topic: 'memberID', pattern: memberID }

                //console.log('Trigger suche ' + memberID + ' ' + JSON.stringify(trigger))
                this.suchpattern = trigger
            },
            getUserInfo() {
                return getLoginUserName() + ' [' + getLoginUsereMail()+']'
            },
            getJahr() {
                return "2023";
            }
        },
        components: {
            //Icon,
            suchleiste,
        }
    }
</script>

<style>
    #app {
        font-family: 'Avenir', Helvetica, Arial, sans-serif;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        color: #003a5c;
        /*margin-top: -0.5em;*/
    }

    #appcenter{
        text-align: center;
        min-width: 800px !important;
    }

    #footer{
        font-size: 0.7em;
    }
    .fullHeight{
        height: 100%;
    }

    .fore {
        color: #003a5c;
    }

    .back{
        color:lightgray;
    }

    .loitem {
        vertical-align: bottom;
        /*padding-top:-10px;*/
        /*background-color: blue;*/
    }
    #logo {
        height: 49px;
        padding-right: 20px;
        padding-left: 10px;
        margin-top: 4px;
        /*background-color: yellow;*/
    }

    .suchpanel{
        /*padding-top: 0.5em;*/
        float: right;
        width: 245px;
    }

    .top10{
        padding:  10px 10px 10px 10px;
    }
    /*.suche{
        right: 15px;
        top: 15px;
        position: absolute;
        border: solid 1px gray;
        border-radius: 5px;
        padding: 10px;
        background-color: whitesmoke;
        text-align: left;
        font-size: 0.8em;
    }*/

    label{
        display: inline-block;
        min-width: 70px  !important;
    }
    .searchtext{
        width: 150px;
    }

    #navh {
        /*padding: 10px 10px 20px 10px;*/
        padding-bottom: 1px;
        border-bottom: 6px solid #003a5c; /*#d4d4d4;*/
        vertical-align: bottom;
        background: #acc8f2; /*#003A5C;*/
    }

        #navh a {
            color: #acc8f2;
        }

            #navh a.router-link-exact-active {
                color: white;
                font-weight: bold;
            }
</style>

