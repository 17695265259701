<template>
    <div>
        <div>LizenzSchlüssel <input type="text" v-model="lizenzkey" /><button @click="loadKunden">auswählen</button></div>
        <div v-if="LogoWorkPool">
            
            <select v-model="listselection">
                <option v-for="(grp,index) in LogoWorkPool" :key="index">
                    {{grp.gruppenname}}

                </option>
            </select>
            <div v-if="LogoWorkSet">
                <div v-for="(lgo,index2) in LogoWorkSet.images" :key="index2">
                    <LogoVorschau :lgo="mapUploadData(lgo)" @refresh="refreshData"></LogoVorschau>
                    <!--<div>
                        <div>{{lgo.key}}</div>
                        <div class="left"><img :src="setLogo(lgo)" class="preview" /></div>
                        <div class="right"><ImagePreview></ImagePreview></div>
                        <div class="clear"></div>
                    </div>-->
                </div>
                {{LogoWorkSet.stamp}}
            </div>
            <!--{{listselection}}-->

        </div>
    </div>
</template>

<script>
    import { getLogoPools, getLogoSet } from '@/moduls/auth'

    import LogoVorschau from '@/components/logoanzeige.vue'
    //import ImagePreview from '@/components/imagepreview.vue'

    export default {
        name: 'LogoSelektion',
        props: {

        },
        components: {
            //Kundenliste,
            LogoVorschau,
            //ImagePreview
        },
        data() {
            return {
                LogoWorkSet: {},
                LogoWorkPool:[],
                working: false,
                lizenzkey: '3W6P1K5OUEB8MOT8',
                listselection:'',
            }
        },
        methods: {
            async refreshData() {
                await this.getLogos(this.lizenzkey, this.listselection)
                this.$emit('selected', this.listselection)
            },
            mapUploadData(lgo) {
                lgo.gruppe = this.listselection
                lgo.lizenz = this.lizenzkey
                return lgo
            },
            async getLogoGruppen(key) {
                //console.log('redirect '+ key)
                this.LogoWorkPool = await getLogoPools(key)
                //console.log(JSON.stringify(this.LogoWorkPool))
            },
            async getLogos(key, gruppe) {
                this.LogoWorkSet = await getLogoSet(key,gruppe)
            },
            async loadKunden() {
                //console.log('fire gruppen')
                await this.getLogoGruppen(this.lizenzkey);
            },
            setLogo(data) {
                //console.log(data)
                return data.base64
            },
            onChange(event) {
                console.log(event.target.value)
                this.$emit('selected', this.lizenzkey)
            }
        },
        created() {
            
        },
        watch: {
            listselection: async function (val) {
                console.log(val)
                await this.getLogos(this.lizenzkey, this.listselection)
                this.$emit('selected', this.listselection)
            }
        }
    };
</script>

<style scoped>
    
</style>