<template>
    <div>
        Produktliste 
        <div v-for="item in datapool" v-bind:key="item.uid">
            {{item.uid}}{{item.name}}{{item.filepool}}
        </div>

    </div>
</template>

<script>
    import { getAllUpdateApps } from '@/moduls/auth'

    export default {
        name: 'ProduktListe',
        props: {

        },
        components: {
            //Kundenliste,
        },
        data() {
            return {
                datapool: [],
                working: false,
            }
        },
        methods: {
            async getProdukte() {
                this.datapool = await getAllUpdateApps()
            }
        },
        created() {
            this.getProdukte()
        }
    };
</script>

<style scoped>
</style>