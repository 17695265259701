<template>
    <div v-if="ppitem.memberID.length<6">
        <div class="iconbox" @click="OpenDetails()">
            <div :class="ArchivColor(ppitem)"><v-icon name="folder" scale="3"></v-icon></div>
            <div class="CI">{{ppitem.filename.length}}</div>
            <div class="CT">{{ppitem.memberID}}</div>
        </div>
        <div class="p10 clear"></div>
    </div>
</template>

<script>
    
    export default {
        name: 'ArchivPDF',
        props: {
            ppitem: {}
        },
        data() {
            return {
                working: false,
                processworking: false,
               
            }
        },
        methods: {
            OpenDetails() {
                this.$emit('ShowDetails',this.ppitem)
            },
            LDD(DataItem) {
                //var NRDD;
                //for (var i = 0; i < DataItem.filename.length; i++) {
                    console.log(JSON.stringify(DataItem.lastRechDatum))
                //}
                //console.log(JSON.stringify(DataItem))
            },
            ArchivColor(item) {
                console.log('Color')
                if (item.lastRechDatum < '2020-12-31') {
                    console.log('arciv')
                    return 'Archiv'
                }
                if (item.lastRechDatum < '2021-12-31') {
                    console.log('away')
                    return 'Away'
                }
                console.log('ok')
                return 'Activ'
            }

        },
        components: {

        },
        created() {

        }
    };
</script>

<style scoped>

    /*.bb{
        min-width:0.4em;
    }
*/
    .iconbox {
        width: 50px;
        /*border: solid 1px red;*/
        box-shadow: 10px 5px 5px #282525;
        float: left;
    }
    .CI {
        text-align: center;
        color: white;
        font-weight: bold;
        margin-top: -33px;
        font-size:small;
    }
    .CT {
        text-align: center;
        color: #155ad7;
        font-weight: bold;
        margin-top: 10px;
    }

    .Activ {
        color: #003a5c;
    }

    .Away {
        color: gray;
    }

    .Archiv {
        color: lightgray;
    }

    .p10{
        padding: 0.4em;
    }
</style>