<template>
    <div>
        Produktliste<br />

        <div v-if="selectededit==''">

            <table>

                <tr>
                    <th>AppID</th>
                    <th>Name</th>
                    <th>Info</th>
                    <th>Version</th>
                    <th>From</th>
                    <th>Till</th>
                    <th>Changed</th>
                    <th>Action</th>
                </tr>
                <tr v-for="item in datapool" v-bind:key="item.uid" :class="isValid(item.tillValid)">

                    <td>
                        <!--<img v-bind:src="'data:image/jpeg;base64,'+item.logostream" width="24" height="24" />-->
                        {{item.appID}}
                    </td>
                    <td>
                        {{item.name}}
                    </td>
                    <td>
                        {{item.beschreibung}}
                    </td>
                    <td>
                        {{item.version}}
                    </td>
                    <td>
                        {{GetDate(item.firstValid)}}
                    </td>
                    <td>
                        {{GetDate(item.tillValid)}}
                    </td>
                    <td>
                        {{GetDate(item.changed)}}
                    </td>
                    <td @click="SetSelection(item)" class="actionHand">
                        <v-icon name="edit" class="actionHand"></v-icon>
                    </td>
                </tr>
            </table>
        </div>
        <div v-if="selectededit!=''">
            <div @click="KillSelection()" class="actionHand">
                <h3> {{selectededit}}&nbsp;{{selected.name}}</h3>    <b>zurück zur Liste </b><br />
            </div>
            <div>&nbsp;</div>
            <div></div>
            <div>
                <div class="row"><div class="c1">AppID</div><div class="c2">{{selected.appID}}</div></div>
                <div class="row"><div class="c1">Name</div><div class="c2">{{selected.name}}</div></div>
                <div class="row"><div class="c1">Info</div><div class="c2">{{selected.beschreibung}}<v-icon name="edit"></v-icon></div></div>
                <div class="row"><div class="c1">Version</div><div class="c2">{{selected.version}}</div></div>
                <div class="row"><div class="c1">Changed</div><div class="c2">{{selected.changed}}</div></div>
                <div class="row"><div class="c1">From</div><div class="c2">{{selected.tillValid}}</div></div>
                <div class="row"><div class="c1">Till</div><div class="c2">{{selected.firstValid}}</div></div>
                </div>
            <div>
                <H4  @click="expanded=true">Update Tool  <v-icon name="edit" class="actionHand"></v-icon></H4>
                <div v-if="expanded">
                    <div class="prefix">Versionsnummer:</div>
                    <div><input type="text" v-model="selected.version" /></div>

                    <div class="clear"></div>
                    <div class="prefix">Filepooldata:</div>
                    <div class="inputarea"><textarea v-model="FilePool" class="inputarea"></textarea></div>
                    <div><input type="file" ref="myFile" @change="selectedFile"><br /></div>
                    <div class="clear"></div>
                    <div><button>Update Aktiv schalten</button> <button @click="expanded=false">Abbrechen</button></div>
                    <div class="clear"></div>
                </div>
                <!--<div>Change Update Version</div>-->
            </div>
        </div>
    </div>
</template>
<script>
    import {  getAllUpdateApps } from '@/moduls/auth'
    import { ShowDatum, IsInFuture } from '@/moduls/datum'
    export default {
        name: 'ProduktListe',
        props: {
        },
        components: {
            //Kundenliste,
        },
        data() {
            return {
                datapool: [],
                selectededit: '',
                selected:'',
                working: false,
                expanded: false,
                Version: '',
                FilePool:'',
            }
        },
        methods: {
            async getProdukte() {
                this.datapool = await getAllUpdateApps()
            },
            isValid(bis) {
                console.log(bis);
                return IsInFuture(bis) ?"" : "offline";
            },
            GetDate(value) {
                return ShowDatum(value)
                //return moment(String(value)).format('MM/DD/YYYY hh: mm')
            },
            KillSelection() {
                this.selectededit = ''
                this.selected = null
            },
            SetSelection(value) {
                this.selectededit = value.appID
                this.selected = value
            },
            getLogo(data) {
                return process.env.VUE_APP_SCREENLOGOURL + data
            },
            selectedFile() {
                console.log('selected a file');
                console.log(this.$refs.myFile.files[0]);

                let file = this.$refs.myFile.files[0];
                if (!file || file.type !== 'text/plain') return;

                // Credit: https://stackoverflow.com/a/754398/52160
                let reader = new FileReader();
                reader.readAsText(file, "UTF-8");
                reader.onload = evt => {
                    this.FilePool = evt.target.result;
                }
                reader.onerror = evt => {
                    console.error(evt);
                }

            }
        },
        created() {
            this.getProdukte()
        }
    };
</script>
<style scoped>
    table,tr, td {
        border: 1px solid black;
    }
    .offline {
        background: #fca7a7;
    }

    .row{
        display:flex;
    }

    .c1{
        flex:20%;
    }
    .c2{
        flex:80%;
    }
    .prefix {
        float: left;
        width: 150px;
    }
    .inputarea{
        float:left;
        width: 500px;
        height: 300px;
    }
</style>
