<template>
    <div>
        <h3>Mailer Rechnungsläufe</h3>
        <div class="workarea">
            Aktiver Rechnungslauf: <span v-if="processworking">[working]  <v-icon name="spinner" scale="2" spin></v-icon></span><span v-else>[idle]</span>
            <div>
                <div>Vorgang: {{pState.vorgangID}}</div>
                <div>Job : {{pState.prozessState}}</div>
                <div>start: {{Datum(pState.jobStart)}}</div>
            </div>
        </div>
        <div v-if="Modal==0">
             
            <h3>Liste Versand noch offen</h3>
            <div v-for="spitem in pitem" v-bind:key="spitem.vorgang">
                <ArchivItem :item="spitem" :mit="true" @sendmail="MailProcessStart"></ArchivItem>
            </div>
        </div>
        <div v-if="Modal==1">
            <div class="Popup">
                <div class="DialogTitle"><div class="DialogHead">Rechnung Mail versand</div></div>
                <div class="pa">
                    <div class="head w10"> Vorgang {{ProcessID}}  -->  {{Datum(ProcessData[0].erstelltam)}}</div>
                    <div class="ListBox">
                        <div v-for="sendItem in ProcessData[0].positionData" :key="sendItem.rid">
                            <div class="col kid">{{sendItem.kid}}</div>
                            <div class="col">{{sendItem.eMail}}</div>
                            <div class="right">{{getCurrency(sendItem.summe)}}</div>
                            <div class="clear"></div>
                        </div>
                    </div>

                    <div class="clear"></div>
                    <div>
                        <button class="left">Senden</button><button class="right" @click="Modal=0">Zurück</button>
                    </div>
                    <div class="clear"></div>
                </div>
            </div>
        </div>
        <div v-if="Modal==2">
            <v-icon name="spinner" spin scale="2"></v-icon>  Working...
        </div>
    </div>
</template>

<script>
    import { Currency, ShowDateTime } from '@/moduls/datum.js'
    import ArchivItem from '@/components/invoice/archivItem.vue'
    import ArchivPdfItem from '@/components/invoice/PdfTreeItem.vue'
    import { geteMailArchiv, GetInvoiceStatus } from '@/moduls/invoice.js'

    export default {
        name: 'Archiv',
        props: {
        },
        data() {
            return {
                working: false,
                processworking: false,
                ppdf: [],
                pitem: [{
                    versendet: true,
                    gespeichert: true,
                    status: 1,
                    Stamp: '2020-12-31',
                    Positionen: 2,
                    RechnungsID: '007-1234',
                    Detail1: false,
                    Detail2: false,
                    PositionData: [
                        {
                            RID: '20210820120',
                            KID: '2012',
                            Detail: 'Wassmer Haimo',
                            Summe: 100,
                            versendet: false,
                            gespeichert: true,
                        },
                        {
                            RID: '20210820140',
                            KID: '2014',
                            Detail: 'Cheffe H2o',
                            Summe: 110,
                            versendet: true,
                            gespeichert: true,
                        }
                    ]
                }],
                pState: {},
                Modal: 0,
                ProcessID: '',
                ProcessData: {},
            }
        },
        methods: {
            Ok() {
                return this.pitem.status == 1 ? true : false
            },
            Versendet() {
                return this.pitem.versendet ? 'ok' : 'invisible'
            },
            Gespeichert() {
                return this.pitem.gespeichert ? 'ok' : 'invisible'
            },
            async GetArchivData() {
                this.pitem = await geteMailArchiv()
                await this.GetStatus()
                //console.log(JSON.stringify(this.pitem))
            },
            getCurrency(item) {
                return Currency(item);
            },
            Datum(item) {
                return ShowDateTime(item)
            },
            async GetStatus() {
                this.pState = await GetInvoiceStatus()
                if (this.pState.isWorking) {
                    this.processworking = true
                }
            },
            MailProcessStart(item) {
                console.log('Mailer Start' + item);
                this.Modal = 1
                this.ProcessID = item

                this.ProcessData = this.pitem.filter(function (e) { return e.vorgangID==item });

            },
            GetMailProcess() {

            }

        },
        components: {
            ArchivItem,
            ArchivPdfItem
        },
        created() {
            this.GetArchivData()
        }
    };
</script>

<style scoped>
    .Popup {
        font-size: 16px;
        border: solid 1px gray;
        padding: 2em;
        position: absolute;
        width: 500px;
        height: 450px;
        z-index: 15;
        top: 20%;
        left: 50%;
        margin: -100px 0 0 -150px;
        background: white;
        box-shadow: 10px 10px darkgray;
    }

    .ListBox{
        height: 250px;
        overflow-y:auto;
    }

    .DialogTitle {
        background-color: #008CBA;
        color: white;
        width: 100%;
        height: 5em;
    }

    .col {
        float: left;
    }

    .kid{
        padding-right: 1em;
        font-weight:bold;
    }

    .DialogHead {
        top: 30%;
        left: 25%;
        position: relative;
        font-size: 24px;
    }

    .errorBox {
        padding: 2em;
        text-align: center;
        color: darkred;
        font-weight: bold;
        border: solid 1px darkred;
        margin-top: 0.5em;
        background-color: burlywood;
    }

    .infoBox {
        padding: 2em;
        text-align: center;
        color: blue;
        font-weight: bold;
        border: solid 1px blue;
        margin-top: 0.5em;
        background-color: aliceblue;
    }

    .block {
        padding-top: 0.2em;
        padding-bottom: 0.2em;
        border-bottom: solid 1px gray;
    }

    .pa {
        padding: 5px;
        padding-top: 10px;
        padding-right: 40px;
        font-family: "Helvetica", arial, sans-serif;
        font-size: 18px;
    }

    .head {
        font-weight: bold;
        font-size: large;
    }

    .w10 {
        padding: 1.5em;
    }

    .PT20 {
        padding-top: 2em;
    }

    .pw10 {
        padding-left: 1.5em;
        padding-right: 1.5em;
        padding-top: 0.5em;
    }

    .disabled {
        visibility: hidden;
    }

    .enabled {
        visibility: visible;
    }
</style>