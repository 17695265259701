<template>
    <div class="viewbox">
        <div v-if="data">
            <div class="posleft">
                Drive Analyse: {{data.memberID}} [{{data.aKey}}]
            </div>
            <div class="left p10 actionHand" @click="getData(data.aKey)"> <v-icon name="cloud" scale ="1.4"></v-icon>  ServerDaten </div>
            <div class="left p10 actionHand" @click="getProtokoll(data.aKey)"> <v-icon name="sitemap" scale ="1.4"></v-icon> ServerSyncProtokoll </div>
            <div class="clear"></div>

            <div v-if="Serverdaten">
                ServerDaten
            </div>
            <div v-if="Serverprotokoll">
                Serverprotokoll
                <div v-for="(item, index) in Serverprotokoll" :key="index">
                    <div v-if="showStart(item)==true">
                        <hr>
                    </div>
                    <div v-else>
                        <div class="left pr5">{{item.stamp}}</div>
                        <div class="left w100">{{item.art}}</div>
                        <div class="left w100 pr5">{{item.client}}</div>
                        <div class="left">{{item.message}}</div>
                    </div>
                    <div class="clear"></div>

                </div>
            </div>

        </div>
    </div>
</template>

<script>

    //import { getServerHashDataSet, getServerProtokoll } from '@/moduls/auth.js'

    export default {


        name: 'DriveAnzeige',
        props: {
            data: Object,
        },
        components: {

        },
        data() {
            return {
                rawview: false,
                working: false,
                panelstate: [
                    { topic: 'add', expand: false },
                    { topic: 'ban', expand: false },
                    { topic: 'kau', expand: false },
                    { topic: 'upd', expand: false },
                    { topic: 'raw', expand: false },
                    { topic: 'inv', expand: false },
                    { topic: 'drv', expand: false }
                ],
                kaufid: '',
                RMEdit: false,
                Serverprotokoll: null,
                Serverdaten: null,
                Hashes :null,
            }
        },
        methods: {
            isExpand(panel) {
                for (var i = 0; i < this.panelstate.length; i++) {
                    if (panel == this.panelstate[i].topic) {
                        if (this.panelstate[i].expand && panel == 'kau') {

                            this.kaufid = this.data.memberID
                            console.log('kauf' + this.kaufid)
                        }
                        return this.panelstate[i].expand
                    }
                }
                return false
            },
            initState() {
                this.panelstate = [
                    { topic: 'add', expand: false },
                    { topic: 'ban', expand: false },
                    { topic: 'kau', expand: false },
                    { topic: 'upd', expand: false },
                    { topic: 'raw', expand: false },
                    { topic: 'inv', expand: false },
                    { topic: 'drv', expand: false }
                ]
            },
            reload(memberID) {
                console.log('refresh ' + memberID)
                this.$emit('refresh', memberID)
            },
            async getData(lizenz) {
                console.log(lizenz)
                return
                //this.Hashes = await getServerHashDataSet(lizenz)
                //console.log(JSON.stringify(this.Hashes))
            },
            async getProtokoll() {
                return
                //this.Serverprotokoll = await getServerProtokoll(lizenz, 'SYNC')
                //console.log(JSON.stringify(this.Serverprotokoll))

            },
            async showStart(item) {
                console.log('-'+item.art + '-   '+(item.art==='[START]'))
                return item.art==='[START]'? true: null
            }

        },
        created() {

        },
        watch: {
            data: function () {
                //console.log('Trigger new Search' + JSON.stringify(v))
                this.initState()
            }
        }
    };
</script>

<style scoped>
    .viewbox {
        padding: 0.5em;
        border: 1px solid gray;
        border-radius: 5px;
    }
    .posleft {
        float: left;
        width: calc(100vh -100px);
    }

    .posright {
        float: right;
    }
    .p10 {
        padding-left:1em;
        padding-right: 1em;
    }

    .pr5 {
        padding-right: 0.5em;
    }

    .w100{
        width: 100px;
    }
</style>