<template>
    <div><div class="block head">
    <h3>Einstellungen</h3>
    <div v-for="(item,index) in suchpattern" v-bind:key="index">
        {{item.memberEmail}}
    </div>
    
</div></div>
</template>

<script>
    import { getKundenliste } from '@/moduls/invoice'

    
    export default {
        data() {
            return {
                suchpattern: [],
                working: false,
                state: 0,
            }
        },
        methods: {
            async LgetKundenliste() {
                this.suchpattern = await getKundenliste()
                // console.log(getKundenliste())
            },
        },
        components: {

        },
        created() {
            this.LgetKundenliste()
        }
    }
</script>

<style scoped>
</style>