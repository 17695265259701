<template>
    <div id="window">
        <h1 @click="Step=0">Gateway e:mendata Backend</h1>
        <div v-if="Step==0" class="rahmen">
            <div class="LH">Login</div>
            <div class="clear"></div>
            <div class="input-group">
                <label>Username</label>
                <input type="text" v-model="username">
            </div>
            <div class="clear"></div>
            <div class="input-group">
                <label>Password</label>
                <input type="password" v-model="password">
            </div>
            <div class="clear"></div>
            <div class="input-group">
                <label class="actionHand " @click="prereset">Password vergessen</label>

            </div>
            <div class="clear"></div>
            <div v-if="working">
                <img src="../assets/images/emenloading.gif" />
                <div>verarbeite Daten...</div>
            </div>
            <div class="button" v-else v-on:click="login">
                Anmelden
            </div>
            <div class="clear"></div>
            <div v-if="submiterror" id="errorbox">{{submiterror}}</div>
            <div class="clear"></div>
        </div>
        <div v-if="Step==2" class="rahmen">
            <div class="LH">Password zurücksetzen</div>
            <div class="clear"></div>
            <div class="input-group">
                <label>Username</label>
                <input type="text" v-model="captchaUser" placeholder="<eMail>" autocomplete="nope">
            </div>
            <div class="clear"></div>
            <div class="input-group">
                <Captcha></Captcha>
            </div>
            <div class="clear"></div>
            <div class="input-group">
                <label>Code</label>
                <input type="text" v-model="captcha" placeholder="<Code>" autocomplete="nope">
            </div>

            <div class="clear"></div>
            <div v-if="working">
                <img src="../assets/images/emenloading.gif" />
                <div>verarbeite Daten...</div>
            </div>
            <div class="button" v-else v-on:click="reset">
                Password zurücksetzen
            </div>
            <div class="clear"></div>
            <div v-if="submiterror" id="errorbox">{{submiterror}}</div>
            <div class="clear"></div>
        </div>
        <div v-if="Step==4" class="rahmen">
            <div class="LH">Passwort zurücksetzen</div>
            <div class="clear"></div>
            <div>Ihnen wurde eine eMail mit einem Initialpasswort zugesendet. Bitte verwenden Sie dieses Initialpasswort um fortzufahren.</div>
            <div class="clear"></div>
            <div class="input-group">
                <label>Initial Passwort</label>
                <input type="text" v-model="pass0" placeholder="<Code aus eMail>" autocomplete="nope">
            </div>

            <div class="clear"></div>
            <div class="input-group">
                <label>Neues Passwort</label>
                <input type="password" v-model="pass1" placeholder="<neues Passwort>" autocomplete="nope">
            </div>
            <div class="clear"></div>
            <div class="input-group">
                <label>Passwort wiederholen</label>
                <input type="password" v-model="pass2" placeholder="<neues Passwort wiederholen>" autocomplete="nope">
            </div>

            <div class="clear"></div>
            <div v-if="working">
                <img src="../assets/images/emenloading.gif" />
                <div>verarbeite Daten...</div>
            </div>
            <div class="button" v-else v-on:click="finalize">
                Password übernehmen
            </div>
            <div class="clear"></div>
            <div v-if="submiterror" id="errorbox">{{submiterror}}</div>
            <div class="clear"></div>
        </div>
    </div>
</template>

<script>
    import Captcha from '@/components/captchaPanel.vue'
    import { loginUser, ResetPassword1, ChangePassword1 } from '@/moduls/auth'
    export default {
        name: 'login',
        data() {
            return {
                username: '',
                password: '',
                captchaUser:'',
                captcha: '',
                pass0: '',
                pass1: '',
                pass2:'',
                submiterror: '',
                working: false,
                Step:0,
            }
        },
        components: {
            Captcha,
        },
        methods: {
            async login() {
                try {
                    this.working = true
                    await loginUser(this.username, this.password)
                    this.working = false
                    this.$router.push('/')
                } catch (err) {
                    this.working = false
                    this.submiterror = (`${err}`)
                }
            },
            async prereset() {
                this.working = false
                this.Step = 2
            },
            async finalize() {
                this.working = true
                this.submiterror = ''
                if (this.pass0 && this.pass0.length > 5 && this.pass1 && this.pass1.length > 3 && this.pass2 == this.pass1) {
                    let finale = await ChangePassword1(this.pass0, this.pass1, this.captchaUser)
                    if (finale == true) {
                        this.submiterror = "Passwort wurde erfolgreich geändert";
                        this.Step = 0;
                        this.pass0 = this.pass1 = this.pass2 = this.captchaUser = this.captcha = ''
                        this.working = false
                        return
                    }
                    this.submiterror = "Bitte Eingaben überprüfen";
                } else {
                    this.submiterror = "Bitte Eingaben überprüfen";
                }
                this.pass0 = this.pass1 = this.pass2 = this.captchaUser = this.captcha = ''
                this.working = false
            },
            async reset() {
                try {
                    this.working = true
                    this.submiterror =''
                    if (this.captchaUser && this.captchaUser.length > 5 && this.captcha && this.captcha.length > 3) {
                        let res = await ResetPassword1(this.captchaUser, this.captcha)
                        if (res) {
                            this.Step = 4
                        } else {
                            this.submiterror = "Bitte Eingaben überprüfen";
                        }
                    } else {
                        this.submiterror = "Bitte Eingaben überprüfen";

                    }
                    this.working = false
                } catch (err) {
                    this.working = false
                    this.submiterror = (`${err}`)
                }
            }
        }
    }
</script>

<style scoped>
    #window{
        padding-bottom: 10px;
    }

    #errorbox{
        padding: 20px;
        color: orangered;
        font-weight: bold;
    }

    .button {
        background-color: #008CBA; 
        border: none;
        border-radius: 4px;
        color: white;
        padding: 0.5em 1em;
        margin-right: 2em;
        text-align: center;
        text-decoration: none;
        display: inline-block;
        font-size: 1em;
        cursor: pointer;
        width: 100px;
        float: right;
    }

    input {
        border: none;
        background: none;
        border-bottom: 1px dotted #666666;
        border-top: 0px;
        border-left: 0px;
        border-right: 0px;
        width: 100%;
        font-size: large;
    }

    .input-group {
        float: left;
        margin: 1rem;
        width: 460px;
    }

        .input-group label {
            margin-right: 0.5rem;
            width: 100%;
            text-align: left;
            font-size: small;
        }

    .rahmen {
        border: solid 2px #dedede;
        border-radius: 4px;
        padding-bottom: 10px;
        margin-left: auto;
        margin-right: auto;
        min-width: 500px;
        max-width: 500px;
        box-shadow: lightgray 4px 4px;

        /*background-color: yellow;*/
    }

    .LH{
        float: left;
        font-size:large;
        font-weight: bold;
        padding: 1em;
    }

    
</style>