<template>
    <div>
        <div>About</div>
        <div>&nbsp;</div>
        <Captcha></Captcha>
        <div>&nbsp;</div>
        <div>&nbsp;</div>
        <div @click="doReset">Reset</div>
        <input type="text" v-model="captchaUser" placeholder="<eMail>" autocomplete="nope">
        <input type="text" v-model="captcha" placeholder="<Code>" autocomplete="nope">
    </div>
</template>

<script>


    //getCaptcha

    import Captcha from '@/components/captchaPanel.vue'
    import { ResetPassword0 } from '@/moduls/auth.js'

    export default {
        name: 'About',
        props: {
            //suche: {}
        },
        components: {
            Captcha,
        },
        data() {
            return {
                code: '',
                captchaUser: '',
                captcha: '',
                //ServerStatus: {},
            }
        },
        mounted() {

        },
        created() {
            //this.getData()
        },
        methods: {

            async doReset() {
                console.log(this.captchaUser)
                console.log(this.captcha)
                if (this.captchaUser && this.captchaUser.length > 5 && this.captcha && this.captcha.length > 3) {
                    await ResetPassword0(this.captchaUser, this.captcha)
                }
            }
            //async getData() {
            //    //console.log('getFilter '+ this.selection);
            //    this.ServerStatus = await getCaptcha()
            //},
            //getImage() {
            //    return this.ServerStatus
            //}

        }
    }

</script>

<style scoped>
    
</style>