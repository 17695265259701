<template>
    <div v-if="item2 && item2.pid">
        <div class="prefix">-</div>
        <div :class="CSSPidItem(item2)">PID: {{item2.pid}}</div>
        <div class="left pr2">Rechnung ab : {{Datum(item2.invoiceFrom)}}</div>
        <div class="left ">Änderung : {{Datum(item2.changed)}}</div>


        <div v-if="!isExpand">
            <div class="left pl2 pr1" @click="isExpand=true"><v-icon name="edit" scale="1.4"></v-icon></div>
        </div>

        <div v-if="isSonderFinanz(item2)">
            <div v-if="item2.finanz.sonderRabatt!=0" class="left pr2">SonderRabatt: {{Currency(item2.finanz.sonderRabatt)}}</div>
            <div v-if="item2.finanz.freieMonate!=0" class="left pr2">Freie Monate: {{item2.finanz.freieMonate}}</div>
            <div v-if="item2.finanz.nichtBerechnen==true" class="left pr2">Nicht Berechnen: {{item2.finanz.nichtBerechnen}}</div>
            <div v-if="item2.finanz.nichtVerwenden==true" class="left pr2">Nicht Verwenden: {{item2.finanz.nichtVerwenden}}</div>
            <div v-if="item2.finanz.buchungsPreis!=0" class="left pr2">Preis: {{Currency(item2.finanz.buchungsPreis)}}</div>
        </div>
        <div v-else>
            <div :class="CSSDataItem(item2)">Netto: {{Currency(item2.nettoPreis)}}</div>
            <div v-if="item2.finanz.nichtBerechnen==true" class="left pr2">Nicht Berechnen: {{item2.finanz.nichtBerechnen}}</div>
            <div v-if="item2.finanz.nichtVerwenden==true" class="left pr2">Nicht Verwenden: {{item2.finanz.nichtVerwenden}}</div>
        </div>

        <div v-if="isExpand" :class="CSSBox(item2)">
            <div class="left pr1" @click="UpdateVerwenden(item2)"><v-icon :name="VerwendenState()" scale="1.6"></v-icon> Verwenden</div>
            <div class="left pr1" @click="UpdateBerechnen(item2)"><v-icon :name="BerechnenState()" scale="1.6"></v-icon> Berechnen</div>


            <div class="left pr1">Sonderrabatt: <input type="text" class="resizedTextbox2" v-model="item2.finanz.sonderRabatt" /></div>
            <div class="left pr1">freie Monate: <input type="text" class="resizedTextbox2" v-model="item2.finanz.freieMonate" /></div>

            <div class="left pr1">Preis: <input type="text" class="resizedTextbox2" v-model="item2.finanz.buchungsPreis" /></div>
            <div class="left pl2" @click="UpdateFinanz(item2)"><v-icon name="save" scale="2"></v-icon></div>
        </div>
        <!--<div v-else>
        <div class="left pl2" @click="isExpand=true"><v-icon name="edit" scale="1.4"></v-icon></div>
    </div>-->

        <div class="clear"></div>
    </div>
</template>

<script>
    import { ShowDateTime, ShowDatum } from '@/moduls/datum.js'
    import { updatePIDFinanz} from '@/moduls/invoice.js'

    export default {
        data() {
            return {
                isExpand: false,
                working: false,
            }
        },
        props: {
            item2: {},
        },
        methods: {
            databaseEnv() {
                return `${process.env.VUE_APP_API_URL}`
            },
            DatumTime(item) {
                return ShowDateTime(item)
            },
            Datum(item) {
                return ShowDatum(item)
            },
            Currency(item) {
                if (item) {
                    return Number.parseFloat(item).toFixed(2) + ' €' //     item.toLocaleString('de-DE', { style: 'currency', currency: 'EUR', minimumFractionDigits: 2 })
                }
                return '-.-'
            },
            CurOrPercent(item) {
                if (item) {
                    //console.log('->'+item+'<-')
                    if (item >= -1 && item <= 1) {
                        return item * 100 + ' %'
                    } else {
                        return Number.parseFloat(item).toFixed(2) + ' €'  //item.toLocaleString('de-DE', { style: 'currency', currency: 'EUR', minimumFractionDigits: 2 })
                    }
                }
                return '-.-'
            },
            isSonderFinanz(item) {
                return item.finanz && (item.finanz.sonderRabatt != 0 || item.finanz.freieMonate != 0 || item.finanz.buchungsPreis !=0)
            },
            BerechnenState() {
                //item2.finanz.nichtBerechnen = !item2.finanz.nichtBerechnen
                return !this.item2.finanz.nichtBerechnen?'toggle-on':'toggle-off'
            },
            VerwendenState() {
                return !this.item2.finanz.nichtVerwenden?'toggle-on':'toggle-off'
            },
            async UpdateBerechnen(item) {
                item.finanz.nichtBerechnen = !item.finanz.nichtBerechnen
                console.log('Update Berechnen ' + item.pid)
                await updatePIDFinanz(item.memberID, item.pid, item.finanz)
                this.isExpand = false
                this.$emit('refresh', this.item)
            },
            async UpdateVerwenden(item) {
                item.finanz.nichtVerwenden = !item.finanz.nichtVerwenden
                console.log('Update Verwenden ' + item.pid)
                //let finanz = { pid: this.item2.pid, memberID: this.item2.memberID }
                await updatePIDFinanz(item.memberID, item.pid, item.finanz)
                this.isExpand = false
                this.$emit('refresh', this.item)
            },
            async UpdateFinanz(item) {
                console.log('Update Finanz '+ item.pid)
                await updatePIDFinanz(item.memberID, item.pid, item.finanz)
                this.isExpand = false
                this.$emit('refresh', this.item)
            },
            CSSPidItem(item) {
                return 'left pr2 pl5' + (item.finanz.nichtVerwenden == true ? ' notUse ' : (item.finanz.nichtBerechnen==true ? ' noCalc' :''))
            },
            CSSDataItem(item) {
                return 'left pr2' + (item.finanz.nichtVerwenden == true || item.finanz.nichtBerechnen == true ? ' notUse ' : '')
            },
            CSSBox(item) {
                return 'left pr2 box ' + (item.finanz.nichtVerwenden == true ? ' boxNoUse ' : (item.finanz.nichtBerechnen == true ? ' boxNoCalc' : ''))
            }
        },
        created() {
            //console.log(this.item2)
        },
    }
</script>

<style scoped>

    .prefix {
        float:left;
        width: 2em !important;
        min-width: 2em;
        padding-left: 1em;
        padding-right: 1em;
        overflow: auto;
    }

    .box {
        border: solid 1px gray;
        padding: 0.4em;
    }

    .boxNoUse {
        border: solid 1px red;
        background-color: palevioletred;
    }
    .boxNoCalc {
        border: solid 1px mediumvioletred;
        background-color: lightgray;
    }

    .notUse {
        color: red;
        text-decoration: line-through;
    }

    .noCalc{
        color:mediumvioletred;
    }

    .noUse {
        color: red;
    }

    .resizedTextbox1 {
        width: 100px;
        height: 24px
    }
    .resizedTextbox2 {
        width: 50px;
        height: 24px
    }
    .pr1{
        padding-right: 1em;
    }
</style>