<template>
    <div>
        <div title="Adressdaten" @click="Toggle('add')" :class="isExpandIcon('add')"><v-icon name="address-card" scale="1.4"></v-icon></div>
        &nbsp;&nbsp;
        <div title="Bankdaten" @click="Toggle('ban')" :class="isExpandIcon('ban')"><v-icon name="credit-card" scale="1.4"></v-icon></div>
        &nbsp;&nbsp;
        <div title="K�ufe" @click="Toggle('kau')" :class="isExpandIcon('kau')"><v-icon name="shopping-cart" scale="1.4"></v-icon></div>
        &nbsp;&nbsp;
        <div title="CreateRechnung" @click="createPdf" class="iconbutton"><v-icon name="camera" scale="1.4"></v-icon></div>

        &nbsp;&nbsp;
        <div title="Rechnungen" @click="Toggle('inv')" :class="isExpandIcon('inv')"><v-icon name="file-pdf" scale="1.4"></v-icon></div>
        &nbsp;&nbsp;
        <div title="Aktivierungskey" @click="Toggle('upd')" :class="isExpandIcon('upd')"><v-icon name="fingerprint" scale="1.4"></v-icon></div>
        &nbsp;&nbsp;
        <div title="Updatedata" @click="Toggle('raw')" :class="isExpandIcon('raw')"><v-icon name="sitemap" scale="1.4"></v-icon></div>
        &nbsp;&nbsp;
        <div title="Drive" @click="Toggle('drv')" :class="isExpandIcon('drv')"><v-icon name="cloud" scale="1.4"></v-icon></div>
        <!--{{value}}-->
    </div>
</template>

<script>
    export default {

        name: 'KundenDetailAuswahl',
        props: {
            value: Array,
        },
        components: {

        },
        data() {
            return {
 
            }
        },
        methods: {
            isExpand(panel) {
                for (var i = 0; i < this.value.length; i++) {
                    if (panel == this.value[i].topic) return this.value[i].expand
                }
                return false
            },
            isExpandIcon(panel) {
                return this.isExpand(panel) ?'iconbutton open' : 'iconbutton closed'
            },
            Toggle(panel) {
                for (var i = 0; i < this.value.length; i++) {
                    if (panel == this.value[i].topic) this.value[i].expand = !this.value[i].expand
                }
            },
            createPdf() {
                this.$emit('createpdf',1)
            }
        },
        created() {

        }
    };
</script>

<style scoped>
    .open {
        color: #003a5c;
    }

    .closed{
        color: gray;
    }

    .iconbutton{
        float: left;
        padding: 0.5em;
        margin-top: -0.4em;
    }

    .iconbutton:hover {
        background: lightgray;
    }

</style>