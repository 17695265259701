<template>
    <div :class="IsDruck(rechnung)" :title="Drucken(rechnung)">
        <!--{{rechnung}}-->
        <div class="col01">{{rechnung.MandatsReferenz}}  <v-icon name="infinity" v-if="rechnung.DauerRechnung"></v-icon> </div>
        <div class="col03">{{rechnung.Kunde.Firma}}</div>
        <div class="col02">{{rechnung.Kunde.ASP}}</div>

        <div class="col01">Pos: {{rechnung.Positionen.length}}</div>
        <div class="col01 tar">{{getCurrency(rechnung.DriveSummen.RechnungSumme)}}</div>
        <!--{{rechnung.DriveSummen}}-->
        <div class="clear"></div>
    </div>
</template>

<script>
    import { Currency } from '../../moduls/datum.js';

    export default {
        name: 'JobSubItem',
        props: {
            rechnung: {}
        },
        data() {
            return {
                working: false,
                processworking: false,
            }
        },
        methods: {
            getCurrency(data) {
                return Currency(data);
            },
            IsDruck(data) {
                //console.log(JSON.stringify(data))
                return (data.DauerRechnung==true && data.CreateDauerRechnung==false)? 'NoPrint':''
            },
            Drucken(data) {
                return (data.DauerRechnung == true && data.CreateDauerRechnung == false) ? 'keine Ver�nderung, kein Rechnungsdruck' : ''
            }

        }
    };
</script>

<style scoped>
    .NoPrint{
        background-color: lightgray;
    }
</style>