<template>
    <div>
        <div class="clear"></div>
        <hr />
        <div>
            <div @click="AddShot">[Add Shot]</div>
            <div v-if="editmode">Shotlink:  <input type="text" v-model="ShotLink" /><button @click="FireAdd">�bernehmen</button><button @click="editmode=false">Abbrechen</button></div>
        </div>
        <div v-for="item in Shots" :key="item">
            <img :src="getScreenData(item)" width="200px" />
            <div>{{item}}</div>
        </div>
        <hr class="clear" />
    </div>
</template>

<script>

    import { addShot2Tool } from '@/moduls/auth'

    export default {
        name: 'Screenshots',
        props: {
            Shots: [],
            pid: '',
            Gruppe:'',
        },
        components: {
            //Kundenliste,
        },
        data() {
            return {
                working: false,
                editmode: false,
                ShotLink: '',
            }
        },
        methods: {
            AddShot() {
                console.log('AddShot')
                this.editmode=true
            },
            async FireAdd() {
                console.log('Fire Up')
                await addShot2Tool(this.pid, this.ShotLink, this.Gruppe)
            },
            getScreenData(data) {
                //console.log(process.env.VUE_APP_SCREENSHOTURL + " ----")
                return process.env.VUE_APP_SCREENSHOTURL+ data
            }
        },
        created() {

        }
    };
</script>

<style scoped>
</style>