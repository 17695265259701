<template>
    <div>
        <div>KDNr: <input type="text" v-model="kundenauswahl" /> <span @click="suche" class="actionHand">Suche</span></div>
        <div class="hr"></div>
        <div v-if="pool" class="w45">
            
            <div v-for="(item,index) in pool" :key="index">
                <div :class="Abgelaufen(item.validTill)">
                    <div class="left actionHand" @click="doSelect(item)">{{item.pid}}</div>
                    <!--<div class="left">{{item.nettoPreis}}</div>-->
                    <!--<div class="right">Ge�ndert:{{getDatum(item.changed)}}</div>-->
                    <!--<div class="clear"></div>-->

                    <div class="left pl2">Rechnung ab: {{getDatum(item.invoiceFrom)}}</div>
                    <div class="right">Valid bis:{{getDatum(item.validTill)}}</div>
                    <div class="clear"><hr /></div>
                </div>
            </div>
        </div>
        <div v-if="editpool">Details: KdnR: <b>{{editpool.memberID}}</b></div>
        <div v-if="editpool">
            <div class="box">
                <div class="left pr2">Produkt : <b>{{editpool.pid}}</b></div>
                <div class="left">Rechnung: <input type="text" v-model="editpool.invoiceFrom" /></div>
                <div class="left">Valid   : <input type="text" v-model="editpool.validTill" /></div>
                <div class="left pl2 actionHand" @click="UpdateFire"> <v-icon name="save" scale="1.5"></v-icon></div>
                <div class="left"><small>({{editpool.memberID}})</small></div>
                <div class="clear"></div>
            </div>
            <div class="hr"></div>
            {{editpool}}

        </div>
    </div>
</template>

<script>

    import { GetKundenKaufPool, RefreshKaufSatz } from '@/moduls/auth.js'
    import { ShowDateTimeUTC, GetNow } from '@/moduls/datum.js'

    export default {
        name: 'KMani',
        props: {
            //suche: {}
        },
        components: {

        },
        data() {
            return {
                selection: 0,
                working: false,
                kundenauswahl: '',
                editpool: {},
                pool: []
            }
        },
        mounted() {

        },
        methods: {
            async suche() {
                console.log(this.kundenauswahl);
                await this.GetKaufListe(this.kundenauswahl)
                this.editpool = null
            },
            async GetKaufListe(lizenz) {
                this.pool = await GetKundenKaufPool(lizenz)
            },
            getDatum(wert) {
                return ShowDateTimeUTC(wert)
            },
            doSelect(item) {
                console.log(item)
                this.editpool = item
            },
            async UpdateFire() {
                console.log('Fire')
                let wp = this.editpool
                await RefreshKaufSatz(wp.pid, this.kundenauswahl, wp.invoiceFrom, wp.validTill)
            },
            Abgelaufen(item) {
                return item > GetNow() ? "OK" : "OUT"
            }
        }
    };
</script>

<style scoped>
    .w45{
        width:70%;
        min-width: 300px;
    }
    .hr{
        border-top:solid 5px gray;
    }

    .box{
        border: solid 4px lightgray;
        padding: 5px;
    }

    .OUT{
        color: red;
    }
</style>