<template>
    <div>
        <div class="block head"><h3>Rechnung Freigaben</h3> </div>
        <hr />
        <div>
            <div>letzter Status:</div>
            <div>{{status.vorgangID}} ({{status.jobStart}})</div>
            <div>Status: {{status.prozessState}}</div>
            <div>Info: {{status.isWorking}}/{{status.canSend}} </div>
            <div>Error: {{status.error}}</div>
            <div>&nbsp;<br /></div>
        </div>
        <hr />
        <div><b>Rechnungen : {{status.vorgangID}}  [{{status.rechnungsMonat}}]</b> </div>
        <hr />
        <div>
            <div v-for="(rechnung,index) in metas.Meta" :key="index">

                <JobDataZeile :rechnung="rechnung"></JobDataZeile>
            </div>
            <div class="hrtop">
                <div class="col03">.</div>
                <div class="col03">.</div>
                <div class="col02 tar"><b>Summe: {{getCurrency(getRechnungenGesamtSumme())}}</b></div>
                <div class="clear"></div>
            </div>
        </div>
        <!--<div>
            <div v-for="(rechnung,index) in metas.Meta" :key="index">

                <JobDataZeile :rechnung="rechnung"></JobDataZeile>
            </div>
            <div class="hrtop">
                <div class="col03">.</div>
                <div class="col03">.</div>
                <div class="col02 tar"><b>Summe: {{getCurrency(getRechnungenGesamtSumme())}}</b></div>
                <div class="clear"></div>
            </div>
        </div>-->
        <div class="block head">
            <div class="col01"><v-icon :name="ActionStatusIcon(0)" scale="1.8"></v-icon></div>
            <div class="col03">
                <button @click="GetServerBlob()" v-if="ActionStatus(0)==1">Rechnung Zusammenfassen -> anzeigen</button>
                <button v-else disabled>Rechnung Zusammenfassen -> anzeigen</button>
            </div>
            <div class="col03"> JobState: {{JS}} </div>
            <div class="clear"></div>
        </div>
        <hr />
        <div v-if="IsAvailable(1)" class="block head">
            <div class="col01"><v-icon :name="ActionStatusIcon(1)" scale="1.8"></v-icon></div>
            <div class="col03">
                <button @click="Modal=1" :title="FreigabeCode" v-if="ActionStatus(1)==1">Rechnung Freigeben -> Versenden</button>
                <button v-else disabled>Rechnung Freigeben -> Versenden</button>
            </div>
            <div class="col01 actionHand" @click="openPreviewPdf()" v-if="ActionStatus(1)==1"><v-icon name="eye" scale="1.9" title="Merged PDF"></v-icon></div>
            <div class="col02"> JobState: {{JS1}} </div>
            <div class="clear"></div>
        </div>
        <hr />
        <div v-if="IsAvailable(2)" class="block head">
            <div class="col01"><v-icon :name="ActionStatusIcon(2)" scale="1.8"></v-icon></div>
            <div class="col03">
                <button @click="Modal=42" title="User Informieren" v-if="ActionStatus(2)==1">Rechnungslauf abschließen</button>
                <button v-else disabled>Rechnungslauf abschließen</button>
            </div>
            <div class="col03"> JobState: {{JS2}} </div>
            <div class="clear"></div>
        </div>
        <div v-if="IsAvailable(3)">
            <v-icon :name="ActionStatusIcon(3)" scale="1.8"></v-icon>
            <h3>Vorgang abgeschlossen</h3>
        </div>

        <div v-if="Modal==42">
            <div class="Popup">
                <div class="DialogTitle"><div class="DialogHead">Abschluss</div></div>
                <div class="pa">


                    <div class="clear"></div>
                    <div class="PT20">
                        <button class="left" @click="StartSendMails()">Abschluss starten.</button>
                        <button class="right" @click="Modal=0">Abbrechen</button>
                    </div>
                    <div class="clear"></div>
                    <div>
                        <div>&nbsp;<br /></div>
                        <div>Rechnungslauf freigegeben.</div>
                        <div></div>
                    </div>
                </div>
            </div>

        </div>
        {{Modal}}
        <div v-if="Modal==1">
            <div class="Popup">
                <div class="DialogTitle"><div class="DialogHead">erstellten Rechnungslauf freigeben</div></div>
                <div class="pa">

                    <div>Erstellte Daten Freigeben?</div>
                    <div>{{status.vorgangID}} vom {{status.jobStart}}</div>
                    <div>Erstellte Rechnungen : {{metas.Meta.length}}</div>
                    <div class="clear"></div>
                    <div class="PT20">
                        <button class="left" @click="StartRTM()">Rechnungslauf publizieren</button>
                        <button class="right" @click="Modal=0">Abbrechen</button>
                    </div>
                    <div class="clear"></div>
                    <div>
                        <div>&nbsp;<br /></div>
                        <div>Mit publizieren werden die erstellen Rechnung PDF in das Userverzeichnis verschoben.</div>
                        <div>Danach können die InformationseMails versendet werden.</div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

    import { getServerStatus, getRechnungen4Check, getRechnungMeta, publishRechnungen, publisheMails } from '@/moduls/invoice.js'
    import { Currency, CurrencyF2 } from '@/moduls/datum.js'

    import JobDataZeile from '@/components/invoice/rechnungen_jobdata.vue'

    export default {
        data() {
            return {
                working: false,
                state: 0,
                status: {},
                metas: {},
                blob: {},
                Modal: 0,
                FreigabeCode: null,
                VersendenInfo: null,
                JS: '',
                JS1: '',
                JS2: '',
            }
        },
        methods: {
            databaseEnv() {
                return `${process.env.VUE_APP_API_URL}`
            },
            async GetServerStatus() {
                this.status = await getServerStatus();
                this.metas = await getRechnungMeta(this.status.vorgangID);
                //console.log(JSON.stringify(this.status))

                if (this.status.freigabeCode != '') {
                    this.FreigabeCode = this.status.freigabeCode
                }
                if (this.FreigabeCode && this.status.canSend) {
                    this.VersendenInfo = "true";
                }
            },
            async GetServerBlob() {
                this.blob = await getRechnungen4Check(this.status.vorgangID);
                //console.log(JSON.stringify(this.blob));
                if (this.blob.status.error == '') {
                    this.getPDFPreview()
                    
                }
                this.JS = this.blob.status.error
            },
            async RefreshMetaData(selection) {
                this.metas = await getRechnungMeta(selection);
            },
            getCurrency(data) {
                return Currency(data)
            },
            getRechnungenGesamtSumme() {
                var GesamtSumme = 0
                if (this.metas.Meta) {
                    for (var i = 0; i < this.metas.Meta.length; i++) {
                        var rs = CurrencyF2(this.metas.Meta[i].DriveSummen.RechnungSumme);
                        GesamtSumme += rs*100; // (this.metas.Meta[i].DriveSummen.RechnungSumme);
                        //console.log(rs);
                    }
                }
                return GesamtSumme/100
            },
            async StartRTM() {
                // console.log('RTM');
                await publishRechnungen(this.status.freigabeCode);
                // console.log('RTM 1');
                //await this.GetServerStatus();
                //// console.log('RTM 2');
                
            },
            async StartSendMails() {
                var res = await publisheMails(this.status.freigabeCode)
                this.Modal = 0
                console.log(res)
            },
            openPreviewPdf() {
                window.open(this.databaseEnv() + 'api/Invoice/Job/MergePdf/' + this.status.vorgangID, '_blank')
            },
            async getPDFPreview() {
                //// console.log(this.databaseEnv() + 'api/Invoice/Job/MergePdf/' + this.status.vorgangID)

                window.open(this.databaseEnv() + 'api/Invoice/Job/MergePdf/' + this.status.vorgangID, '_blank')
                await this.GetServerStatus();
                //location.reload();
                //return this.databaseEnv() + 'api/Invoice/Job/MergePdf/' + this.status.vorgangID
                //return this.databaseEnv() + 'Job/MergePdf/' + this.status.vorgangID;
            },
            ActionStatusIcon(view) {
                var zuze = this.ActionStatus(view)
                // console.log(zuze)
                if (zuze == -1) return 'times-circle'
                if (zuze == 0) return 'exclamation-circle'
                if (zuze == 1) return 'edit'
                if (zuze == 2) return 'check-square'
                return 'ban';
            },
            IsAvailable(view) {
                if (!this.status || this.status.error) return false

                if (this.status.prozessState == 'Abgeschlossen') {
                    return view == 3
                } else {
                    if (view==3) return false
                }
                return true
            },
            ActionStatus(view) {
                // console.log(view)
                // console.log(JSON.stringify(this.status))
                if (!this.status) return -1
                if (this.status.error) { return -1 }

                if (view == 0) {
                    if (this.status.prozessState == 'RechnungenErstellt') { return 1 }
                    if (this.status.prozessState == 'RechnungenChecked') { return 2 }
                    if (this.status.prozessState == 'RechnungenRTM') { return 2 }
                }else if (view == 1) {
                    if (this.status.prozessState == 'RechnungenChecked') { return 1 }
                    if (this.status.prozessState == 'RechnungenRTM') { return 2 }
                } else if (view == 2) {
                    if (this.status.prozessState == 'RechnungenRTM') { return 1 }
                } else if (view == 3) {
                    return 0;
                } else if (view == 4) {
                    return 0;
                } else if (view == 5) {
                    return 0;
                }
                return 0
            }
        },
        components: {
            JobDataZeile
        },
        created() {
            this.GetServerStatus();
        }
    }

</script>

<style scoped>
    .Popup {
        font-size: 16px;
        border: solid 1px gray;
        padding: 2em;
        position: absolute;
        width: 700px;
        height: 450px;
        z-index: 15;
        top: 20%;
        left: 50%;
        margin: -100px 0 0 -150px;
        background: white;
        box-shadow: 10px 10px darkgray;
    }

    .DialogTitle {
        background-color: #008CBA;
        color: white;
        width: 100%;
        height: 5em;
    }

    .DialogHead {
        top: 30%;
        left: 25%;
        position: relative;
        font-size: 24px;
    }

    .errorBox {
        padding: 2em;
        text-align: center;
        color: darkred;
        font-weight: bold;
        border: solid 1px darkred;
        margin-top: 0.5em;
        background-color: burlywood;
    }

    .infoBox {
        padding: 2em;
        text-align: center;
        color: blue;
        font-weight: bold;
        border: solid 1px blue;
        margin-top: 0.5em;
        background-color: aliceblue;
    }

    .block {
        padding-top: 0.2em;
        padding-bottom: 0.2em;
        border-bottom: solid 1px gray;
    }

    .pa {
        padding: 5px;
        padding-top: 10px;
        padding-right: 40px;
        font-family: "Helvetica", arial, sans-serif;
        font-size: 18px;
    }

    .head {
        font-weight: bold;
        font-size: large;
    }

    .w10 {
        padding: 1.5em;
    }

    .PT20 {
        padding-top: 2em;
    }

    .pw10 {
        padding-left: 1.5em;
        padding-right: 1.5em;
        padding-top: 0.5em;
    }

    .disabled {
        visibility: hidden;
    }

    .enabled {
        visibility: visible;
    }

    /* Always display the drop down caret */
    input[type="date"]::-webkit-calendar-picker-indicator {
        color: #000000;
    }

    /* A few custom styles for date inputs */
    input[type="date"] {
        appearance: none;
        -webkit-appearance: none;
        color: #000000;
        font-family: "Helvetica", arial, sans-serif;
        font-size: 18px;
        border: 1px solid #ecf0f1;
        background: #ecf0f1;
        padding: 5px;
        display: inline-block !important;
        visibility: visible !important;
    }

    input[type="date"], focus {
        color: #95a5a6;
        box-shadow: none;
        -webkit-box-shadow: none;
        -moz-box-shadow: none;
    }

    .resizedTextbox2 {
        width: 80px;
        height: 32px;
        font-size: large;
        background: #ecf0f1;
    }

    .resizedTextbox1 {
        width: 160px;
        height: 32px;
        font-size: large;
        background: #ecf0f1;
    }
</style>