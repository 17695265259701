<template>
    <div>
        <div v-for="item in datapool" :key="item.id">
            <div class="left w20">{{item.bezeichnung}}</div>
            <div class="left w20">{{item.invoiceID}}</div>
            <div class="left w10">{{getDatum(item.rechnungsdatum)}}</div>
            <div class="right">{{formatPrice(item.details.rechnungsbetrag)}}</div>
            <div class="right w10 actionHand"><v-icon name="file-pdf"></v-icon></div>
            <div class="clear"></div>
            <!--<span class="col">{{item}}</span>-->
            <!--<span class="col">{{item}}</span>-->

        </div>
    </div>
</template>

<script>

    import { getInvoices } from '@/moduls/auth'
    import { ShowDatum } from '@/moduls/datum'

    export default {
        name: 'Rechnungen',
        props: {
            memberid:String,
        },
        components: {
            //Kundenliste,
        },
        data() {
            return {
                datapool: [],
                //warenkorb: {},
                working: false,
            }
        },
        methods: {
            async getRechnungen() {
                this.datapool = await getInvoices(this.memberid)
                //this.warenkorb = await getWarenkorb(this.memberid)
            },
            getDatum(data) {
                return ShowDatum(data);
            },
            formatPrice(value) {
                return value.toLocaleString('de-DE', { style: 'currency', currency: 'EUR' });
            },
        },
        created() {
            this.getRechnungen()
        }
    };
</script>

<style scoped>
    .col{
        padding: 5px;
    }
    .w10{
        width: 10%;
        padding-right:1em;
    }
    .w20 {
        width: 25%;
        padding-right: 1em;
    }
</style>