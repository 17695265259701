import Vue from 'vue';
import App from './App.vue';
import router from './router'
import moment from 'moment'
import wysiwyg from "vue-wysiwyg"
import 'vue-awesome/icons'
import Icon from 'vue-awesome/components/Icon'
import '@/assets/css/styles.css'
import "@/assets/css/vueWysiwyg.css";

Vue.config.productionTip = true;
//Vue.config.devtools = false
//Vue.config.debug = false
//Vue.config.silent = true


Vue.component('v-icon', Icon)
Vue.use(require('vue-moment'), moment);
Vue.use(wysiwyg, {});
Vue.prototype.moment = moment


new Vue({
    el: '#app',
    //store,
    router,
    components: { App },
    render: h => h(App)
})
