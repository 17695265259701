<template>
    <div >
        <div>
            <h1>Kundenliste {{filter}}</h1>
            <!--<div class="line"></div>-->
            <div>
                <memberPanel :data="DetailSelection" @refresh="reloadPanel"></memberPanel>
            </div>
            <div v-if="IsDriveSelektion()==true">
                <analysePanel :data="DetailSelection"></analysePanel>
            </div>
            <pages :dataPool="datapool" @changePage="onChangePage" :perPage="pPage"></pages>

            <div class="tableheadline">
                <div class="Col1" @click="SortChange(1)"><span v-if="showSortInfo(1)"><v-icon :name="SortInfoIcon()" class="p5"></v-icon></span>KID</div>
                <div class="Col3" @click="SortChange(2)"><span v-if="showSortInfo(2)"><v-icon :name="SortInfoIcon()" class="p5"></v-icon></span>Name</div>
                <div class="Col3" @click="SortChange(3)"><span v-if="showSortInfo(3)"><v-icon :name="SortInfoIcon()" class="p5"></v-icon></span>eMail/login</div>
                <div class="Col1" @click="SortChange(4)"><span v-if="showSortInfo(4)"><v-icon :name="SortInfoIcon()" class="p5"></v-icon></span>erstellt</div>
                <div class="Col2" @click="SortChange(5)"><span v-if="showSortInfo(5)"><v-icon :name="SortInfoIcon()" class="p5"></v-icon></span>AKey</div>
                <div class="Col1">Status</div>
                <div v-if="IsDriveSelektion()==true" class="Col1">Details</div>
                <div v-else class="Col2">Role</div>
            </div>
            <div class="clear"></div>
            <div class="line"></div>
            <div v-for="item in pageOfItems" v-bind:key="item.memberID" class="SmallListe">
                <div class="Col1 actionHand" @click="ShowDetails(item)">{{ColDrive(item)}}</div>
                <div class="Col3">{{ColData(item.anzeigename)}}</div>
                <div class="Col3">{{ColData(item.memberEmail)}} </div>
                <div class="Col1">{{Datum(item.memberCreated)}}   </div>
                <div class="Col2"> {{ColData(item.aKey)}}</div>
                <div class="Col1">{{item.memberProperties.status}}</div>
                <!--<div v-if="IsDriveSelektion()==true" class="Col1"><v-icon name="eye" scale="1.2"></v-icon></div>-->
                <div v-if="IsDriveSelektion()==true" class="Col1"></div>
                <div v-else class="Col2">{{item.memberProperties.authtype}}</div>
                <div class="clear"></div>
            </div>
        </div>
        

    </div>
</template>

<script>
    import { getMemberFilter, getMember } from '@/moduls/auth'
    import { ShowDatum } from '@/moduls/datum.js'

    import pages from '@/components/pagination.vue'
    import MemberPanel from '@/components/memberPanel.vue'
    import AnalysePanel from '@/components/analysePanel.vue'

    export default {
        name: 'KundenListe',
        props: {
            filter:String,
        },
        components: {
            pages,
            MemberPanel,
            AnalysePanel
            //Kundenliste,
        },
        data() {
            return {
                datapool: [],
                working: false,
                pageOfItems: [],
                pPage: 50,
                SortOrderStatus: 0,
                DetailSelection: {},
            }
        },
        methods: {
            async getKunden() {
                // console.log(this.filter)
                if (this.filter != '') {
                    if (this.filter === 'drive') {
                        this.datapool = await getMemberFilter('Drive', '', 0)

                    }

                    if (this.filter === 'Newmember') {
                        this.datapool = await getMemberFilter('Newmembers', '', 0)
                        // console.log('newm')
                    }
                } else {
                    this.datapool = await getMemberFilter('Sort', '', this.SortOrderStatus)
                }
            },
            SortChange(pos) {
                //console.log(pos + ' ' + this.SortOrderStatus)

                if (this.SortOrderStatus == pos || this.SortOrderStatus==0) {
                    this.SortOrderStatus = pos * -1
                } else if (this.SortOrderStatus * -1 == pos) {
                    this.SortOrderStatus = pos
                } else {
                    this.SortOrderStatus = pos * -1
                }
                this.getKunden()
            },
            showSortInfo(pos) {
                return (this.SortOrderStatus == pos || this.SortOrderStatus*-1 == pos)
            },
            SortInfoIcon() {
                return this.SortOrderStatus >= 0 ? 'sort-alpha-down' :'sort-alpha-up'
            },
            onChangePage(pageOfItems) {
                // update page of items
                this.pageOfItems = pageOfItems;
            },
            Datum(data) {
                return ShowDatum(data)
            },
            IsDriveSelektion() {
                let res = (this.filter === 'drive')
                // console.log('Drive '+res);
                return res
            },
            ColData(data) {
                return data && data.length>1 ? data: ' -.- '
            },
            ColDrive(data) {
                //console.log(JSON.stringify(data))
                let Kid = data.memberID
                let Drive = data.hasDrive ? " *" : ""
                return Kid && Kid.length > 1 ? Kid +Drive : ' -.- '
            },
            ShowDetails(item) {
                this.DetailSelection = item
                //console.log('details ' + JSON.stringify(item))
            },
            async reloadPanel(item) {
                this.DetailSelection = await getMember(item)
                //console.log('reload '+ item)
            }
        },
        created() {
            this.getKunden()
        },
        watch: {
            filter: function() {
                //console.log('kliste mounted ' + this.filter);
                this.getKunden()
            }
        }
    };
</script>

<style scoped>
    .Col1{
        width: 8%;
        float: left;
        min-width: 80px;
    }
    .Col2 {
        width: 13%;
        float: left;
        min-width: 150px;
    }
    .Col3 {
        width: 22%;
        float:left;
        min-width:220px;
    }

    .p5{
        padding-right:1em;
    }

    .line{
        height: 1em;
    }

    .tableheadline {
       font-size: inherit;
        background: red;
    }

    .space {
        padding: 1em;
        min-height: 1em;
    }
</style>