<template>
    <div>
        <h3>Archiv Rechnungsläufe</h3>
        <div class="workarea">
            Aktiver Rechnungslauf: <span v-if="processworking">[working]  <v-icon name="spinner" scale="2" spin></v-icon></span><span v-else>[idle]</span>
            <div>
                <div>Vorgang: {{pState.vorgangID}}</div>
                <div>Job : {{pState.prozessState}}</div>
                <div>start: {{Datum(pState.jobStart)}}</div>
            </div>
        </div>
        <div>
            <h3>Liste</h3>
            <div v-for="spitem in pitem" v-bind:key="spitem.vorgangID">
                <!--{{spitem}}-->
                <ArchivItem :item="spitem"></ArchivItem>
            </div>
        </div>
        <div>
            <h3 class="actionHand"  @click="reloadTree()">Core PDF</h3>
            <div v-for="ppitem in ppdf" v-bind:key="ppitem.memberID">
                <ArchivPdfItem :ppitem="ppitem" class="IconBox" @ShowDetails="ShowDetails" ></ArchivPdfItem>
                <!--<v-icon name="folder"></v-icon>{{ppitem.memberID}} {{ppitem.filename.length}}-->
            </div>
            <div class="clear"></div>

        </div>
        <div>
            <div v-if="UserDetailArchiv" class="editBox">
                <div>
                    <div class="col02"><b>{{UserDetailArchiv.memberID}}</b></div>
                    <button title="PDF Rechnung in Kundencontainer ablegen" v-if="canAddPdf(UserDetailArchiv)" class="colr" @click="AddNewPdf()">Hinzufügen</button>
                    <div class="clear"></div>
                </div>
                <div>
                    <div class="col02">Rechnungsname</div>
                    <div class="col02">RechDatum (Sort, then lastWrote))</div>
                    <div class="col01">Summe</div>
                    <!--<div class="colr" @click="AddNewPdf()">Hinzufügen</div>-->
                    <div class="clear"></div>
                </div>
                <div v-for="(item,inx) in UserDetailArchiv.filename" :key="inx">
                    <div class="boxItem">
                        <div class="col02">{{item.filename}}</div>
                        <div class="col02">{{Datum(item.rechDatum)}}</div>
                        <div class="col01">{{ToFixedNumber(item.rechSumme)}}</div>
                        <div class="colr actionHand" @click="ShowPDF(UserDetailArchiv.memberID,item.filename)" title="Ansicht"><v-icon name="eye" scale="1.8"></v-icon></div>
                        <div class="clear"></div>
                    </div>
                    
                </div>
            </div>
        </div>
        <div v-if="Modal==1">
            <div class="Popup">
                <div class="DialogTitle"><div class="DialogHead">Rechnung für KID {{UserDetailArchiv.memberID}} hinzufügen </div></div>
                <div class="pa">
                    <div class="PT20">
                        <div class="row"><div><br /></div></div>

                        <div>
                            Rechnungs Summe: <input type="text" v-model="uploadSumme" />
                        </div>
                        <div class="row"><div><br /></div></div>
                        <div><v-icon name="file-pdf" scale="1.8"></v-icon></div>
                        <div>
                            <input type="file" @change="uploadFile" ref="file">
                            <br />
                        </div>
                        <div class="row"><div><br /></div></div>
                        <div>

                            <button v-if="canUpload" @click="submitFile">Upload!</button>
                            <button class="right" @click="exitPopup()">Abbrechen</button>
                        </div>
                        <div class="row"><div><br /></div></div>
                        <div v-if="webError">
                            <div class="warnBox">
                               : {{uploadError}}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>

    import axios from 'axios'
    import { authFileHeader, getLoginUserName} from '@/moduls/auth.js'
    import { ShowDateTime } from '@/moduls/datum.js'
    import ArchivItem from '@/components/invoice/archivItem.vue'
    import ArchivPdfItem from '@/components/invoice/PdfTreeItem.vue'
    import { getRechnungsArchiv, getRechnungsPDFArchiv, GetInvoiceStatus } from '@/moduls/invoice.js'


    export default {
        name: 'Archiv',
        props: {
        },
        data() {
            return {
                working: false,
                processworking: false,
                ppdf:[],
                pitem: [],
                pState: {},
                UserDetailArchiv: {},
                Modal: 0,
                Images: null,
                uploadResult: false,
                uploadError: null,
                uploadSumme: 0,
                canUpload: false,
                webError:false,
            }
        },
        methods: {
            databaseEnv() {
                return `${process.env.VUE_APP_API_URL}`
            },
            warnBox() {
                return this.uploadError || this.uploadError !='' ? 'warnBox' : ''
            },
            uploadFile() {
                this.Images = this.$refs.file.files[0];
                this.canUpload = true
            },
            exitPopup() {
                this.Modal = 0
                this.canUpload = false
                this.Images = null
                this.webError=false
            },
            ToFixedNumber(data) {
                return data.toFixed(2);
            },
            canAddPdf(data) {
                return data && data.memberID 
            },
            async submitFile() {
                const formData = new FormData();
                formData.append('file', this.Images);
                formData.append('memberID', this.UserDetailArchiv.memberID);
                formData.append('uploader', getLoginUserName());
                formData.append('rechsumme', this.uploadSumme);
                const headers = authFileHeader() //  { 'Content-Type': 'multipart/form-data' };
                this.uploadResult = false
                this.uploadError =null
                await axios.post(this.databaseEnv()+'api/invoice/rechnungupload/post', formData, { headers }).then((res) => {
                    res.data.files; // binary representation of the file
                    res.status; // HTTP status

                    console.log(res.data)
                    this.uploadResult = res.data > 0
                    this.uploadError = res.data <1 ? 'Datei wurde nicht hochgeladen': null
                });
                if (this.uploadResult) {
                    this.Modal = 0;
                    await this.reloadTree()
                } else {
                    this.webError = true
                }
                console.log(this.uploadError)
            },
            ShowDetails(dataset) {
                this.UserDetailArchiv = dataset
                console.log(JSON.stringify(dataset))
            },
            Ok() {
                return this.pitem.status==1 ? true:false
            },
            Versendet() {
                return this.pitem.versendet ? 'ok': 'invisible'
            },
            Gespeichert() {
                return this.pitem.gespeichert? 'ok': 'invisible'
            },
            async GetArchivData() {
                this.pitem = await getRechnungsArchiv()
                await this.GetStatus()
                // console.log(JSON.stringify(this.pitem))
            },
            async GetPDFArchiv() {
                this.ppdf = await getRechnungsPDFArchiv()
            },
            async reloadTree() {
                await this.GetPDFArchiv()
            },
            Datum(item) {
                return ShowDateTime(item)
            },
            async GetStatus() {
                this.pState = await GetInvoiceStatus()
                if (this.pState.isWorking) {
                    this.processworking = true
                }
            },
            async ShowPDF(member,invoiceID) {
                console.log(invoiceID)
                window.open(this.databaseEnv() + 'api/Invoice/Job/ArchivPdf/'+ member +'/' + invoiceID, '_blank')

            },
            AddNewPdf() {
                this.Modal = 1
            }
        },
        components: {
            ArchivItem,
            ArchivPdfItem
        },
        created() {
            this.GetArchivData()
        }
    };
</script>
<style scoped>
    .col{
        float:left;
    }
    .colr{
        float:right;
    }
    .workarea{
        padding:1em;
        border-left:solid 1px gray;
        border-bottom:solid 1px gray;
    }
    .w20{
        width:20%;
    }
    .p1 {
        padding-left:0.5em;
        padding-right:0.5em;
    }
    .red{
        color:darkred;
    }
    .ok{
        color:darkgreen;
    }
    .invisible{
        color: white;
        background-color: white;
    }
    .editBox{
        border: solid 1px darkgray;
        padding: 0.5em;
    }
    .boxItem{
        background: whitesmoke;
    }
    .IconBox{
        float: left;
        padding-right: 0.4em;
        padding-left:0.4em;
    }
    .Popup {
        font-size: 16px;
        border: solid 1px gray;
        padding: 2em;
        position: absolute;
        width: 500px;
        height: 450px;
        z-index: 15;
        top: 20%;
        left: 50%;
        margin: -100px 0 0 -150px;
        background: white;
        box-shadow: 10px 10px darkgray;
    }
    .DialogTitle {
        background-color: #008CBA;
        color: white;
        width: 100%;
        height: 5em;
    }
    .DialogHead {
        top: 30%;
        left: 15%;
        position: relative;
        font-size: 24px;
    }
    .errorBox {
        padding: 2em;
        text-align: center;
        color: darkred;
        font-weight: bold;
        border: solid 1px darkred;
        margin-top: 0.5em;
        background-color: burlywood;
    }
    .infoBox {
        padding: 2em;
        text-align: center;
        color: blue;
        font-weight: bold;
        border: solid 1px blue;
        margin-top: 0.5em;
        background-color: aliceblue;
    }
    .block {
        padding-top: 0.2em;
        padding-bottom: 0.2em;
        border-bottom: solid 1px gray;
    }
    .pa {
        padding: 5px;
        padding-top: 10px;
        padding-right: 40px;
        font-family: "Helvetica", arial, sans-serif;
        font-size: 18px;
    }
    .head {
        font-weight: bold;
        font-size: large;
    }
</style>
