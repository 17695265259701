<template>
    <div>
        <div id="nav">
            <!--<button @click="selection=2">Aktivierungskey zurücksetzen</button>-->
            <button @click="selection=1">Tool Liste</button>
            <button @click="selection=2">Mail Templates</button>
            <button @click="selection=3">Testkunden</button>
            <button @click="selection=4">Test</button>
            <button @click="selection=6">KaufMani</button>
            <button @click="selection=5">Captcha</button>
            <!--<button @click="selection=2">Image Liste</button>
    <button @click="selection=3">Image Upload</button>-->
        </div>
        <div class="clear"></div>
        <div class="content">
            <tliste v-if="selection==1"></tliste>
            <!--<sliste v-if="selection==2"></sliste>
    <uliste v-if="selection==3"></uliste>-->
            <Mailverwaltung v-if="selection==2"></Mailverwaltung>

            <TestKunden v-if="selection==3"></TestKunden>

            <KaufManiKunden v-if="selection==6"></KaufManiKunden>

            <div v-if="selection==4">
                <div class="Pin" type="password" @click="switchVisibility"><v-icon name="eye-slash"></v-icon></div>
                <input :type="passwordFieldType" v-model="password" placeholder="<Password>" autocomplete="new-password">

                <div class="clear"></div>
                <div @click="doHash">Show HashCode</div>
                <div>{{HashCode}}</div>
            </div>

            <div v-if="selection==5">
                <Captcha></Captcha>
                <div>&nbsp;</div>
                <div>&nbsp;</div>
                <div @click="doReset">Reset</div>
                <input type="text" v-model="captchaUser" placeholder="<eMail>" autocomplete="nope">
                <input type="text" v-model="captcha" placeholder="<Code>" autocomplete="nope">
            </div>
        </div>
        <div class="clear"></div>
    </div>
</template>

<script>
    //import { isAdminUser, isPowerUser } from '@/moduls/auth'
    //import Produktliste from '@/components/produktliste.vue'
    import tliste from '@/components/toolliste.vue'
    import Mailverwaltung from '@/components/mailverwaltung.vue'
    import TestKunden from '@/components/TestKunden.vue'

    import KaufManiKunden from '@/components/KaufManiKunden.vue'

    import Captcha from '@/components/captchaPanel.vue'
    import { hashPass } from '@/moduls/auth.js'


    //import sliste from '@/components/stuffListe.vue'
    //import uliste from '@/components/stuffUpload.vue'

    export default {
        name: 'WWW',
        props: {
            //suche: {}
        },
        components: {
            tliste,
            Mailverwaltung,
            TestKunden,
            Captcha,
            KaufManiKunden,
            //sliste,
            //uliste,
            //Produktliste,
            //Backendverwaltung,
        },
        data() {
            return {
                selection: 0,
                working: false,
                code: '',
                captchaUser: '',
                captcha: '',
                HashCode: '',
                password: '',
                passwordFieldType: 'password'
            }
        },
        mounted() {
            //console.log('mount '+isAdminUser())
            //console.log('mount ' + JSON.stringify(this.$route.params) + ' ' + this.suche)
        },
        methods: {
            async doHash() {
                console.log(this.HashCode)
                this.HashCode = await hashPass(this.password);
            },

            async doReset() {
                console.log(this.captchaUser)
                console.log(this.captcha)
                if (this.captchaUser && this.captchaUser.length > 5 && this.captcha && this.captcha.length > 3) {
                    //await ResetPassword0(this.captchaUser, this.captcha)
                    console.log('OK')
                }
            },
            switchVisibility() {
                this.passwordFieldType = this.passwordFieldType === 'password' ? 'text' : 'password'
            }
            //isAdminUser() {
            //    return isAdminUser()
            //    //return getUserInfo()?.role?.includes('Admin')
            //},
            //isPowerUser() {
            //    return isPowerUser();
            //    //return getUserInfo()?.role?.includes('Poweruser')
            //},
        }
    };
   // /api/Apps/0000 für WebSite{ gruppe }
</script>

<style scoped>
    .leftnav {
        float: left;
        width: 300px;
    }

    .contentbox {
        float: left;
        width: calc( 100vh - 200px);
    }

    .content {
        padding: 20px;
    }

    .Pin{
        float:left;
        width: 1em;
    }

    button {
        background-color: #003a5c;
        border: none;
        color: white;
        padding: 0.8em;
        text-align: center;
        text-decoration: none;
        display: inline-block;
        font-size: 0.8em;
        cursor: pointer;
        width: 200px;
        float: left;
    }
</style>