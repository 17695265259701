<template>
    <div>
        <div class="fully">
            <div v-if="newItem">
                <div >
                    <h1>Neues Mail Template</h1>
                    <div><span class="colLabel">TID</span><span><input type="text" v-model="newTemplateItem.tid" /></span></div>
                    <div><span class="colLabel">Info</span><span><input type="text" v-model="newTemplateItem.templateInfo" /></span></div>
                    <div><span class="colLabel">Subject</span><span><input type="text" v-model="newTemplateItem.subject" /></span></div>
                    <div><span class="colLabel">Parse</span><span><input type="text" v-model="newTemplateItem.parseData" /></span></div>
                    <wysiwyg v-model="newTemplateItem.body" />
                    <span @click="saveTemplate" class="colLabel"><v-icon name="save" scale="2" title="speichern"></v-icon></span>
                    <span @click="newItem=null"><v-icon name="window-close" scale="2" title="abbrechen"></v-icon></span>
                </div>
            </div>
            <div v-else>
                <h1>Mail Templates <span @click="newTemplate"><v-icon name="certificate" scale="1.2" title="Neues Template"></v-icon></span></h1>
                <div>
                    <div class="head">
                        <div class="col1 head w100">TID</div>
                        <div class="col1 head w200">Info</div>
                        <div class="col1 head w200">Subject</div>
                        <div class="col1 head"></div>
                        <div class="colE3 head">Parse</div>
                        <div class="clear"></div>
                    </div>

                    <div class="fully" v-for="item in datapool" v-bind:key="item.uid">

                        <div v-if="isExpand(item)" class="fully1Context">
                            <div class="head">
                                <div class="col1">{{item.tid}}</div>
                                <div class="col3"><input type="text" v-model="item.templateInfo" /></div>
                                <div class="col4"><input type="text" v-model="item.subject" /></div>
                                <div class="colE1" @click="saveHtml(item)"><v-icon name="save"></v-icon></div>
                                <div class="colE3"><input type="text" v-model="item.parseData" /></div>
                                <div class="clear"></div>
                            </div>
                            <wysiwyg v-model="item.body" />
                            <!--<div ><v-icon name="save" scale="2"></v-icon></div>-->
                        </div>
                        <div v-else>
                            <div>
                                <div class="col1 w100">{{item.tid}}</div>
                                <div class="col1 w200">{{item.templateInfo}}</div>
                                <div class="col1 w200">{{item.subject}}</div>
                                <div class="colE1" @click="doExpand(item)"><v-icon name="angle-down"></v-icon></div>
                                <div class="colE3">{{item.parseData}}</div>
                                <div class="clear"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

    import { getMailTemplates, submitMailTemplate } from '@/moduls/auth'

    export default {
        name: 'Mailverwaltung',
        props: {

        },
        components: {
            //Kundenliste,
        },
        data() {
            return {
                datapool: [],
                working: false,
                selected: {},
                expandItem: '',
                newItem: null,
                newTemplateItem: {
                    body: '',
                    tid: 'Temp',
                    templateInfo: '',
                    parseData: '',
                    subject:'',
                    isHtmlBody: true,
                    parse: true,
                }
            }
        },
        methods: {
            async getTemplates() {
                this.datapool = await getMailTemplates()
            },
            onChange(event) {
                console.log(event.target.value)
                this.$emit('selected', this.selected.tid)
            },
            isExpand(item) {
                //console.log(item)
                return this.expandItem==item.tid
            },
            doExpand(item) {
                this.expandItem = item.tid
            },
            async saveHtml(data) {

                let res = await submitMailTemplate(data)
                if (res) {
                    this.expandItem = ''
                }
                console.log('update: ' + res?.tid)
            },
            newTemplate() {
                console.log('Trigger')
                this.newItem='create'
            },
            async saveTemplate() {
                if (confirm('Template wird angelegt')) {
                    await this.saveHtml(this.newTemplateItem)
                    await this.getTemplates()
                    this.newItem = null
                }
            }
        },
        created() {
            this.getTemplates()
        },

    };
</script>

<style scoped>
    .col1 {
        float: left;
    }

    .col2 {
        float: left;
        min-width: 100px;
        cursor: pointer;
    }
    .col3 {
        float: left;
        min-width: 200px;
        cursor: pointer;
    }
    .col4 {
        float: left;
        min-width: 300px;
        cursor: pointer;
    }

    .colE{
        float: right;
    }
    .colE1 {
        float: right;
        padding-left:8px;
    }
    .colE2 {
        float: right;
        min-width: 50px;
    }
    .colE3 {
        float: right;
        min-width: 100px;
        overflow: hidden;
    }

    .colLabel{
        float: left;
        width: 150px !important;
    }

    .w100{
        min-width: 100px;
    }

    .w200 {
        min-width: 200px;
    }

    .lab {
        padding-right: 5px;
    }

    .role {
        padding-right: 5px;
        font-weight: bold;
    }

    .fully {
        /*width: 100%;*/
        background: lightred;
    }

    .fully1 {
        width: 1000px;
        /*background: #ff6a00;*/
    }

    .fully1Context {
        width: 1000px;
        border-top: solid 1px black;
        border-bottom: solid 1px black;
    }
    .head{
        padding-top: 4px;
        padding-bottom: 4px;
        background: #eeeeee;
    }
</style>