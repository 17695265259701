//import decode from 'jwt-decode'
import axios from 'axios'
import * as auth from '@/moduls/auth'

//const AUTH_TOKEN_KEY = 'authToken'



//

// /




export function getRechnungsPDFArchiv() {
    return new Promise(async (resolve, reject) => {
        try {
            let res = await axios({
                //url: `${process.env.VUE_APP_API_URL}api/Kunde/`,
                url: `${process.env.VUE_APP_API_URL}api/Invoice/Archiv/Invoices/Tree`,
                method: 'GET',
                headers: auth.authHeader(),
            })
            //console.log(res.data)
            resolve(res.data)
        } catch (err) {
            reject(err)
        }
    })
}

export function getRechnungsArchiv() {
    return new Promise(async (resolve, reject) => {
        try {
            let res = await axios({
                //url: `${process.env.VUE_APP_API_URL}api/Kunde/`,
                url: `${process.env.VUE_APP_API_URL}api/Invoice/Archiv/Invoices`,
                method: 'GET',
                headers: auth.authHeader(),
            })
            //console.log(res.data)
            resolve(res.data)
        } catch (err) {
            reject(err)
        }
    })
}


export function geteMailArchiv() {
    return new Promise(async (resolve, reject) => {
        try {
            let res = await axios({
                //url: `${process.env.VUE_APP_API_URL}api/Kunde/`,
                url: `${process.env.VUE_APP_API_URL}api/Invoice/Mailversand/Invoices`,
                method: 'GET',
                headers: auth.authHeader(),
            })
            //console.log(res.data)
            resolve(res.data)
        } catch (err) {
            reject(err)
        }
    })
}

export function getRechnungen4Check(jobID) {
    return new Promise(async (resolve, reject) => {
        try {
            let res = await axios({
                //url: `${process.env.VUE_APP_API_URL}api/Kunde/`,
                url: `${process.env.VUE_APP_API_URL}api/Invoice/Job/Confirm/` + jobID,
                method: 'GET',
                headers: auth.authHeader(),
            })
            //console.log(res.data)
            resolve(res.data)
        } catch (err) {
            reject(err)
        }
    })
}


export function publishRechnungen(jobID) {
    return new Promise(async (resolve, reject) => {
        try {
            let res = await axios({
                //url: `${process.env.VUE_APP_API_URL}api/Kunde/`,
                url: `${process.env.VUE_APP_API_URL}api/Invoice/Job/Confirm/` + jobID,
                method: 'PUT',
                headers: auth.authHeader(),
            })
            //console.log(res.data)
            resolve(res.data)
        } catch (err) {
            reject(err)
        }
    })
}

export function publisheMails(jobID) {
    return new Promise(async (resolve, reject) => {
        try {
            let res = await axios({
                //url: `${process.env.VUE_APP_API_URL}api/Kunde/`,
                url: `${process.env.VUE_APP_API_URL}api/Invoice/Job/SendMail/` + jobID,
                method: 'PUT',
                headers: auth.authHeader(),
            })
            //console.log(res.data)
            resolve(res.data)
        } catch (err) {
            reject(err)
        }
    })
}



export function getRechnungMeta(job) {
    return new Promise(async (resolve, reject) => {
        try {
            let res = await axios({
                //url: `${process.env.VUE_APP_API_URL}api/Kunde/`,
                url: `${process.env.VUE_APP_API_URL}api/Invoice/Job/Meta/`+job,
                method: 'GET',
                headers: auth.authHeader(),
            })
            //console.log(res.data)
            resolve(res.data)
        } catch (err) {
            reject(err)
        }
    })
}

export function getServerStatus() {
    return new Promise(async (resolve, reject) => {
        try {
            let res = await axios({
                //url: `${process.env.VUE_APP_API_URL}api/Kunde/`,
                url: `${process.env.VUE_APP_API_URL}api/Invoice/Job`,
                method: 'GET',
                headers: auth.authHeader(),
            })
            //console.log(res.data)
            resolve(res.data)
        } catch (err) {
            reject(err)
        }
    })
}


export function SetInvoiceProcess(vorgang,status) {
    return new Promise(async (resolve, reject) => {
        try {
            var JobAction = 'Idle'
            if (status == 1) {
                JobAction = 'Rechnungen'
            } else if (status == 2) {
                JobAction='MailVersand'
            }
            let res = await axios({
                url: `${process.env.VUE_APP_API_URL}api/Invoice/BackendJob/` + JobAction + '/' + vorgang + '/',
                method: 'POST',
                headers: auth.authHeader(),
            })
            resolve(res.data)
        } catch (err) {
            reject(err)
        }
    })
}

export function SetInvoiceStatus(vorgang) {
    return new Promise(async (resolve, reject) => {
        try {
            let res = await axios({
                url: `${process.env.VUE_APP_API_URL}api/Invoice/Status/`+ vorgang,
                method: 'PUT',
                headers: auth.authHeader(),
            })
            resolve(res.data)
        } catch (err) {
            reject(err)
        }
    })
}

export function KillRechnungsLauf() {
    return new Promise(async (resolve, reject) => {
        try {
            let res = await axios({
                //url: `${process.env.VUE_APP_API_URL}api/Kunde/`,
                url: `${process.env.VUE_APP_API_URL}api/Invoice/Job`,
                method: 'DELETE',
                headers: auth.authHeader(),
            })
            //console.log(res.data)
            resolve(res.data)
        } catch (err) {
            reject(err)
        }
    })
}


export function TriggerStart(vorgangID, erstellt, sepa, eMail, job, RechDatum) {
    return new Promise(async (resolve, reject) => {
        try {
            let res = await axios({
                url: `${process.env.VUE_APP_API_URL}api/Invoice/Job/Create/`,
                method: 'PUT',
                headers: auth.authHeader(),
                data: {
                    "vorgangID": vorgangID,
                    "rechnungDatum": erstellt,
                    "sepaDatum": sepa,
                    "prozessState": job,
                    "rechnungsMonat": RechDatum,
                    "notificationMail": eMail
                }
            })
            // console.log(res);
            resolve(res.data)
        } catch (err) {

            console.log(err);
            reject(err)
        }
    })
}

export function GetInvoiceStatus() {

    return new Promise(async (resolve, reject) => {
        try {
            let res = await axios({
                //url: `${process.env.VUE_APP_API_URL}api/Kunde/`,
                url: `${process.env.VUE_APP_API_URL}api/Invoice/Status`,
                method: 'GET',
                headers: auth.authHeader(),
            })
            //console.log(res.data)
            resolve(res.data)
        } catch (err) {
            reject(err)
        }
    })
}

export function KillKauf(member, PID, datum) {
    return new Promise(async (resolve) => {
        try {
            // console.log('Rabatt:'+rabattwert)
            let res = await axios({
                //url: `${process.env.VUE_APP_API_URL}api/Kunde/`,
                url: `${process.env.VUE_APP_API_URL}api/Invoice/Kunde/KillProdukt`,
                method: 'DELETE',
                headers: auth.authHeader(),
                data: {
                    memberID: member,
                    PID: PID,
                    zum: datum,
                    Remove: true
                },
            })
            // console.log(res.data)
            resolve(res.data)
        } catch (err) {
            resolve("FAILS")
            //reject(err)
        }
    })
}



export function getPreviewPdf64Invoice(RID) {
    return new Promise(async (resolve, reject) => {
        try {
            let res = await axios({
                //url: `${process.env.VUE_APP_API_URL}api/Kunde/`,
                url: `${process.env.VUE_APP_API_URL}api/Invoice/Archiv/PDFBase64/` + RID,
                method: 'GET',
                headers: auth.authHeader(),
            })
            //console.log(res.data)
            resolve(res.data)
        } catch (err) {
            reject(err)
        }
    })
}

//Archiv/PDFBase64/{Rechnungsnummer}

export function getPreviewPdfInvoice(memberID) {
    return new Promise(async (resolve, reject) => {
        try {
            let res = await axios({
                //url: `${process.env.VUE_APP_API_URL}api/Kunde/`,
                url: `${process.env.VUE_APP_API_URL}api/Invoice/Kunde/Preview/` + memberID,
                method: 'GET',
                headers: auth.authHeader(),
            })
            //console.log(res.data)
            resolve(res.data)
        } catch (err) {
            reject(err)
        }
    })
}

export function updatePIDFinanz(memberid, pid, finanz) {
    // console.log(memberid + ' '+ pid + ' ' + JSON.stringify(finanz))
    return new Promise(async (resolve, reject) => {
        try {
            let res = await axios({
                //url: `${process.env.VUE_APP_API_URL}api/Kunde/`,
                url: `${process.env.VUE_APP_API_URL}api/Invoice/Kunde/Tool/Finanz`,
                method: 'POST',
                headers: auth.authHeader(),
                data: {
                    memberID: memberid,
                    pid: pid,
                    sonderRabatt: parseFloat(finanz.sonderRabatt),
                    freieMonate: parseInt(finanz.freieMonate),
                    buchungsPreis: parseFloat(finanz.buchungsPreis),
                    nichtBerechnen: finanz.nichtBerechnen,
                    nichtVerwenden: finanz.nichtVerwenden
                },
            })
            // console.log(res.data)
            resolve(res.data)
        } catch (err) {
            reject(err)
        }
    })
}

export function setRabatt(member, rabattwert) {
    return new Promise(async (resolve, reject) => {
        try {
            // console.log('Rabatt:'+rabattwert)
            let res = await axios({
                //url: `${process.env.VUE_APP_API_URL}api/Kunde/`,
                url: `${process.env.VUE_APP_API_URL}api/Invoice/Kunde/Rabatt`,
                method: 'POST',
                headers: auth.authHeader(),
                data: {
                    memberID: member,
                    rabatt: rabattwert
                },
            })
            // console.log(res.data)
            resolve(res.data)
        } catch (err) {
            reject(err)
        }
    })
}


export function setDauerInvoice(member, status) {
    return new Promise(async (resolve, reject) => {
        try {
            let res = await axios({
                //url: `${process.env.VUE_APP_API_URL}api/Kunde/`,
                url: `${process.env.VUE_APP_API_URL}api/Invoice/Kunde/Setting`,
                method: 'POST',
                headers: auth.authHeader(),
                data: {
                    memberID: member,
                    dauerInvoice: status
                },
            })
            // console.log(res.data)
            resolve(res.data)
        } catch (err) {
            reject(err)
        }
    })
}

export function setVerwenden(member, status) {
    return new Promise(async (resolve, reject) => {
        try {
            let res = await axios({
                //url: `${process.env.VUE_APP_API_URL}api/Kunde/`,
                url: `${process.env.VUE_APP_API_URL}api/Invoice/Kunde/Setting`,
                method: 'POST',
                headers: auth.authHeader(),
                data: {
                    memberID: member,
                    verwenden: status==-1
                },
            })
            //console.log(res.data)
            resolve(res.data)
        } catch (err) {
            reject(err)
        }
    })
}

export function removeKundenFromWhitelist(item) {
    return new Promise(async (resolve, reject) => {
        try {
            let res = await axios({
                //url: `${process.env.VUE_APP_API_URL}api/Kunde/`,
                url: `${process.env.VUE_APP_API_URL}api/Invoice/WhiteList/`+item,
                method: 'DELETE',
                headers: auth.authHeader(),
            })
            // console.log(res.data)
            resolve(res.data)
        } catch (err) {
            reject(err)
        }
    })
}

export function getWhiteliste() {
    return new Promise(async (resolve, reject) => {
        try {
            let res = await axios({
                //url: `${process.env.VUE_APP_API_URL}api/Kunde/`,
                url: `${process.env.VUE_APP_API_URL}api/Invoice/WhiteList`,
                method: 'GET',
                headers: auth.authHeader(),
            })
            // console.log(res.data)
            resolve(res.data)
        } catch (err) {
            reject(err)
        }
    })
}

export function getKundenliste() {
    return new Promise(async (resolve, reject) => {
        try {
            let res = await axios({
                //url: `${process.env.VUE_APP_API_URL}api/Kunde/`,
                url: `${process.env.VUE_APP_API_URL}api/Invoice/Kunden` ,
                method: 'GET',
                headers: auth.authHeader(),
            })
            //console.log(res.data)
            resolve(res.data)
        } catch (err) {
            reject(err)
        }
    })
}

export function setKunden2Whitelist(item) {
    return new Promise(async (resolve, reject) => {
        try {
            let res = await axios({
                //url: `${process.env.VUE_APP_API_URL}api/Kunde/`,
                url: `${process.env.VUE_APP_API_URL}api/Invoice/Whitelist`,
                method: 'PUT',
                headers: auth.authHeader(),
                data: {
                    memberID: item
                },
            })
            // console.log(res.data)
            resolve(res.data)
        } catch (err) {
            reject(err)
        }
    })
}