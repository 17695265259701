import Vue from 'vue'
import Router from 'vue-router'
import Home from '@/views/home.vue'
import Kunden from '@/views/kunden.vue'
import Updates from '@/views/updates.vue'
import WWW from '@/views/www.vue'
import About from '@/views/about.vue'
import Suchergebnis  from '@/views/suchergebnis.vue'
import Login from '@/views/login.vue'
import Logout from '@/views/logout.vue'
import Rechnung from '@/views/rechnung.vue'
import Invoices from '@/views/invoice.vue'
import { isLoggedIn } from '@/moduls/auth'

Vue.use(Router)

const router = new Router({
    mode: 'history',
    base: process.env.BASE_URL,
    routes: [
        {
            path: '/',
            name: 'home',
            component: Home
        },
        {
            path: '/login',
            name: 'login',
            component: Login,
            meta: {
                allowAnonymous: true
            }
        },
        {
            path: '/service',
            name: 'service',
            // route level code-splitting
            // this generates a separate chunk (about.[hash].js) for this route
            // which is lazy-loaded when the route is visited.
            component: () => import(/* webpackChunkName: "about" */ '@/views/service.vue')
        },
        {
            path: '/kunden',
            name: 'kunden',
            component: Kunden
        },
        {
            path: '/invoice',
            name: 'Invoices',
            component: Invoices
        },
        {
            path: '/suche',
            name: 'suche',
            component: Suchergebnis
        },
        {
            path: '/about',
            name: 'about',
            component: About
        },
        {
            path: '/updates',
            name: 'updates',
            component: Updates
        },
        {
            path: '/webmin',
            name: 'www',
            component: WWW
        },
        {
            path: '/logout',
            name: 'logout',
            component: Logout
        },

        {
            path: '/rechnung/:id',
            name: 'rechnung',
            component: Rechnung,
            props: true
        }

    ]
})

router.beforeEach((to, from, next) => {
    if (to.name == 'login' && isLoggedIn()) {
        next({ path: '/' })
    }
    else if (!to.meta.allowAnonymous && !isLoggedIn()) {
        next({
            path: '/login',
            query: { redirect: to.fullPath }
        })
    }
    else {
        next()
    }
})

export default router
