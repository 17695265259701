<template>
    <div>
        <div class="mt5 mbox">
            <div class="Headline">Tool Liste nach Gruppen</div>
            <div>
                <gruppenwahl @GruppeSelection="GruppeChanged"></gruppenwahl>
            </div>
            <div class="Action"><changeOrder :Gruppe="selectedGruppe" @Reload="getProdukte"></changeOrder></div>
        </div>
        <div class="mt5">&nbsp;</div>
        <div v-for="item in datapool" v-bind:key="item.uid">
            <span class="c1b">{{item.name}}</span>
            <span class="c1">[{{item.pid}}]</span>
            <span class="cr"><img :src="getLogo(item.logo)" /></span>
            <hr />
            <div><span class="c1b">Tagline</span><span>{{item.tagline}}</span></div>
            <div><span class="c1b">Label</span><span>{{item.label}}</span></div>
            <div><span class="c1b">Hinweis</span><span>{{item.hinweis}}</span></div>
            <div><span class="c1b">Description</span>{{item.description}}</div>
            <div><span class="c1b">Teaser</span>{{item.teaser}}</div>
            <div><span class="c1b">Requirements</span>{{item.requirements}}</div>
            <div><span class="c1b">FullText</span>{{item.fullText}}</div>
            <div><span class="c1b">FeatureList</span>{{item.featureList}}</div>
            <div class="right" @click="DoExpand(item)"><v-icon name="edit" scale="1.5"></v-icon></div>
            <div v-if="isExpand(item)">
                <hr />
                <div class="c0">
                    <span class="c1"><span class="c1b">Preis:</span>{{formatPrice(item.preis)}}</span>
                    <span v-if="HasTest(item)" class="c1">Testversion</span>
                    <span v-if="HasKauf(item)" class="c1">Kaufbar</span>
                </div>
                <div class="c0">
                    <div class="c0"><span class="c1b">Testlink:</span> {{item.link_testversion}}</div>
                    <div class="c0"><span class="c1b">Bestellen:</span> {{item.link_bestellen}}</div>
                    <div class="c0"><span class="c1b">Rabattierbar</span>: {{item.rabattierbar}}</div>
                </div>
            </div>

            <div v-if="isExpand(item)">
                <toolview :DataItem="item" @hide="CloseExpand"></toolview>
            </div>
            <hr class="class-1" />
        </div>
        
    </div>
</template>

<script>
    import { getAllTools } from '@/moduls/auth'
    import gruppenwahl from '@/components/gruppenauswahl.vue'
    import toolview from '@/components/tooleditview.vue'
    import changeOrder from '@/components/changeOrderTools.vue'

    export default {
        name: 'ToolListe',
        props: {

        },
        components: {
            //Kundenliste,
            gruppenwahl,
            toolview,
            changeOrder,
        },
        data() {
            return {
                datapool: [],
                expand:[],
                working: false,
                gruppen: [],
                selectedGruppe: '0000',
                DetailView: '',
            }
        },
        methods: {
            async getProdukte() {
                this.datapool = await getAllTools(this.selectedGruppe)
            },
            GruppeChanged(value) {
                this.selectedGruppe = value
                this.getProdukte()
                this.expand =[]
                //console.log(value)
            },
            HasTest(item) {
                return item.link_testversion!=''
            },
            HasKauf(item) {
                return item.link_bestellen != ''
            },
            Detail(item) {
                this.DetailView = item
            },
            formatPrice(value) {
                return value.toLocaleString('de-DE', { style: 'currency', currency: 'EUR' });
            },
            removeExtension(filename) {
                var lastDotPosition = filename.lastIndexOf(".");
                if (lastDotPosition === -1) return filename;
                else return filename.substr(0, lastDotPosition);
            },
            getLogo(data) {
                //console.log(process.env.VUE_APP_SCREENLOGOURL + data)
                data = this.removeExtension(data)+'.png'
                //console.log(process.env.VUE_APP_SCREENLOGOURL + data)
                return (process.env.VUE_APP_SCREENLOGOURL + data)
            },
            isExpand(item) {
                if (this.expand.length <= 0) return false
                return this.expand.indexOf(item.pid)>-1
            },
            DoExpand(item) {
                var index = this.expand.indexOf(item.pid);
                if (index > -1) {
                    this.expand.splice(index, 1);
                } else {
                    this.expand.push(item.pid)
                }
                //console.log(item)
            },
            CloseExpand(pid) {
                var index = this.expand.indexOf(pid);
                if (index > -1) {
                    this.expand.splice(index, 1);
                }
            }
        },
        created() {
            this.getProdukte()
        }
    };
</script>

<style scoped>

    .mbox{
        border: solid 1px #dddddd;
        padding: 0.5em;
    }

    .mt5{
        clear:both;
        margin-top: 1em;
    }

    .c1 {
        width: 12%;
        padding: 5px;
    }

    .c0 {
        padding: 5px;
    }

    .c1b {
        font-weight:bold;
    }

    .cr{
        float: right;
        height:48px;
    }

    .right{
        float:right;
        margin-top: -40px;
    }
    .infoblock{
        float: left;
        width: 70%;
        margin-top: 20px;
    }
    .top10{
        margin-top:10px;
    }

    hr.class-1 {
        border-top: 4px solid #8c8b8b;
        clear: both;
    }

    .Headline {
        float: left;
        font-weight: bold;
        font-size: 1.4em;
        padding-bottom: .5em;

    }

    .Action{
        padding-top:0.5em;
        cursor: pointer;
    }

</style>