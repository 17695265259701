export const namespaced = true

import moment from 'moment'



export function Currency(value) {
    return Number.parseFloat(value).toFixed(2) + ' €'
}

export function CurrencyF2(value) {
    return Number.parseFloat(value).toFixed(2)
}

export function GetHeuteWerktag(Days) {
    if (Days) {
        let Stamp = moment().add(Days, 'days')
        //console.log(new Date(Stamp).getDay())
        if (new Date(Stamp).getDay() == 6) { Stamp = moment().add(Days + 2, 'days') }
        if (new Date(Stamp).getDay() == 0) { Stamp = moment().add(Days + 1, 'days') }
        return Stamp.format('YYYY-MM-DD')
    }
    return moment().format('YYYY-MM-DD')
}

export function GetNow() {
    return moment().format('YYYY-MM-DD HH:mm')
}

export function GetRechnungDatum(datum) {
    if (datum) {
        return moment.utc(String(datum)).local().format('MMM YYYY')
    }
}

export function GetVorgangsDatum(datum) {
    if (datum) {
        return moment.utc(String(datum)).local().format('YYYYMM')
    }
}

export function GetDataBaseDatum(datum) {
    if (datum) {
        return moment.utc(String(datum)).local().format('YYYY-MM-DD')
    }
}

export function ShowDateTime(datum) {
    if (datum) {
        return moment.utc(String(datum)).local().format('DD.MM.YYYY HH:mm')
    }
}

export function ShowDateTimeUTC(datum) {
    if (datum) {
        return moment.utc(String(datum)).format('DD.MM.YYYY HH:mm')
    }
}

export function ShowDatum(datum) {
    if (datum) {
        return moment.utc(String(datum)).local().format('DD.MM.YYYY')
    }
}

export function IsInFuture(datum) {
    if (datum) {
        return moment.utc(String(datum)).local() >= moment.utc()
    }
}