<template>
    <div class="tabelle">
        <div class="headline">
            <div class="headitem fatcol">{{data.memberID}}</div>
            <div class="headitem">{{data.anzeigename}}</div>
            <div class="headitem"><v-icon name="info"></v-icon>{{data.bemerkung}}</div>
            <div class="headitem right">>> <a @click="RemoveFromWhiteList(data.memberID)">[Remove From WhiteList]</a></div>
        </div>
        <div class="headline">
            <div class="colspace">&nbsp;- </div>
            <div class="headitem col3">
                <v-icon name="handshake"></v-icon>{{Datum(data.changed)}}
            </div>
            <div class="headitem col3">
                <v-icon name="handshake"></v-icon> {{Datum(data.set2List)}}
            </div>
            <div class="headitem col3">
                <v-icon name="chalkboard-teacher"></v-icon> {{Datum(data.removeList)}}
            </div>
        </div>
        <div class="clear"></div>
    </div>
</template>

<script>
    //import KundenListe from '@/components/invoice/kundenliste.vue'
    import { ShowDateTime } from '@/moduls/datum.js'
    export default {
        data() {
            return {
                suchpattern: {},
                working: false,
            }
        },
        props: {
            data: {},
        },
        methods: {
            databaseEnv() {
                return `${process.env.VUE_APP_API_URL}`
            },
            RemoveFromWhiteList(item) {
                this.$emit('whitelist', item)
                // console.log('whitelist ' + item)
            },
            Datum(item) {
                return ShowDateTime(item)
            }

        },
        components: {
           
        }
    }
</script>

<style scoped>
    .headline{
        clear:both;
    }
    .headitem{
        float:left;
        padding-right: 1em;
    }

    .right{
        float:right;
    }
    .fatcol{
        font-weight: bold;
        padding-right: 1em;
    }
    .col3{
        width:25%;
    }
    .colspace{
        width: 3em;
        min-width: 3em;
        float: left;
    }
    .tabelle {
        clear: both;
        padding-top: 0.5em;
        padding-bottom: 0.5em;
        border-bottom: solid 1px gray;
    }

</style>