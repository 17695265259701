<template>
    <div>
        <div>
            <div class="preis">Suchergebnis:</div>
            <div>
                <div v-if="!working" class="suchinfo">{{suche}}</div>
                <div v-else><v-icon name="spinner" spin></v-icon></div>
            </div>
        </div>
        <div class="clear"></div>
        <pages :dataPool="datapool" @changePage="onChangePage" :perPage="pPage"></pages>
        <div v-for="(searchres,index) in pageOfItems" v-bind:key="index">
            <MemberPanel :data="searchres" @refresh="refresh"></MemberPanel>
        </div>
        <div v-if="!datapool" class="viewbox">
            Kein Suchergebnis
        </div>
        <!--{{datapool}}-->
    </div>
</template>

<script>
    import { getMember, getMemberFilter } from '@/moduls/auth'
    import MemberPanel from '@/components/memberPanel.vue'

    import pages from '@/components/pagination.vue'

    export default {
        name: 'Suchergebnis',
        props: {
            suche: {}
        },
        components: {
            MemberPanel,
            pages
        },
        data() {
            return {
                selection: 0,
                working: false,
                datapool: [],
                pageOfItems: [],
                pPage: 15,
            }
        },
        mounted() {
            //console.log('mount ' + JSON.stringify(this.$route.params) + ' ' + this.suche)
        },
        watch: {
            suche: async function () {
                console.log('watch suche ' + JSON.stringify(this.suche))
                if (this.suche) {
                    console.log(JSON.stringify(this.suche))
                    await this.getMemberFilter(this.suche.topic, this.suche.pattern)
                }
            }
        },
        methods: {
            async getMemberFromID(memberID) {

                this.datapool= await getMember(memberID)
            },
            async getMemberFilter(topic, pattern) {
                //console.log('0')
                this.working = true
                this.datapool = await getMemberFilter(topic, pattern)
                //console.log('1')
                this.working = false
            },
            onChangePage(pageOfItems) {
                // update page of items
                this.pageOfItems = pageOfItems;
            },
            refresh(memberID) {
                //console.log('suchergebnis ' + memberID)
                this.$emit('refreshsuche', memberID)
                //this.getMemberFromID(memberID)
            }
        }
    };
</script>

<style scoped>
    .suchinfo{
        padding-top: 8px;
        padding-left: 16px;
    }

    .viewbox {
        padding-top:1em;
        padding: 0.5em;
        border: 1px solid gray;
        border-radius: 5px;
    }
</style>