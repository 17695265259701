import decode from 'jwt-decode'
import axios from 'axios'

const AUTH_TOKEN_KEY = 'authToken'

export function getMember(memberID) {
    return new Promise(async (resolve, reject) => {
        try {
            let res = await axios({
                //url: `${process.env.VUE_APP_API_URL}api/Kunde/`,
                url: `${process.env.VUE_APP_API_URL}api/Kunde/`+memberID,
                method: 'GET',
                headers: authHeader(),
            })
            resolve(res.data)
        } catch (err) {
            reject(err)
        }
    })
}

export function getWarenkorb(memberID) {
    return new Promise(async (resolve, reject) => {
        try {
            let res = await axios({
                url: `${process.env.VUE_APP_API_URL}api/Kunde/Warenkorb/` + memberID + ``,
                method: 'GET',
                headers: authHeader(),
            })
            resolve(res.data)
        } catch (err) {
            console.log(err)
            reject(err)
        }
    })
}

export function deleteWarenkorb(memberID, PID) {
    return new Promise(async (resolve, reject) => {
        try {
            let res = await axios({
                url: `${process.env.VUE_APP_API_URL}api/Kunde/Warenkorb/` + memberID + ``,
                method: 'DELETE',
                headers: authHeader(),
                data: {
                    memberID: memberID,
                    pid: PID,
                },
            })
            resolve(res.data)
        } catch (err) {
            reject(err)
        }
    })
}

export function deleteWarenkorbSelections(memberID) {
    return new Promise(async (resolve, reject) => {
        try {
            let res = await axios({
                url: `${process.env.VUE_APP_API_URL}api/Kunde/Selection/` + memberID + ``,
                method: 'DELETE',
                headers: authHeader(),
            })
            resolve(res.data)
        } catch (err) {
            reject(err)
        }
    })
}

export function PutWarenkorb(memberID, PID) {
    return new Promise(async (resolve, reject) => {
        try {
            let res = await axios({
                url: `${process.env.VUE_APP_API_URL}api/Kunde/Warenkorb/` + memberID + ``,
                method: 'PUT',
                headers: authHeader(),
                data: {
                    memberID: memberID,
                    pid: PID,
                },
            })
            resolve(res.data)
        } catch (err) {
            reject(err)
        }
    })
}

export function confirmWarenkorb(memberID, PID) {
    return new Promise(async (resolve, reject) => {
        try {
            let res = await axios({
                url: `${process.env.VUE_APP_API_URL}api/Kunde/Warenkorb/` + memberID + ``,
                method: 'POST',
                headers: authHeader(),
                data: {
                    memberID: memberID,
                    pid: PID,
                },
            })
            resolve(res.data)
        } catch (err) {
            reject(err)
        }
    })
}

export function getMemberApps(memberID) {
    return new Promise(async (resolve, reject) => {
        try {
            let res = await axios({
                url: `${process.env.VUE_APP_API_URL}api/Kunde/` + memberID + `/Apps`,
                method: 'GET',
                headers: authHeader(),
            })
            resolve(res.data)
        } catch (err) {
            reject(err)
        }
    })
}


export function getMemberKaufInfo(memberID) {
    return new Promise(async (resolve, reject) => {
        try {
            let res = await axios({
                url: `${process.env.VUE_APP_API_URL}api/Kunde/` + memberID + `/KaufInfo`,
                method: 'GET',
                headers: authHeader(),
            })
            resolve(res.data)
        } catch (err) {
            reject(err)
        }
    })
}


export function getMemberAppsServiceState() {
    return new Promise(async (resolve, reject) => {
        try {
            let res = await axios({
                url: `${process.env.VUE_APP_API_URL}api/service/status`,
                method: 'GET',
                headers: authHeader(),
            })
            resolve(res.data)
        } catch (err) {
            reject(err)
        }
    })
}

export function getMemberWaren(memberID) {
    return new Promise(async (resolve, reject) => {
        try {
            let res = await axios({
                url: `${process.env.VUE_APP_API_URL}api/Kunde/Warenkorb/` + memberID,
                method: 'GET',
                headers: authHeader(),
            })
            resolve(res.data)
        } catch (err) {
            reject(err)
        }
    })
}

export function addShot2Tool(pid, shotlink, gruppe) {
    return new Promise(async (resolve, reject) => {
        try {
            let res = await axios({
                url: `${process.env.VUE_APP_API_URL}api/Tool/AddShot/` ,
                method: 'PUT',
                headers: authHeader(),
                data: {
                    shotlink: shotlink,
                    pid: pid,
                    Gruppe: gruppe
                },
            })
            resolve(res.data)
        } catch (err) {
            reject(err)
        }
    })
}

export function removeShot2Tool(pid, shotlink, gruppe) {
    return new Promise(async (resolve, reject) => {
        try {
            let res = await axios({
                url: `${process.env.VUE_APP_API_URL}api/Tool/AddShot/`,
                method: 'DELETE',
                headers: authHeader(),
                data: {
                    shotlink: shotlink,
                    pid: pid,
                    Gruppe: gruppe
                },
            })
            resolve(res.data)
        } catch (err) {
            reject(err)
        }
    })
}

export function getTools(gruppe) {
    return new Promise(async (resolve, reject) => {
        try {
            let res = await axios({
                url: `${process.env.VUE_APP_API_URL}api/Tools/` + gruppe,
                method: 'GET',
                headers: authHeader(),
            })
            resolve(res.data)
        } catch (err) {
            reject(err)
        }
    })
}

export function updateTool(dataitem) {
    //console.log(JSON.stringify(dataitem))
    return new Promise(async (resolve, reject) => {
        try {
         
            let res = await axios({
                url: `${process.env.VUE_APP_API_URL}api/Tool`,
                method: 'POST',
                headers: authHeader(),
                data: dataitem,
            })
            resolve(res.data)
        } catch (err) {
            reject(err)
        }
    })

}

export function uploadStuff(data) {
    return new Promise(async (resolve, reject) => {
        try {
            let res = await axios({
                url: `${process.env.VUE_APP_API_URL}api/Images`,
                method: 'PUT',
                headers: authHeader(),
                data: data
            })
            resolve(res.data)
        } catch (err) {
            reject(err)
        }
    })
}

export function getStuff() {
    return new Promise(async (resolve, reject) => {
        try {
            let res = await axios({
                url: `${process.env.VUE_APP_API_URL}api/Images`,
                method: 'GET',
                headers: authHeader(),
            })
            resolve(res.data)
        } catch (err) {
            reject(err)
        }
    })
}

export function getApps(gruppe) {
    return new Promise(async (resolve, reject) => {
        try {
            let res = await axios({
                url: `${process.env.VUE_APP_API_URL}api/Gruppe/` + gruppe ,
                method: 'GET',
                headers: authHeader(),
            })
            resolve(res.data)
        } catch (err) {
            reject(err)
        }
    })
}

export function getAllApps() {
    return new Promise(async (resolve, reject) => {
        try {
            let res = await axios({
                url: `${process.env.VUE_APP_API_URL}api/AppInfos/` ,
                method: 'GET',
                headers: authHeader(),
            })
            resolve(res.data)
        } catch (err) {
            reject(err)
        }
    })
}

export function getProforma(memberID) {
    return new Promise(async (resolve, reject) => {
        try {
            //console.log('id ' +memberID)
            let res = await axios({
                url: `${process.env.VUE_APP_API_URL}api/Kunde/Rechnung/base64/`+ memberID,
                method: 'GET',
                headers: authHeader(),
            })
            resolve(res.data)
        } catch (err) {
            reject(err)
        }
    })
}

export function getAllUpdateApps() {
    return new Promise(async (resolve, reject) => {
        try {
            let res = await axios({
                url: `${process.env.VUE_APP_API_URL}api/GetUpdateProdukte/`,
                method: 'POST',
                headers: authHeader(),
            })
            resolve(res.data)
        } catch (err) {
            reject(err)
        }
    })
}

export function getAllFilePools() {
    return new Promise(async (resolve, reject) => {
        try {
            let res = await axios({
                url: `${process.env.VUE_APP_API_URL}api/AppUpdates`,
                method: 'GET',
                headers: authHeader(),
            })
            resolve(res.data)
        } catch (err) {
            reject(err)
        }
    })
}

export function getFilePools(id) {
    return new Promise(async (resolve, reject) => {
        try {
            let res = await axios({
                url: `${process.env.VUE_APP_API_URL}api/FilePool/`+ id,
                method: 'GET',
                headers: authHeader(),
            })
            resolve(res.data)
        } catch (err) {
            reject(err)
        }
    })
}

export function validateCaptcha(code) {
    return new Promise(async (resolve, reject) => {
        try {
            let res = await axios({
                url: `${process.env.VUE_APP_API_URL}api/Token/IsCaptcha/`+code,
                method: 'GET',
                headers: authHeader(),
            })
            resolve(res.data)
        } catch (err) {
            reject(err)
        }
    })
}

export function getCaptcha() {
    return new Promise(async (resolve, reject) => {
        try {
            let res = await axios({
                url: `${process.env.VUE_APP_API_URL}api/Token/Captcha`,
                method: 'GET',
                headers: authHeader(),
                
            })
            //console.log(res)
            resolve(res.data)
        } catch (err) {
            reject(err)
        }
    })
}


export function insertTestlizenz(id, pass) {
    return new Promise(async (resolve, reject) => {
        try {
            let res = await axios({
                url: `${process.env.VUE_APP_API_URL}api/Service/Testuserpool/` + id + `/`+ pass+``,
                method: 'PUT',
                headers: authHeader(),
            })
            console.log(res.data)
            resolve(res.data)
        } catch (err) {
            if (err.response) {
                if (err.response.status === 404) {
                    resolve("LizenzKey nicht erkannt")
                } else if (err.response.status === 401) {
                    resolve("Auth required")
                    //return ("Auth required")
                } else {
                    console.error('Response:', err.response)
                    reject(err)
                }
            } else if (err.request) {
                console.error('Request: ', err.request)
                reject("Server antwortet nicht")
            } else {
                console.error('Caught an error during resetkey:', err)
                reject(err)
            }
        }
    })
}


export function hashPass(key) {
    return new Promise(async (resolve, reject) => {
        try {
            let res = await axios({
                url: `${process.env.VUE_APP_API_URL}api/genPass/`+key,
                method: 'GET',
                headers: authHeader(),

            })
            //console.log(res)
            resolve(res.data)
        } catch (err) {
            reject(err)
        }
    })
}

export function resetLizenzkey(lizenzkey) {
    return new Promise(async (resolve, reject) => {
        try {
            //console.log(PID + ' ' + version + ' ' + stream)
            let res = await axios({
                url: `${process.env.VUE_APP_API_URL}api/Lizenz/resetactivation`,
                method: 'POST',
                headers: authHeader(),
                data: {
                    lizenzkey: lizenzkey
                },
            })
            resolve(res.data)
        } catch (err) {
            if (err.response) {
                if (err.response.status === 404) {
                    resolve("LizenzKey nicht erkannt")
                } else if (err.response.status === 401) {
                    resolve("Auth required")
                    //return ("Auth required")
                } else {
                    console.error('Response:', err.response)
                    reject(err)
                }
            } else if (err.request) {
                console.error('Request: ', err.request)
                reject("Server antwortet nicht")
            } else {
                console.error('Caught an error during resetkey:', err)
                reject(err)
            }
        }
    })
}


export function ResetPassword1(email, code) {
    return new Promise(async (resolve, reject) => {
        try {
            //console.log(PID + ' ' + version + ' ' + stream)
            let res = await axios({
                url: `${process.env.VUE_APP_API_URL}api/Token/CaptchaResetPassword`,
                method: 'POST',
                headers: authHeader(),
                data: {
                    captchaCode: code,
                    eMail: email
                },
            })
            resolve(res.data)
        } catch (err) {
            reject(err)
        }
    })
}


export function ActivateLizenzAdmin(email, code) {
    return new Promise(async (resolve, reject) => {
        try {
            //console.log(PID + ' ' + version + ' ' + stream)
            let res = await axios({
                url: `${process.env.VUE_APP_API_URL}api/Token/AdminActivateKonto`,
                method: 'POST',
                headers: authHeader(),
                data: {
                    captchaCode: code,
                    eMail: email
                },
            })
            resolve(res.data)
        } catch (err) {
            reject(err)
        }
    })
}

export function ResetPasswordAdmin(email, code) {
    return new Promise(async (resolve, reject) => {
        try {
            //console.log(PID + ' ' + version + ' ' + stream)
            let res = await axios({
                url: `${process.env.VUE_APP_API_URL}api/Token/AdminResetPassword`,
                method: 'POST',
                headers: authHeader(),
                data: {
                    captchaCode: code,
                    eMail: email
                },
            })
            resolve(res.data)
        } catch (err) {
            reject(err)
        }
    })
}


export function ResetPassword0(email, code) {
    return new Promise(async (resolve, reject) => {
        try {
            //console.log(PID + ' ' + version + ' ' + stream)
            let res = await axios({
                url: `${process.env.VUE_APP_API_URL}api/Token/WebResetPasswordLink`,
                method: 'POST',
                headers: authHeader(),
                data: {
                    captchaCode: code,
                    eMail: email
                },
            })
            resolve(res.data)
        } catch (err) {
            reject(err)
        }
    })
}

export function ChangePassword1(oldPass, newPass, email) {
    return new Promise(async (resolve, reject) => {
        try {
            //console.log(PID + ' ' + version + ' ' + stream)
            let res = await axios({
                url: `${process.env.VUE_APP_API_URL}api/Token/KontoChangePassword`,
                method: 'POST',
                headers: authHeader(),
                data: {
                    oldPW: oldPass,
                    newPW: newPass,
                    eMail:email
                },
            })
            //console.log(res)
            resolve(res.data)
        } catch (err) {
            reject(err)
        }
    })
}

export function setPIDFilePoolRTM( PID, version, stream) {
    return new Promise(async (resolve, reject) => {
        try {
            console.log(PID + ' ' + version + ' ' + stream )
            let res = await axios({
                url: `${process.env.VUE_APP_API_URL}api/SetRTMUpdate/`,
                method: 'POST',
                headers: authHeader(),
                data: {
                    pid: PID,
                    version: version,
                    filePool: stream
                },
            })
            resolve(res.data)
        } catch (err) {
            reject(err)
        }
    })
}

export function submitMailTemplate(job) {
    return new Promise(async (resolve, reject) => {
        try {
            console.log(job)
            let res = await axios({
                url: `${process.env.VUE_APP_API_URL}api/MailTemplate`,
                method: 'POST',
                headers: authHeader(),
                data: job,
            })
            resolve(res.data)
        } catch (err) {
            reject(err)
        }
    })
}

export function submitServiceStatus(job, info, disab, datum) {
    return new Promise(async (resolve, reject) => {
        try {
            console.log(job + ' ' + info + ' ' + disab + ' '+datum)
            let res = await axios({
                url: `${process.env.VUE_APP_API_URL}api/service/status`,
                method: 'POST',
                headers: authHeader(),
                data: {
                    actionJob: job,
                    info: info,
                    disable: disab,
                    fromDate: datum
                },
            })
            resolve(res.data)
        } catch (err) {
            reject(err)
        }
    })
}


export function RefreshKaufSatz(pid, lizenz, from, till) {
    return new Promise(async (resolve, reject) => {
        try {
            let res = await axios({
                url: `${process.env.VUE_APP_API_URL}api/KaufSatz/service/`,
                method: 'PUT',
                headers: authHeader(),
                data: {
                    pid: pid,
                    lizenz: lizenz,
                    invoiceFrom: from,
                    validTill: till
                },
            })
            resolve(res.data)
        } catch (err) {
            reject(err)
        }
    })
}

export function GetKundenKaufPool(lizenz) {
    return new Promise(async (resolve, reject) => {
        try {
            let res = await axios({
                url: `${process.env.VUE_APP_API_URL}api/KaufSatz/service/` + lizenz,
                method: 'GET',
                headers: authHeader(),
            })
            resolve(res.data)
        } catch (err) {
            reject(err)
        }
    })
}

export function CheckExistingeMail(checkMail) {
    return new Promise(async (resolve, reject) => {
        try {
            let res = await axios({
                url: `${process.env.VUE_APP_API_URL}api/Kunde/LizenzMail/` + checkMail,
                method: 'GET',
                headers: authHeader(),
            })
            resolve(res.data)
        } catch (err) {
            reject(err)
        }
    })
}


export function getAllTools(gruppe) {
    return new Promise(async (resolve, reject) => {
        try {
            let res = await axios({
                url: `${process.env.VUE_APP_API_URL}api/Tools/`+gruppe,
                method: 'GET',
                headers: authHeader(),
            })
            resolve(res.data)
        } catch (err) {
            reject(err)
        }
    })
}

export function getDriveData(lizenz) {
    return new Promise(async (resolve, reject) => {
        try {
            console.log('Drive '+ lizenz)
            let res = await axios({
                url: `${process.env.VUE_APP_API_URL}api/CloudDrive/driveview/`+ lizenz,
                method: 'GET',
                headers: authHeader(),
            })
            resolve(res.data)
        } catch (err) {
            reject(err)
        }
    })
}

export function updateToolOrder(dataset) {
    return new Promise(async (resolve, reject) => {
        try {
            //console.log(PID + ' ' + memberID + ' ' + actionjob + ' ' + invoicefrom)
            let res = await axios({
                url: `${process.env.VUE_APP_API_URL}api/Tools/Order`,
                method: 'POST',
                headers: authHeader(),
                data: dataset,
            })
            resolve(res.data)
        } catch (err) {
            reject(err)
        }
    })
}

export function getInvoices(memberid) {
    return new Promise(async (resolve, reject) => {
        try {
            let res = await axios({
                url: `${process.env.VUE_APP_API_URL}api/Kunde/Rechnungen`,
                method: 'POST',
                headers: authHeader(),
                data: {
                    memberID: memberid,
                    positionen: true,
                },
            })
            resolve(res.data)
        } catch (err) {
            reject(err)
        }
    })
}

export function getMailTemplates() {
    return new Promise(async (resolve, reject) => {
        try {
            let res = await axios({
                url: `${process.env.VUE_APP_API_URL}api/MailTemplates`,
                method: 'GET',
                headers: authHeader(),
            })
            resolve(res.data)
        } catch (err) {
            reject(err)
        }
    })
}

export function getKaufEditData(memberid) {
    return new Promise(async (resolve, reject) => {
        try {
            let res = await axios({
                url: `${process.env.VUE_APP_API_URL}api/Kunde/KaufPanel/`+memberid,
                method: 'GET',
                headers: authHeader(),
            })
            resolve(res.data)
        } catch (err) {
            reject(err)
        }
    })
}

export function setPIDKauf(memberID, PID, actionjob, invoicefrom) {
    return new Promise(async (resolve, reject) => {
        try {
            console.log(PID + ' ' + memberID + ' ' + actionjob + ' ' + invoicefrom)
            let res = await axios({
                url: `${process.env.VUE_APP_API_URL}api/KundeApp/` + memberID,
                method: 'POST',
                headers: authHeader(),
                data: {
                    action: actionjob,
                    PID: PID,
                },
            })
            resolve(res.data)
        } catch (err) {
            reject(err)
        }
    })
}

export function deleteLizenzKauf(memberID, PID) {
    return new Promise(async (resolve, reject) => {
        try {
            console.log(PID + ' ' + memberID)
            let res = await axios({
                url: `${process.env.VUE_APP_API_URL}api/Kunde/LizenzKauf/` + memberID + `/`+ PID,
                method: 'Delete',
                headers: authHeader(),
                //data: {
                //    action: actionjob,
                //    PID: PID,
                //},
            })
            resolve(res.data)
        } catch (err) {
            reject(err)
        }
    })
}

export function getTestlizenzpool() {
    return new Promise(async (resolve, reject) => {
        try {
            let res = await axios({
                url: `${process.env.VUE_APP_API_URL}api/Service/Testuserpool`,
                method: 'GET',
                headers: authHeader(),
            })
            resolve(res.data)
        } catch (err) {
            reject(err)
        }
    })
}


export function getWebStatusCode(url) {
    console.log(url)
    return new Promise(async (resolve, reject) => {
        try {
            let res = await axios.get(url)
            resolve(res.data)
        } catch (err) {
            reject(err)
        }
    })
}


export function getLogoPools(key) {
    return new Promise(async (resolve, reject) => {
        try {
            let res = await axios({
                url: `${process.env.VUE_APP_API_URL}api/sync/` + key  +`/Logos`,
                method: 'GET',
                headers: authHeader(),
            })
            //console.log(res)
            resolve(res.data)
        } catch (err) {
            reject(err)
        }
    })
}

export function getLogoSet(key, gruppe) {
    return new Promise(async (resolve, reject) => {
        try {
            let res = await axios({
                url: `${process.env.VUE_APP_API_URL}api/sync/` + key + `/Logo/`+ gruppe,
                method: 'GET',
                headers: authHeader(),
            })
            //console.log(res)
            resolve(res.data)
        } catch (err) {
            reject(err)
        }
    })
}

export function PushRechnungseMail(memberID, nemail) {
    return new Promise(async (resolve, reject) => {
        try {
            let job = {
                'memberData': {
                    'rechnungseMail': nemail
                }
            }
            console.log(memberID + ' ' + nemail+ ' '+job)
            let res = await axios({
                url: `${process.env.VUE_APP_API_URL}api/Kunde/`+memberID+`/Detail`,
                method: 'POST',
                headers: authHeader(),
                data: job,
            })
            resolve(res.data)
        } catch (err) {
            ErrorHandler(err, resolve, reject)
        }
    })

}


export function getMemberFilter(topic, pattern, sortorder) {
    return new Promise(async (resolve, reject) => {
        try {
            let res = await axios({
                url: `${process.env.VUE_APP_API_URL}api/Kunde/filter`,
                method: 'POST',
                headers: authHeader(),
                data: {
                    art: topic,
                    pattern: pattern,
                    sortOrder: sortorder,
                },
            })
            resolve(res.data)
        } catch (err) {
            ErrorHandler(err, resolve, reject)
        }
    })
}

export function getMembers(filter) {
    return new Promise(async (resolve, reject) => {
        try {
            let a=0
            if (filter) { a = 3 }
            let res = await axios({
                url: `${process.env.VUE_APP_API_URL}api/Kunden`,
                method: 'GET',
                headers: authHeader(),
            })
            console.log(a)
            resolve(res.data)
        } catch (err){
            ErrorHandler(err, resolve, reject)
        }
    })
}


export function getServerProtokoll(token, AUTH) {
    return new Promise(async (resolve, reject) => {
        try {
            let res = await axios({
                url: `${process.env.VUE_APP_API_URL}api/sync/ServerProcesses/` + token+'/'+AUTH,
                method: 'GET',
                headers: authHeader(),
            })
            //console.log(a)
            resolve(res.data)
        } catch (err) {
            reject(err)
        }
    })
}


export function getServerHashDataSet(token) {
    return new Promise(async (resolve, reject) => {
        try {
            let res = await axios({
                url: `${process.env.VUE_APP_API_URL}api/sync/analyse/`+token,
                method: 'GET',
                headers: authHeader(),
            })
            //console.log(a)
            resolve(res.data)
        } catch (err) {
            reject(err)
        }
    })
}

export function getKaufStatistik() {
    return new Promise(async (resolve, reject) => {
        try {
            let res = await axios({
                url: `${process.env.VUE_APP_API_URL}api/KaufStatistik`,
                method: 'GET',
                headers: authHeader(),
            })
            //console.log(a)
            resolve(res.data)
        } catch (err) {
            reject(err)
        }
    })
}

export function updateUserRoles(change) {
    return new Promise(async (resolve, reject) => {
        try {
            let res = await axios({
                url: `${process.env.VUE_APP_API_URL}api/Backend/UserRole`,
                method: 'POST',
                headers: authHeader(),
                data: change,
            })
            resolve(res.data)
        } catch (err) {
            reject(err)
        }
    })
}

export function getUserRoles() {
    return new Promise(async (resolve, reject) => {
        try {
            let res = await axios({
                url: `${process.env.VUE_APP_API_URL}api/UserRoles`,
                method: 'GET',
                headers: authHeader(),
            })
            //console.log(a)
            resolve(res.data)
        } catch (err) {
            reject(err)
        }
    })
}

export function setMemberPass(member, newpass) {
   
    return new Promise(async (resolve, reject) => {
        try {
            let res = await axios({
                url: `${process.env.VUE_APP_API_URL}api/Kunde/`+member+`/Password`,
                method: 'Post',
                headers: authHeader(),
                data: {
                    membersID: member,
                    passPhase: 'ResetUserbyAdmin',
                    passNPhase: newpass
                },
            })
            resolve(res.data)
        } catch (err) {
            reject(err)
        }
    })
}

export function loginUser(username, password) {
    return new Promise(async (resolve, reject) => {
        try {
            let res = await axios({
                url: `${process.env.VUE_APP_API_URL}api/Token`,
                method: 'POST',
                data: {
                    lizenzAnmeldeName: username,
                    pass: password,
                },
            })

            setAuthToken(res.data.token)
            resolve()
        }
        catch (err) {
            reject(LoginErrorHandler(err))
            //if (err.response) {
            //    if (err.response.status === 400) {
            //        reject("Anmeldedaten nicht erkannt")
            //    } else {
            //        console.error('Response login:', err.response)
            //    }
            //} else if (err.request) {
            //    console.error('Request login:', err.request)
            //    reject("Server antwortet nicht")
            //} else {
            //    console.error('Caught an error during login:', err)
            //    reject(err)
            //}
        }
    })
}

function ErrorHandler(err, resolve, reject) {
    //console.log(' Error Handler '+ err)
    if (err.response) {
        if (err.response.status === 400) {
            reject("Anmeldedaten nicht erkannt")
        } else if (err.response.status === 403) {
            resolve ([])
            //return ("Auth required")
        } else {
            console.error('Response login:', err.response)
            reject(err)
        }
    } else if (err.request) {
        console.error('Request login:', err.request)
        reject ("Server antwortet nicht")
    } else {
        console.error('Caught an error during login:', err)
        reject(err)
    }
}

function LoginErrorHandler(err) {
    if (err.response) {
        if (err.response.status === 400) {
            return ("Anmeldedaten nicht erkannt")
        } else {
            console.error('Response login:', err.response)
        }
    } else if (err.request) {
        console.error('Request login:', err.request)
        return("Server antwortet nicht")
    } else {
        console.error('Caught an error during login:', err)
        return (err)
    }
}


export function authHeader() {
    return { 'Authorization': 'Bearer ' + getAuthToken(), 'Content-type': 'application/json', 'Access-Control-Allow-Origin': '*', 'Access-Control-Allow-Credentials':'true', 'X-ed-uuid': getUUID() }
}

export function authFileHeader() {
    return { 'Authorization': 'Bearer ' + getAuthToken(), 'Content-Type': 'multipart/form-data' }
}


export function isAdminUser() {
    //console.log('iA ' + getUserInfo()?.role?.includes('Admin'))

    //console.log(getUserInfo().role)
    return getUserInfo()?.role?.includes('Adminuser')
}

export function isPowerUser() {
    //console.log('iP ' + getUserInfo()?.role?.includes('Poweruser'))
    return getUserInfo()?.role?.includes('Poweruser')
}

export function isKundenUser() {
    //console.log('iP ' + getUserInfo()?.role?.includes('Poweruser'))
    return getUserInfo()?.role?.includes('Kundenuser')
}

export function isFinanzUser() {
    //console.log('iP ' + getUserInfo()?.role?.includes('Poweruser'))
    return getUserInfo()?.role?.includes('Finanzuser')
}

export function isUpdateUser() {
    //console.log('iP ' + getUserInfo()?.role?.includes('Poweruser'))
    return getUserInfo()?.role?.includes('Updateuser')
}

export function isWebUser() {
    //console.log('iP ' + getUserInfo()?.role?.includes('Poweruser'))
    return getUserInfo()?.role?.includes('Tooluser')
}

export function logoutUser() {
    clearAuthToken()
}

export function setAuthToken(token) {
    axios.defaults.headers.common['Authorization'] = `Bearer ${token}`
    localStorage.setItem(AUTH_TOKEN_KEY, token)
}

export function getAuthToken() {
    return localStorage.getItem(AUTH_TOKEN_KEY)
}




export function getUUID() {
    if (localStorage.getItem('X-ed-uuid')) {
        return localStorage.getItem('X-ed-uuid')
    }
    let nG = GUID()
    localStorage.setItem('X-ed-uuid',nG)
    return nG
}

export function GUID() {
    // Public Domain/MIT
    var d = new Date().getTime();
    if (typeof performance !== 'undefined' && typeof performance.now === 'function') {
        d += performance.now(); //use high-precision timer if available
    }
    var newGuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
        var r = (d + Math.random() * 16) % 16 | 0;
        d = Math.floor(d / 16);
        return (c === 'x' ? r : (r & 0x3 | 0x8)).toString(16);
    });
    return newGuid;
}


export function clearAuthToken() {
    axios.defaults.headers.common['Authorization'] = ''
    localStorage.removeItem(AUTH_TOKEN_KEY)
    localStorage.removeItem('X-ed-uuid')
}

export function isLoggedIn() {
    let authToken = getAuthToken()
    return authToken && !isTokenExpired(authToken)
}

export function getUserInfo() {
    if (isLoggedIn()) {
        return decode(getAuthToken())
    }
}

export function getLoginUserName() {
    if (isLoggedIn()) {
        var z = decode(getAuthToken())
        return z.nameid
    }
}
export function getLoginUsereMail() {
    if (isLoggedIn()) {
        var z = decode(getAuthToken())
        return z.unique_name
    }
}

function getTokenExpirationDate(encodedToken) {
    let token = decode(encodedToken)
    if (!token.exp) {
        return null
    }

    let date = new Date(0)
    date.setUTCSeconds(token.exp)

    return date
}

function isTokenExpired(token) {
    let expirationDate = getTokenExpirationDate(token)
    console.log(expirationDate < new Date())
    return expirationDate < new Date()
}