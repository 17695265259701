<template>
    <div class="fix">
        <div v-if="SubmitStatus==''" class="captcha">
            <div class="redl">
                <div class="recimg">
                    <v-icon name="spinner" v-if="working" spin scale="5"></v-icon>
                    <img :src="getImage()" v-else />
                </div>
                <div v-if="!working" class="recicon" @click="recycle"> <v-icon name="recycle" scale="1.2"></v-icon></div>
                <div class="clear"></div>
            </div>
            <div v-if="IsActiv">
                <div ><input type="text" v-model="code" class="incode" placeholder="Bitte Code aus Bild eingeben." /></div>
                <div v-if="code.length>4" @click="validateCaptcha" class="subicon"><v-icon name="arrow-circle-right" scale="2"></v-icon></div> 
            </div>
            <div v-if="SubmitStatus=='' && !start" class="failed" >Der eingegeben Code war nicht korrekt.</div>
        </div>
        <div v-else @click="setCaptchaStart">
            Captcha Verifizierung starten
        </div>
        <!--{{ServerStatus}}-->
    </div>
</template>

<script>
    import { getCaptcha, validateCaptcha } from '@/moduls/auth.js'


    export default {
        name: 'Captcha',
        props: {
            IsActiv: Boolean
            //suche: {}
        },
        components: {

        },
        data() {
            return {
                start: true,
                ServerStatus: {},
                code: '',
                SubmitStatus: '',
                working: false,
            }
        },
        mounted() {

        },
        computed: {
            
        },
        created() {
            this.start=true
            this.getData()
        },
        methods: {

            recycle() {
                this.getData()
                this.start= true
            },

            async validateCaptcha() {
                this.working = true
                this.SubmitStatus = await validateCaptcha(this.code)
                console.log('* '+document.cookie)
                if (this.SubmitStatus == '') {
                    this.getData()
                    this.start = false
                }
                this.code=''
                this.working = false
            },

            async getData() {
                //console.log('getFilter '+ this.selection);
                this.working = true
                this.ServerStatus = await getCaptcha()
                this.working = false
            },
            getImage() {
                return this.ServerStatus
            },
            setCaptchaStart() {
                this.SubmitStatus = ''
                this.getData()
                this.start = true
            }
            

        }
    };
</script>

<style scoped>
    .subicon{
        margin-top: -32px;
        margin-left: 100px;

    }

    .failed{
        padding: 5px;
        color: darkred;
        font-weight: bold;
        font-size: small;
    }

    .redl {
        /*background: red;*/
    }

    .incode{
        width: 200px;
        margin:5px;
        padding : 2px;
    }


    .fix {
        min-width: 270px;
        max-width: 270px;
        box-shadow: 5px 10px rgba(0,58,92,0.7);
        background: rgba(0,58,92,.2)
    }
    .captcha {
        border: solid 2px #003462;
    }

    .recicon {
        display: flex;
        justify-content: center;
        align-items:flex-end;
        height: 75px;
        width: 50px;
        cursor: pointer;
        /*border: 3px solid green;*/
    }

    .recimg {
        float: left;
        /*height: 75px;*/
    }

    img {
        padding: 5px;
        height:75px;
    }
</style>