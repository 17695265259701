<template>
    <div>

        <hr />
        <div class="pre vertical-align"><button @click="selection=2">Kontoaktivierung</button></div><div>Aktivierung setzten, Code versenden [{{lastSel2}}].</div>
        <div class="extend" v-if="selection==2">
            <div>
                <div>Anmeldename</div>
                <input type="text" v-model="AktivationID" />
                <button @click="doAction(2)">Absenden</button>
            </div>
        </div>
        <div class="clear"></div>
        <hr />
        <div class="pre vertical-align"><button @click="DoSelect(4)">Reset Password</button></div><div>Kundenpassword zurücksetzten (vergeben)</div>
        <div class="p05">
            <div class="extend" v-if="selection==4">

                <div>Password Rücksetzten für</div><input type="text" v-model="memberID" />
                <div>neues PWD</div><input type="password" v-model="npass" />
                <div>-.-</div>
                <button @click="doAction(4)">Absenden</button>
            </div>

        </div>
        <div class="clear"></div>
        <hr />
        <div class="pre vertical-align"><button @click="DoSelect(3)" >Reset Password</button></div><div>Kundenpassword zurücksetzten (Mail an User)</div>
        <div class="p05">
            <div class="extend" v-if="selection==3">
                <div>Captcha</div>
                <div>Password Rücksetzten für</div>
                <input type="text" v-model="memberID" />
                <div>Resetlink an LoginMail</div>
                <button @click="doAction(3)">Absenden</button>
            </div>

        </div>
        <div class="clear"></div>
        <hr />
        <div class="pre vertical-align"><button @click="DoSelect(1)" >Aktivierung</button></div><div>Lizenzschlüssel Zurücksetzten (Dive Installationen Limit)</div>
        <div class="p05">
            <div class="extend" v-if="selection==1">
                <div>
                    <div>Lizenzschlüssel:</div>
                    <input type="text" v-model="Akey" />
                    <button @click="doAction(1)">Absenden</button>
                </div>
                <div v-if="AkeyMsg" class="error">
                    {{AkeyMsg}}
                </div>
            </div>

        </div>
        <div class="clear"></div>


        <hr />

        <div class="pre vertical-align"><button @click="DoSelect(7)" >Produkt Kündigen</button></div>
        <div>Kündigung</div>
        <div class="p05">
            <div class="extend" v-if="selection==7">
                <div>Kunde:</div>
                <input type="text" v-model="memberID" />
                <button @click="SelectedKillKunde=!SelectedKillKunde">Suchen</button>
                <div v-if="SelectedKillKunde">
                    <div>PID:</div>
                    <SelectApp @selected="setPID"></SelectApp>
                    <div>Datum:</div>
                    <input type="date" v-model="Datum" />
                    <div v-if="Datum">
                        <button @click="doAction(7)" class="">Kauf kündigen {{PID}}</button>
                    </div>
                    <!--<div v-if="Datum">
                        <button @click="doAction(8)" class="">Kauf kündigen {{PID}} + Mail</button>
                    </div>-->
                    <div v-if="KillError">
                        {{KillError}}
                    </div>
                </div>
            </div>
        </div>

        <div class="clear"></div>
        <hr />
    </div>
</template>

<script>

    //import { setMemberPass, setPIDKauf, resetLizenzkey } from '@/moduls/auth.js'
    import { resetLizenzkey } from '@/moduls/auth.js'
    import { KillKauf } from '@/moduls/invoice.js'
    import SelectApp from '@/components/AppSelection.vue'
    import { ActivateLizenzAdmin, ResetPasswordAdmin } from '../moduls/auth';

    export default {
        name: 'KundeAdmin',
        props: {
            //suche: {}
        },
        components: {
            SelectApp
        },
        data() {
            return {
                selection: '',
                working: false,
                Akey: '',
                memberID: '',
                PID: '',
                Passwd: '',
                Datum: '',
                AkeyMsg: '',
                SelectedKillKunde: false,
                npass: '',
                AktivationID: '',
                lastSel2: '',
                KillError: null
            }
        },
        mounted() {
            this.KillError=null
            //console.log('mount ' + JSON.stringify(this.$route.params) + ' ' + this.suche)
        },
        computed: {
            isDisabled: function () {
                return true;
            }
        },
        methods: {

            DoSelect(job) {
                this.selection = job
                this.AkeyMsg = null
            },
            async doAction(state) {
                this.KillError = null
                if (state == 1) {
                    console.log(state);
                    this.AkeyMsg = await resetLizenzkey(this.Akey)
                    //console.log(res)
                } else if (state == 2) {
                    let la = await ActivateLizenzAdmin(this.AktivationID, '')
                    console.log(state + ' '+ la);
                    if (la != '') {
                        this.selection = ''
                        this.lastSel2 = la;
                    } else {
                        this.lastSel2=''
                    }
                } else if (state == 4) {
                    //this.PID=''
                    //await setPIDKauf(this.memberID, this.PID,'Probe','')
                    let res = await ResetPasswordAdmin(this.memberID, this.npass)
                    console.log(state + ' ' + res);
                    
                } else if (state == 5) {
                    //this.PID = ''
                    //await setPIDKauf(this.memberID, this.PID, 'Kauf', this.Datum)
                    console.log(state);
                } else if (state == 6) {
                    console.log(state);
                } else if (state == 7) {
                    //console.log(this.memberID);
                    //console.log(this.PID);
                    //console.log(this.Datum);
                    //console.log(state);
                    let res = await KillKauf(this.memberID, this.PID, this.Datum);
                    this.KillError = "OK"
                    if (res == 'FAILS') {
                        this.KillError = res + new Date()
                    }
                    console.log(res);

                } else if (state == 8) {
                    //console.log(this.memberID);
                    //console.log(this.PID);
                    //console.log(this.Datum);
                    //console.log("MAIL");
                    //console.log(state);
                    let res = await KillKauf(this.memberID, this.PID, this.Datum);
                    this.KillError = "OK"
                    if (res == 'FAILS') {
                        this.KillError = res + new Date()
                    }
                    console.log(res);
                }else if (state == 3) {
                    console.log(state);
                }
            },
            setPID(value) {
                console.log('PID')
                this.PID = value
            },

            getFilter() {
                //console.log('getFilter '+ this.selection);
                return this.selection == 1 ? "" : "new"
            },
        }
    };

</script>

<style scoped>

    .NotImplemented{
        background-color:aliceblue;
    }

    .pre {
        padding-top: 1em;
        float: left;
        width: 150px;
    }

    .extend {
        float: left;
        border: solid 1px black;
        padding: 1em;
    }

    .p05{
        padding: 0.5em;
    }
    .extendBorder {
        border: solid 1px black;
    }

    .center {
        text-align: center;
    }

    .error{
        clear:both;
        color: red;
    }
    .vertical-align {
        position: relative;
        top: 50%;
        -webkit-transform: translateY(-50%);
        -ms-transform: translateY(-50%);
        transform: translateY(-50%);
    }
    .NoRTM{
        background: gray;
    }
</style>