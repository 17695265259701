<template>
    <div>
        <div id="nav">
            <button @click="selection=1">Produktliste</button>
            <button v-if="isAdminUser()" @click="selection=2">Updateliste</button>
            <button v-if="isAdminUser()" @click="selection=3">Update einpflegen</button>
            <!--<button @click="selection=3">Produktpflege</button>-->
        </div>
        <div class="clear"></div>
        <div class="content">
            <!--<div class="contentbox">-->
            <div v-if="selection==1">
                Alle Produkte UI und Data
                <pliste></pliste>

            </div>
            <div v-if="selection==2">
                Aktive Updates
                <uliste></uliste>

            </div>
            <div v-if="selection==3">
                UpdateProdukt pflegen, FilePool
                <tliste></tliste>
            </div>
            <!--</div>-->
            <div class="ph5">&nbsp;</div>
        </div>
        <div class="clear"></div>
    </div>
</template>

<script>
    import { isAdminUser, isUpdateUser } from '@/moduls/auth'

    import pliste from '@/components/produktlisteAll.vue'
    import uliste from '@/components/produktupdateliste.vue'
    import tliste from '@/components/produktupdatepool.vue'

    export default {
        name: 'Updates',
        props: {
            //suche: {}
        },
        components: {
            pliste,
            uliste,
            tliste
            //Backendverwaltung,
        },
        data() {
            return {
                selection: 0,
                working: false,
            }
        },
        mounted() {
            //console.log('mount '+isAdminUser())
            //console.log('mount ' + JSON.stringify(this.$route.params) + ' ' + this.suche)
        },
        methods: {
            isAdminUser() {
                return isAdminUser() || isUpdateUser()
                //return getUserInfo()?.role?.includes('Admin')
            },

        }
    };
</script>

<style scoped>
    .leftnav {
        float: left;
        width: 300px;
    }

    .contentbox {
        float: left;
        width: 100vh; /*calc( 100vh - 300px);*/
    }

    button {
        background-color: #003a5c; /*#008CBA;*/
        border: none;
        color: white;
        padding: 0.8em;
        text-align: center;
        text-decoration: none;
        display: inline-block;
        font-size: 0.8em;
        cursor: pointer;
        width: 200px;
        float: left;
    }
</style>